import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BRANCHES_REQUESTED, BRANCHES_SLICE_NAME } from "./constants";
import { getBranchesApi } from "../../api";


export const branchesRequested = createAsyncThunk(BRANCHES_REQUESTED, async () => {
    try {
        const response = await getBranchesApi()
        return response
    } catch (err) {
        throw err
    }
})


const branchesSlice = createSlice({
    name: BRANCHES_SLICE_NAME,
    initialState: {
        branchId: null,
        branchModelOpen: false,
        branchName: "",
        branchAddress: "",
        branchesList: []
    },
    reducers: {
        handleChangebranchId: (state, action) => {
            state.branchId = action.payload
        },
        handleChangebranchModelOpen: (state, action) => {
            state.branchModelOpen = action.payload
        },
        handleChangebranchName: (state, action) => {
            state.branchName = action.payload
        },
        handleChangebranchAddress: (state, action) => {
            state.branchAddress = action.payload
        },
        handleResetModel: (state) => {
            state.branchId = null
            state.branchName = ""
            state.branchModelOpen = false
            state.branchAddress = ""
        }
    },
    extraReducers: builder => { 
        builder.addCase(branchesRequested.fulfilled, (state, action) => {
            state.branchesList = action.payload.data.branches
        })
    }
})


export const {
    handleChangebranchId,
    handleChangebranchModelOpen,
    handleChangebranchName,
    handleChangebranchAddress,
    handleResetModel
} = branchesSlice.actions

export const getbranchName = state => state.branches.branchName
export const getbranchAddress = state => state.branches.branchAddress
export const getbranchModelOpen = state => state.branches.branchModelOpen
export const getbranchId = state => state.branches.branchId
export const getbranchList = state => state.branches.branchesList

export default branchesSlice.reducer