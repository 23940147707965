import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store/store';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppLayout from './layout'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { cosmicTheme, darkOneTheme, darkTheme, LigthTheme } from './common/themes';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultTheme = createTheme(LigthTheme);
const helmetContext = {};
let persistor = persistStore(store);
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <HelmetProvider context={helmetContext}>
              <AppLayout />
              <ToastContainer autoClose={5000} draggable={false} limit={5} />
            </HelmetProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
