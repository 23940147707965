
export const FINANACE_TABLE_COLUMNS = [
    { name: "VSTID" },
    { name: "Details" },
    { name: "Status" }, //Draft, published, paid, pending
    { name: "" },
]

export const FINANCE_SLICE_NAME = 'financeSlice'
export const FINANCE_REQUESTED = `${FINANCE_SLICE_NAME}/financeRequested`
