import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: "#afb9dd",
        cursor: "pointer"
    },
    
}));

const ExplicitTable = ({ columns, children, size }) => {
    return (
        <Table size={size}>
            <TableHead>
                <TableRow>
                    {columns ? columns.length > 0 ?
                        columns.map(col => (
                            <StyledTableCell key={col.name}>{col.name}</StyledTableCell>
                        ))
                        : "" : ""}
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    )
}

export default ExplicitTable