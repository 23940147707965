import { useTheme } from '@emotion/react'
import { Button, Container, IconButton, TextField, Typography, styled } from '@mui/material'
import MuiBox from '@mui/material/Box'
import React from 'react'
import "./style.css"
import { useSelector } from 'react-redux'
import LoginComponent from './Login'
import SignUpComponent from './SignUp'

const Box = styled(MuiBox)(({ theme }) => ({

}))
const Authorization = () => {
  const theme = useTheme()



  return (
    <LoginComponent />
  )
}

export default Authorization