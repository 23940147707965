import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDatePicker = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker {...props}
                    slotProps={{ textField : {...props}}}
                />
        </LocalizationProvider>
    )
}

export default CustomDatePicker