import { Box, Container, ButtonGroup, Tooltip, Fab } from "@mui/material"
import CustomizedStepper from "../../components/Stepper"
import { STEPS } from "./constants"
import { useDispatch, useSelector } from "react-redux"
import { getActiveStep, getSteps, handleNextStep, handlePreviousStep } from "./addStudentSlice"
import Icons from "../../common/icons"
import Personal from "./Personal"
import ParentsInformation from "./Parents"
import EmergencyContact from "./EmergencyContact"

const ActiveComponent = ({ activeStep, steps }) => {
    switch (activeStep) {
        case 0:
            return <Personal />
            break;
        case 1:
            return <ParentsInformation />
            break;
        case 2:
            return <EmergencyContact />
            break;
        case 3:
            return <>At Fourth Step</>
            break;
        case 4:
            return <>At _fourth Step</>
            break;
        case 5:
            return <>At Five Step</>
            break;
        case 6:
            return <>At Six Step</>
            break;
        case 7:
            return <>At eight Step</>
            break;
        case 8:
            return <>At Nine Step</>
            break;
        default:
            break;
    }
}

const AddStudents = () => {
    const activeStep = useSelector(getActiveStep)
    const steps = useSelector(getSteps)

    const dispatch = useDispatch()

    return (
        <Container maxWidth="xl" spacing={2}>
            <Box sx={{
                width: "100%"
            }}>
                <CustomizedStepper steps={STEPS}>
                    <ActiveComponent activeStep={activeStep} steps={steps} />
                </CustomizedStepper>
            </Box>
            <ButtonGroup sx={{
                position: "absolute",
                bottom: 50,
                right: 50
            }}>
                <Tooltip placement="top" title={"Previous Step"}  >
                    <Fab variant="contained" color="primary" sx={{ mr: 2 }}
                        onClick={() => dispatch(handlePreviousStep())}
                    >
                        <Icons.ArrowRightAlt sx={{ transform: "scaleX(-1)" }} />
                    </Fab>
                </Tooltip>
                <Tooltip placement="top" title={"Next Step"} >
                    <Fab variant="contained" color="primary"
                        onClick={() => dispatch(handleNextStep())}
                    >
                        <Icons.ArrowRightAlt />
                    </Fab>
                </Tooltip>
            </ButtonGroup>
        </Container>
    )
}

export default AddStudents