import { useTheme } from '@emotion/react'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const NotFound = () => {
    const theme = useTheme()
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "30%",
                margin: "auto",
                [theme.breakpoints.down("sm")]: {
                    width: "100%"
                }
            }}
        >
            <img style={{
                mixBlendMode: "multiply",
                width: "100%"
            }} src={process.env.PUBLIC_URL + "/static/404.jpg"} />
            <Typography sx={{
                fontWeight: 700, marginTop: "-15%", marginBottom: 2,
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                    fontSize: 20,
                    textAlign: "center"
                }
            }} variant="h4" component={"h4"}>LOST! LETS HELP YOU GET BACK</Typography>
            <Button variant="contained" size="small" color="error" >HELP ME GET BACK</Button>
        </Box >
    )
}

export default NotFound