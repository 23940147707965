import { createSlice } from "@reduxjs/toolkit";
import { ADD_STUDENT_SLICE_NAME, STEPS } from "./constants";


const addStudentSlice = createSlice({
    name: ADD_STUDENT_SLICE_NAME,
    initialState: {
        steps: STEPS,
        activeStep: 0,
    },
    reducers: {
        handlePreviousStep: (state, action) => {
            if (state.activeStep > 0) {
                state.activeStep = state.activeStep - 1
            } else {
                state.activeStep = 0
            }
        },
        handleNextStep: (state, action) => {
            if (state.activeStep <= state.steps.length - 1) {
                state.activeStep++
            } else {

            }
        },
        handleResetSlice: (state, action) => {
            state.activeStep = 0
            state.steps = STEPS
        }
    },
    extraReducers: builder => { }
})

const handleAsyncNextStep = (state, action) => {

}
export const {
    handlePreviousStep,
    handleNextStep,
    handleResetSlice
} = addStudentSlice.actions

export const getActiveStep = state => state.addStudent.activeStep
export const getSteps = state => state.addStudent.steps

export default addStudentSlice.reducer