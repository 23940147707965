import Icons from "../../common/icons";
import Dashboard from "../../screen/Dashboard";
import Students from "../../screen/Students";
import Meals from "../../screen/Meals";
import Packages from "../../screen/Package";
import Classes from "../../screen/Classes";
import Finance from "../../screen/Finance";
import Reports from "../../screen/Reports";
import Users from "../../screen/Users";
import Profile from "../../screen/Profile";
import ChangePassword from "../../screen/Chpw";
import Authorization from "../../screen/Auth";
import Groups from "../../screen/Groups";
import AddStudents from "../../screen/AddStudents";
import Voucher from "../../screen/Voucher";
import VoucherPreview from "../../screen/VoucherPreview";
import CreateStudent from "../../screen/CreateStudent";
import VoucherConfig from "../../screen/Voucher/VoucherConfig";
import Designations from "../../screen/Designations";
import Titles from "../../screen/Titles";
import AddTutor from "../../screen/AddTutor";
import Employee from "../../screen/Employee";
import StaffProfile from "../../screen/StaffProfile";
import Branches from "../../screen/Branches";
import Languages from "../../screen/Languages";
import Ethnicity from "../../screen/Ethnicities";
import Roles from "../../screen/Roles";
import Permissions from "../../screen/Permissions";
import StudentProfile from "../../screen/StudentProfile";


export const APP_NAVIGATION = [
    // {
    //     index: 0,
    //     type: "nav",
    //     label: "Login",
    //     icon: Icons.Dashboard,
    //     path: "/",
    //     permission: "login",
    //     role: ["admin", "parents"],
    //     screen: Authorization,
    //     redirectPath: "/",
    //     showInLeftNav: false,
    //     isProtected: false,
    // },
    {
        index: 0,
        type: "nav",
        label: "Dashboard",
        icon: Icons.Dashboard,
        path: "/dashboard",
        permission: "dashboard",
        role: ["admin", "parents"],
        screen: Dashboard,
        redirectPath: "/",
        showInLeftNav: true,
        isProtected: true,
    },
    {
        index: 90,
        type: "nav",
        label: "System Administration",
        icon: Icons.Dashboard,
        path: "/student-corner",
        permission: "can_access_system_administration",
        role: ["admin", "parents"],
        // screen: Dashboard,
        // redirectPath: "/",
        showInLeftNav: true,
        isProtected: true,
        childrens: [
            // {
            //     index: 82,
            //     type: "nav",
            //     label: "Groups",
            //     icon: Icons.School,
            //     path: "/groups",
            //     permission: "meals",
            //     role: ["admin"],
            //     screen: Groups,
            //     redirectPath: "/",
            //     showInLeftNav: true,
            //     isProtected: true,
            // },
            {
                index: 2014,
                type: "nav",
                label: "Branches",
                icon: Icons.PeopleAlt,
                path: "/branches",
                permission: "can_access_branches",
                role: ["admin"],
                screen: Branches,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
            {
                index: 2015,
                type: "nav",
                label: "Languages",
                icon: Icons.PeopleAlt,
                path: "/languages",
                permission: "can_view_languages",
                role: ["admin"],
                screen: Languages,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
            {
                index: 2016,
                type: "nav",
                label: "Ethnicities",
                icon: Icons.PeopleAlt,
                path: "/etnicities",
                permission: "can_view_ethnicities",
                role: ["admin"],
                screen: Ethnicity,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
            {
                index: 31,
                type: "nav",
                label: "Users",
                icon: Icons.PeopleAlt,
                path: "/users",
                permission: "can_view_users",
                role: ["admin"],
                screen: Users,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
            {
                index: 31,
                type: "nav",
                label: "Roles and Permissions",
                icon: Icons.PeopleAlt,
                path: "/roles-and-permissions",
                permission: "can_view_roles",
                role: ["admin"],
                screen: Roles,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
            {
                index: 32,
                type: "nav",
                label: "Permissions",
                icon: Icons.PeopleAlt,
                path: "/permission",
                permission: "can_view_permissions",
                role: ["admin"],
                screen: Permissions,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: false,
            },
            // {
            //     index: 33,
            //     type: "nav",
            //     label: "Email Configurations",
            //     icon: Icons.PeopleAlt,
            //     path: "/email-configurations",
            //     permission: "dashboard",
            //     role: ["admin"],
            //     screen: Users,
            //     redirectPath: "/",
            //     isProtected: true,
            //     showInLeftNav: true,
            // },
            // {
            //     index: 34,
            //     type: "nav",
            //     label: "System Logs",
            //     icon: Icons.PeopleAlt,
            //     path: "/audit-logs",
            //     permission: "dashboard",
            //     role: ["admin"],
            //     screen: Users,
            //     redirectPath: "/",
            //     isProtected: true,
            //     showInLeftNav: true,
            // },
            // {
            //     index: 35,
            //     type: "nav",
            //     label: "Activity Logs",
            //     icon: Icons.PeopleAlt,
            //     path: "/activity-logs",
            //     permission: "dashboard",
            //     role: ["admin"],
            //     screen: Users,
            //     redirectPath: "/",
            //     isProtected: true,
            //     showInLeftNav: true,
            // },
        ]
    },
    {
        index: 0,
        type: "nav",
        label: "Students Corner",
        icon: Icons.Dashboard,
        path: "/student-corner",
        permission: "can_access_student_corner",
        role: ["admin", "parents"],
        // screen: Dashboard,
        // redirectPath: "/",
        showInLeftNav: true,
        isProtected: true,
        childrens: [
            {
                index: 2,
                type: "nav",
                label: "Meals",
                icon: Icons.Restaurant,
                path: "/meals",
                permission: "can_view_meals",
                role: ["admin"],
                screen: Meals,
                redirectPath: "/",
                showInLeftNav: true,
                isProtected: true,
            },
            {
                index: 4,
                type: "nav",
                label: "Classes",
                icon: Icons.Class,
                path: "/classes",
                permission: "can_view_classes",
                role: ["admin"],
                screen: Classes,
                redirectPath: "/",
                showInLeftNav: true,
                isProtected: true,
            },
            {
                index: 3,
                type: "nav",
                label: "Packages",
                icon: Icons.Inventory2,
                path: "/packages",
                permission: "can_view_packages",
                role: ["admin"],
                screen: Packages,
                redirectPath: "/",
                showInLeftNav: true,
                isProtected: true,
            },

            {
                index: 1,
                type: "nav",
                label: "Students",
                icon: Icons.School,
                path: "/students",
                permission: "can_view_students",
                role: ["admin"],
                screen: Students,
                redirectPath: "/",
                showInLeftNav: true,
                isProtected: true,
            },
            {
                index: 5,
                type: "nav",
                label: "Vouchers",
                icon: Icons.RequestQuote,
                path: "/vouchers",
                permission: "can_view_vouchers",
                role: ["admin"],
                screen: Finance,
                redirectPath: "/",
                showInLeftNav: true,
                isProtected: true,
            },

        ]
    },

    // {
    //     index: 80,
    //     type: "nav",
    //     label: "Staff Corner",
    //     icon: Icons.Dashboard,
    //     path: "/staff-corner",
    //     permission: "dashboard",
    //     role: ["admin", "parents"],
    //     // screen: Dashboard,
    //     // redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    //     childrens: [
    //         {
    //             index: 81,
    //             type: "nav",
    //             label: "Designations",
    //             icon: Icons.School,
    //             path: "/designations",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: Designations,
    //             redirectPath: "/",
    //             showInLeftNav: true,
    //             isProtected: true,
    //         },
    //         {
    //             index: 83,
    //             type: "nav",
    //             label: "Titles",
    //             icon: Icons.School,
    //             path: "/titles",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: Titles,
    //             redirectPath: "/",
    //             showInLeftNav: true,
    //             isProtected: true,
    //         },
    //         {
    //             index: 81,
    //             type: "nav",
    //             label: "Staff",
    //             icon: Icons.School,
    //             path: "/staff",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: Employee,
    //             redirectPath: "/",
    //             showInLeftNav: true,
    //             isProtected: true,
    //         },
    //         {
    //             index: 84,
    //             type: "nav",
    //             label: "Pay Slips",
    //             icon: Icons.School,
    //             path: "/pay-slips",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: Students,
    //             redirectPath: "/",
    //             showInLeftNav: true,
    //             isProtected: true,
    //         },
    //         {
    //             index: 85,
    //             type: "nav",
    //             label: "Add Tutor",
    //             icon: Icons.School,
    //             path: "/add-tutor",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: AddTutor,
    //             redirectPath: "/",
    //             showInLeftNav: false,
    //             isProtected: true,
    //         },
    //         {
    //             index: 86,
    //             type: "nav",
    //             label: "Staff Profile",
    //             icon: Icons.School,
    //             path: "/staff-profile",
    //             permission: "meals",
    //             role: ["admin"],
    //             screen: StaffProfile,
    //             redirectPath: "/",
    //             showInLeftNav: true,
    //             isProtected: true,
    //         },
    //     ]
    // },





    // {
    //     index: 100,
    //     type: "nav",
    //     label: "Finance Corner",
    //     icon: Icons.Dashboard,
    //     path: "/settings",
    //     permission: "dashboard",
    //     role: ["admin", "parents"],
    //     // screen: Dashboard,
    //     // redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    //     childrens: [
    //         {
    //             index: 101,
    //             type: "nav",
    //             label: "Profit / Loss Statement",
    //             icon: Icons.PeopleAlt,
    //             path: "/profit-and-loss",
    //             permission: "dashboard",
    //             role: ["admin"],
    //             screen: Users,
    //             redirectPath: "/",
    //             isProtected: true,
    //             showInLeftNav: true,
    //         },
    //         {
    //             index: 92,
    //             type: "nav",
    //             label: "Receivables",
    //             icon: Icons.PeopleAlt,
    //             path: "/receivables",
    //             permission: "dashboard",
    //             role: ["admin"],
    //             screen: Users,
    //             redirectPath: "/",
    //             isProtected: true,
    //             showInLeftNav: true,
    //         },
    //     ]
    // },

    {
        index: 90,
        type: "nav",
        label: "Settings",
        icon: Icons.Dashboard,
        path: "/settings",
        permission: "can_access_settings",
        role: ["admin", "parents"],
        // screen: Dashboard,
        // redirectPath: "/",
        showInLeftNav: true,
        isProtected: true,
        childrens: [
            // {
            //     index: 91,
            //     type: "nav",
            //     label: "Profile",
            //     icon: Icons.PeopleAlt,
            //     path: "/profile",
            //     permission: "can_access_profile",
            //     role: ["admin"],
            //     screen: Users,
            //     redirectPath: "/",
            //     isProtected: true,
            //     showInLeftNav: true,
            // },
            {
                index: 92,
                type: "nav",
                label: "Change Password",
                icon: Icons.PeopleAlt,
                path: "/change-passwords",
                permission: "can_access_change_password",
                role: ["admin"],
                screen: ChangePassword,
                redirectPath: "/",
                isProtected: true,
                showInLeftNav: true,
            },
        ]
    },
    // {
    //     index: 3,
    //     type: "nav",
    //     label: "Groups",
    //     icon: Icons.Inventory2,
    //     path: "/groups",
    //     permission: "groups",
    //     role: ["admin"],
    //     screen: Groups,
    //     redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    // },


    // {
    //     index: 6,
    //     type: "nav",
    //     label: "Reports",
    //     icon: Icons.AccountBalance,
    //     path: "/reports",
    //     permission: "meals",
    //     role: ["admin"],
    //     screen: Reports,
    //     redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    // },

    // {
    //     index: 41,
    //     type: "nav",
    //     label: "Profile",
    //     icon: Icons.AccountBox,
    //     path: "/profile",
    //     permission: "dashboard",
    //     role: ["admin", "parents"],
    //     screen: Profile,
    //     redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    // },
    // {
    //     index: 41,
    //     type: "nav",
    //     label: "Change Passord",
    //     icon: Icons.Security,
    //     path: "/chpw",
    //     permission: "dashboard",
    //     role: ["admin", "parents"],
    //     screen: ChangePassword,
    //     redirectPath: "/",
    //     showInLeftNav: true,
    //     isProtected: true,
    // },
    {
        index: 41,
        type: "nav",
        label: "Add Student",
        icon: Icons.Security,
        path: "/add-student",
        permission: "can_add_students",
        role: ["admin", "parents"],
        screen: AddStudents,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
    {
        index: 41,
        type: "nav",
        label: "Voucher",
        icon: Icons.Security,
        path: "/voucher",
        permission: "can_add_vouchers",
        role: ["admin"],
        screen: Voucher,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
    {
        index: 41,
        type: "nav",
        label: "Voucher Preview",
        icon: Icons.Security,
        path: "/voucher-preview",
        permission: "can_edit_vouchers",
        role: ["admin"],
        screen: VoucherPreview,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
    {
        index: 42,
        type: "nav",
        label: "CreateStudent",
        icon: Icons.Security,
        path: "/create-student",
        permission: "can_add_students",
        role: ["admin"],
        screen: CreateStudent,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
    {
        index: 43,
        type: "nav",
        label: "CreateStudent",
        icon: Icons.Security,
        path: "/config-voucher",
        permission: "can_edit_vouchers",
        role: ["admin"],
        screen: VoucherConfig,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
    {
        index: 44,
        type: "nav",
        label: "Student Profile",
        icon: Icons.Security,
        path: "/student-profile",
        permission: "can_add_students",
        role: ["admin"],
        screen: StudentProfile,
        redirectPath: "/",
        showInLeftNav: false,
        isProtected: true,
    },
]