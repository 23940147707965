import { Grid, IconButton, Container, Button, Box, Typography, TextField, Stack, Chip, Select, MenuItem } from '@mui/material'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../../components/Table/ExplicitTable'
import CustomTextField from '../../../components/TextField'
import Icons from '../../../common/icons'
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import { getVoucherConfigFeeAmount, getVoucherConfigFeeDescription, getVoucherConfigFeeType, getVoucherConfigId, getVoucherConfigList, handleChangeVoucherConfigFeeAmount, handleChangeVoucherConfigFeeDescription, handleChangeVoucherConfigFeeType, handleChangeVoucherConfigId, handleResetForm, voucherConfigRequested } from './voucherConfigSlice'
import { deleteVoucherDetailsApi, postVoucherDetailsApi, updateVoucherDetailsApi, updateVoucherStatusApi } from '../../../api'
import { getVoucherId } from '../voucherSlice'
import { COMMON_STYLES } from '../../../common/constant'
import dayjs from 'dayjs'

const VoucherConfig = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [status, setStatus] = React.useState(null)
    const voucherConfigList = useSelector(getVoucherConfigList)

    const voucherConfigId = useSelector(getVoucherConfigId)
    const voucherId = useSelector(getVoucherId)
    const voucherConfigFeeType = useSelector(getVoucherConfigFeeType)
    const voucherConfigFeeAmount = useSelector(getVoucherConfigFeeAmount)
    const voucherConfigFeeDescription = useSelector(getVoucherConfigFeeDescription)

    const [parsedDetails, setParsedDetails] = React.useState([])

    const loadVoucherDetails = async () => {
        try {
            dispatch(voucherConfigRequested({ voucherId })).unwrap()
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        loadVoucherDetails()
    }, [])


    React.useEffect(() => {
        if (location.state.status) {
            setStatus(location.state.status)
        }
    }, [location.state])
    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} maxWidth={"xl"} sx={{ justifyContent: "end" }}>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent={"center"}>
                        <Grid item xs={12} textAlign={"right"}>
                            <Button variant='contained'  size='small' onClick={() => {
                                navigate("/voucher-preview", {
                                    state: {
                                        voucherId: voucherId
                                    }
                                })
                            }}>Preview Voucher</Button>
                        </Grid>
                        <Grid item xs={8} >
                            <Stack gap={1.5}>
                                <Typography variant='h6' component={"h4"}>Voucher Details</Typography>

                                <Typography><b>Registration Id: </b>{voucherConfigList.students.id}</Typography>
                                <Typography><b>Student Name: </b>{voucherConfigList.students.full_name}</Typography>
                                <Typography><b>Billing Month: </b>{dayjs(voucherConfigList.month).format("MMM-YYYY")}</Typography>
                                <Typography><b>Payment Type: </b>{!voucherConfigList.duration === "Full" ? <Chip color='error' variant='contained' label="Applied for Installments" sx={{ ...COMMON_STYLES.chip }} /> : <Chip color='success' label="One Time Payment" size='small' sx={{ ...COMMON_STYLES.chip }} />}</Typography>
                                <Typography><b>Voucher Status: </b> <Select sx={{
                                    width: 200,
                                    fontSize: 12,
                                }} size='small' value={status} onChange={async e => {
                                    try {
                                        await updateVoucherStatusApi({ id: voucherId, status: e.target.value })
                                        setStatus(e.target.value)
                                    } catch (err) {
                                        console.log(err)
                                    }
                                }} >
                                    <MenuItem value="">--- Please Select ---</MenuItem>
                                    <MenuItem value="Draft">Draft</MenuItem>
                                    <MenuItem value="Pending Approval"> Pending Approval</MenuItem>
                                    <MenuItem value="Late"> Late</MenuItem>
                                    <MenuItem value="Paid"> Paid</MenuItem>
                                </Select></Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} >
                            <Stack gap={1.5} sx={{
                                boxShadow: theme => theme.shadows[4],
                                p: 2
                            }}>
                                <Typography variant='h6' component={"h4"}>Add New Element</Typography>
                                <TextField fullWidth value={voucherConfigFeeType} onChange={e => dispatch(handleChangeVoucherConfigFeeType(e.target.value))} size='small' label='Fee Type' />
                                <TextField fullWidth value={voucherConfigFeeAmount} onChange={e => dispatch(handleChangeVoucherConfigFeeAmount(e.target.value))} size='small' label='Fee Amount' />
                                <TextField fullWidth value={voucherConfigFeeDescription} onChange={e => dispatch(handleChangeVoucherConfigFeeDescription(e.target.value))} size='small' label='Description' />
                                <Stack flexDirection={"row"} gap={1.5}>
                                    <Button variant='contained' size='small'
                                        onClick={async () => {
                                            try {
                                                 voucherConfigId === null ? await postVoucherDetailsApi({
                                                    amount: voucherConfigFeeAmount,
                                                    type: voucherConfigFeeType,
                                                    description: voucherConfigFeeDescription,
                                                    voucherId: voucherId
                                                }):  await updateVoucherDetailsApi({
                                                    amount: voucherConfigFeeAmount,
                                                    type: voucherConfigFeeType,
                                                    description: voucherConfigFeeDescription,
                                                    voucherId: voucherId,
                                                    id: voucherConfigId
                                                })
                                                dispatch(handleResetForm())
                                                loadVoucherDetails()
                                            } catch (err) {
                                                console.log(err)
                                            }
                                        }}
                                    >Add</Button>
                                    <Button variant='outlined' size='small' onClick={() => {
                                        dispatch(handleResetForm())
                                    }}>Cancel</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ExplicitTable size={"small"} columns={[
                        { name: "Fee Type" },
                        { name: "Amount" },
                        { name: "Description" },
                        { name: "" },
                    ]}>
                        {voucherConfigList.voucher_meta.length > 0 ?
                            voucherConfigList.voucher_meta.map((v, idx) => (
                                <StyledTableRow key={idx}>
                                    <StyledTableCell>{v.type.toUpperCase()}</StyledTableCell>
                                    <StyledTableCell>{v.amount}</StyledTableCell>
                                    <StyledTableCell>{v.description}</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: "right" }}>
                                        <IconButton sx={{ p: 0, mr: 1 }} onClick={() => {
                                            dispatch(handleChangeVoucherConfigId(v.id))
                                            dispatch(handleChangeVoucherConfigFeeType(v.type))
                                            dispatch(handleChangeVoucherConfigFeeAmount(v.amount))
                                            dispatch(handleChangeVoucherConfigFeeDescription(v.description))
                                        }}>
                                            <Icons.BorderColor sx={{ ...COMMON_STYLES.icon }} />
                                        </IconButton>

                                        <IconButton sx={{ p: 0, mr: 1 }} onClick={async () => {
                                            try {
                                                await deleteVoucherDetailsApi({ id: v.id })
                                                loadVoucherDetails()
                                            } catch (err) {
                                                console.log({ err })
                                            }
                                        }}>
                                            <Icons.DeleteForever sx={{ ...COMMON_STYLES.icon }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                            : ""}

                    </ExplicitTable>


                </Grid>
                <Grid item xs={4}>
                    <ExplicitTable size={"small"} columns={[

                    ]}>
                        <StyledTableRow>
                            <StyledTableCell sx={{ color: theme => theme.palette.success.main }}>Amount Payable</StyledTableCell>
                            <StyledTableCell sx={{ color: theme => theme.palette.success.main, textAlign: "right", fontWeight: 700 }}>PKR Rs.{parseFloat(voucherConfigList.amount_payable)}.00/-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell sx={{ color: "#f00" }}>
                                <p>Amount Payable (After Due Date)</p>
                                <p style={{fontSize: 10}}>100 Rs per day</p>
                                 </StyledTableCell>
                            <StyledTableCell sx={{ color: "#f00", textAlign: "right", fontWeight: 700 }}>PKR Rs.{dayjs(new Date()).diff(dayjs(voucherConfigList.due_date), "day") > 0 ? parseFloat(voucherConfigList.amount_payable) +  (parseFloat(dayjs(new Date()).diff(dayjs(voucherConfigList.issue_date), "day")) * 100) : voucherConfigList.amount_payable  }.00/-</StyledTableCell>
                        </StyledTableRow>
                    </ExplicitTable>
                </Grid>
            </Grid>
        </Container>
    )
}

export default VoucherConfig