import { Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import ExplicitTable from '../../../components/Table/ExplicitTable'
import Dialog from '../../../components/Modal'
import CustomTextField from '../../../components/TextField'
import CustomizeSelect, { MenuItem } from '../../../components/Select'
import { PARENTAL_RELATIONS } from '../Parents/constants'

const EmergencyContact = () => {
  return (
    <Container maxWidth="xl" component={Paper} sx={{ pb: 4 }}>
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h4">Emergency Contacts</Typography>
        </Grid>
        <Grid item xs={12}>
            <ExplicitTable
                columns={[
                    { name: "Name" },
                    { name: "Relation" },
                    { name: "Contact Number" },
                    { name: "" },
                ]}
            >

            </ExplicitTable>
        </Grid>
    </Grid>

    <Dialog dailogOpen={false} title="Emergency Contact" size="sm" hasCloseIcon={true} clickAwayListener={true}
        actionsButtonArray={[
            {
                label: "Cancel",
                color: "error",
                variant: "contained",
                onClick: () => { },
                size: "small"
            },
            {
                label: "Done",
                sx: { bgColor: theme => theme.palette.primary.main },
                variant: "contained",
                onClick: () => { },
                size: "small"
            }
        ]}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CustomTextField fullWidth label="Full Name" size="small" />
            </Grid>
            <Grid item xs={12}>
                <CustomizeSelect fullWidth label="Relation" size="small">
                    {PARENTAL_RELATIONS.map(relation => (
                        <MenuItem key={relation.key} value={relation.key}>{relation.name}</MenuItem>
                    ))}
                </CustomizeSelect>
            </Grid>
            <Grid item xs={12}>
                <CustomTextField fullWidth label="Contact Number" size="small" />
            </Grid>
        </Grid>
    </Dialog>
</Container >
  )
}

export default EmergencyContact