import { useSelector } from "react-redux"
import { getIsLoggedIn, getPermissions, getRole } from "../../screen/Auth/authSlice"
import { Navigate, Outlet } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

const ProtectedRoute = ({ label, roles, permission, redirectPath = "/", children }) => {
    const isLoggedIn = useSelector(getIsLoggedIn)
    const userPermissions = useSelector(getPermissions)
    const role = "admin"

    if (isLoggedIn && roles.includes(role)) {
        if (userPermissions.includes(permission)) {
            return <>
                <Helmet>
                    <title>{label} | School Management System</title>
                    <meta name='description' content='A multiple branch controlled School management system' />
                    <meta name='owner' content='Asad Mehmood' />
                    <meta name='owner-website' content='https://www.itsasad.pk' />
                </Helmet>
                {children ? children : <Outlet />}

            </>
        } else {
            return <>
                <Helmet>
                    <title>Login | School Management System</title>
                    <meta name='description' content='A multiple branch controlled School management system' />
                    <meta name='owner' content='Asad Mehmood' />
                    <meta name='owner-website' content='https://www.itsasad.pk' />
                </Helmet>
                <Navigate to={redirectPath} replace />
            </>
        }
    }
    return <>
        <Helmet>
            <title>Login | School Management System</title>
            <meta name='description' content='A multiple branch controlled School management system' />
            <meta name='owner' content='Asad Mehmood' />
            <meta name='owner-website' content='https://www.itsasad.pk' />
        </Helmet>
        <Navigate to={redirectPath} replace />
    </>
}

export default ProtectedRoute