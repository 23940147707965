import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MEALS_REQUESTED, MEALS_SLICE_NAME } from "./constants";
import { getAllMealsApi } from "../../api";

export const mealsRequested = createAsyncThunk(MEALS_REQUESTED, async () => {
    try {
        const response = await getAllMealsApi()
        return response
    } catch (err) {
        throw err
    }
})

const mealSlice = createSlice({
    name: MEALS_SLICE_NAME,
    initialState: {
        mealModelOpen: false,
        mealId: null,
        name: "",
        price: "",
        meal_for: "",
        meals: [],
        deleteModelOpen: false
    },
    reducers: {
        handleChangeMealId: (state, action) => {
            state.mealId = action.payload
        },
        handleChangeMealName: (state, action) => {
            state.name = action.payload
        },
        handleChangeMealFor: (state, action) => {
            state.meal_for = action.payload
        },
        handleChangeMealPrice: (state, action) => {
            state.price = action.payload
        },
        handleChangeMealModelOpen: (state, action) => {
            state.mealModelOpen = action.payload
        },
        handleChangeMealDeleteModelOpen: (state, action) => {
            state.deleteModelOpen = action.payload
        },
        handleResetModel: (state) => {
            state.mealId = null
            state.name = ""
            state.price = ""
            state.meal_for = ""
            state.mealModelOpen = false
        },
        handleResetSlice: (state) => {
            state.mealId = null
            state.name = ""
            state.price = ""
            state.meal_for = ""
            state.mealModelOpen = false
            state.meals = []
        }
    },
    extraReducers: builder => {
        builder.addCase(mealsRequested.fulfilled, (state, action) => {
            state.meals = action.payload.data.meals
            
        })
    }
})

export const {
    handleChangeMealId,
    handleChangeMealName,
    handleChangeMealPrice,
    handleChangeMealModelOpen,
    handleChangeMealFor,
    handleResetModel,
    handleResetSlice,
    handleChangeMealDeleteModelOpen
} = mealSlice.actions

export const getMealModelOpen = (state) => state.meal.mealModelOpen
export const getMealsList = (state) => state.meal.meals
export const getMealName = (state) => state.meal.name
export const getMeaalFor = (state) => state.meal.meal_for
export const getMealPrice = (state) => state.meal.price
export const getMealId = (state) => state.meal.mealId
export const getMealDeleteModelOpen = (state) => state.meal.deleteModelOpen

export default mealSlice.reducer