export const BREADCRUMBS = [
    {
        name: "Employees",
        icon: "",
        path: `/maange-inventory`
    },
    {
        name: "Employee",
        icon: "",
        path: `/Employee`
    },
]

export const TABLE_HEADS = [
    { name: "Id" },
    { name: "Full Name" },
    { name: "Email Address" },
    { name: "CNIC Number" },
    { name: "Contact Number" },
    { name: "Tile - Designation" },
    { name: "Action", align: "right" },
]

export const EMPLOYEE_SLICE_NAME = 'employeeSlice'
export const EMPLOYEES_REQUESTED= `${EMPLOYEE_SLICE_NAME}/employeesRequested`
export const EMPLOYEE_ITEMS_REQUESTED= `${EMPLOYEE_SLICE_NAME}/employeeItemsRequested`