import { Container, Grid, Paper, Typography } from "@mui/material"
import CustomTextField from "../../../components/TextField"
import CustomizeSelect, { MenuItem } from "../../../components/Select"
import CustomDatePicker from "../../../components/DatePicker"


const Personal = () => {
    return (
        <Container component={Paper} maxWidth="xl" sx={{pb: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Child Information</Typography>
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="First Name" placeholder="First Name" />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="Middle Name" placeholder="Middle Name" />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="Last Name" placeholder="Last Name" />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="Nick Name" placeholder="Nick Name" />
                </Grid>

                <Grid item xs={3}>
                    <CustomizeSelect fullWidth size="small" label="Gender" placeholder="Gender" >
                        <MenuItem value="">--- Please Select One ---</MenuItem>
                        <MenuItem value="male" >Male</MenuItem>
                        <MenuItem value="female" >Female</MenuItem>
                    </CustomizeSelect>
                </Grid>

                <Grid item xs={3}>
                    <CustomDatePicker fullWidth size="small" label="Date Of Birth" placeholder="Date of Birth" />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="Postal Code" placeholder="Postal Code" />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField fullWidth size="small" label="Home Phone" placeholder="Home Phone" />
                </Grid>
               
                <Grid item xs={6}>
                    <CustomTextField fullWidth size="small" label="Religion" placeholder="Religion" />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField fullWidth size="small" label="Ethinic Group" placeholder="Ethinic Group" />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField fullWidth size="small" label="What is the first language spoken at home?" placeholder="First Language" />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField fullWidth size="small" label="Is there any other language spoken at home?" placeholder="Secondary Language" />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField fullWidth size="small" label="Address" placeholder="Address" multiline rows={3} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Personal