import { Box, Button, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useRef } from 'react'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Icons from '../../common/icons';
import dayjs from 'dayjs';
import { getAllVoucherDetailsApi, getVoucherDetailByIdApi } from '../../api';
import { useLocation } from 'react-router-dom'

const VoucherPreview = () => {
    const heads = ["Bank", "School", "Parent"]
    const ref = useRef()
    const [details, setDetails] = React.useState(null)
    const location = useLocation()

    const loadVoucherDetails = async () => {
        try {
            const response = await getAllVoucherDetailsApi({
                voucherId: location.state.voucherId
            })
            setDetails(response.data.voucher)
        } catch (err) {
            console.log(err)
        }
    }

    const handleDownloadPdf = async () => {
        const element = ref.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4"
        });

        pdf.addImage(data, 'PNG', 0, 0, 295, 0);
        pdf.save('print.pdf');
    };

    React.useEffect(() => {
        if (location.state.voucherId) {
            loadVoucherDetails()
        }
    }, [location.state])

    return (
        <Container maxWidth="xl" component={Paper} sx={{
        }}>
            <Grid container sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Button size="small" variant='contained' onClick={handleDownloadPdf} startIcon={<Icons.FileDownload />}>Download PDF</Button>
                </Grid>
            </Grid>
            {details && details.id ? <Grid container spacing={3} ref={ref}>

                {heads.map((head) => (
                    <Grid item xs={4} sx={{

                    }}>
                        <Grid container gap={0.5} sx={{
                            border: "1px solid #000",
                            p: 3
                        }}>
                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Box sx={{
                                        width: "90px"
                                    }}>
                                        <img style={{
                                            width: "100%"
                                        }} src={"https://www.hbl.com/assets/theme_images/logo-1920.png"} />
                                    </Box>
                                    <Box sx={{
                                        textAlign: "center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center"
                                    }}>
                                        <Typography sx={{
                                            background: "#000",
                                            color: "#fff",
                                            maxWidth: "fit-content",
                                            px: 1,
                                            py: 0.5,
                                            textAlign: "center",
                                            fontSize: 12,
                                            mb:1
                                        }}>{head} Copy</Typography>
                                        <Typography sx={{
                                            fontWeight: 800
                                        }}>HABIB BANK LIMITED</Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            fontStyle: "italic"
                                        }}>Any branch within Pakistan</Typography>
                                    </Box>
                                    <Box sx={{
                                        width: "90px"
                                    }}>
                                        <img style={{
                                            width: "100%"
                                        }} src={details?.branch.logo} />
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Typography sx={{
                                        fontSize: 12
                                    }}>Account Number: <b style={{ textDecoration: "underline" }}>22 6979 3061 2103</b> </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} >
                                    <Typography sx={{
                                        fontSize: 12
                                    }}>No: <b style={{ textDecoration: "underline" }}>22 6979 3061 2103</b> </Typography>
                                    <Typography sx={{
                                        fontSize: 12
                                    }}>Dated: <b style={{ textDecoration: "underline" }}>August 02, 2023</b> </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Typography sx={{
                                        fontSize: 12,
                                        width: "15%"
                                    }}>Credit</Typography>
                                    <Typography sx={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        textDecoration: "underline"
                                    }}>Kidz Republik Private Limited</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Typography sx={{
                                        fontSize: 12,
                                        width: "15%"
                                    }}>Full Name</Typography>
                                    <Typography sx={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        textDecoration: "underline",
                                    }}>{details?.students?.full_name}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Box sx={{ display: "flex", flex: 1 }}>
                                        <Typography sx={{
                                            fontSize: 12,
                                            width: "30%"
                                        }}>Class</Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                        }}>{details?.classes?.name}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flex: 1, }}>
                                        <Typography sx={{
                                            fontSize: 12,
                                            width: "30%"
                                        }}>Reg# No:</Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                        }}>{details?.students?.id}</Typography>
                                    </Box>
                                </Stack>
                            </Grid>


                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Typography sx={{
                                        fontSize: 12,
                                        width: "15%"
                                    }}>Month</Typography>
                                    <Typography sx={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        textDecoration: "underline"
                                    }}>{dayjs(details?.month).format("MMMM-YYYY")}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                    <Typography sx={{
                                        fontSize: 12,
                                        width: "15%"
                                    }}>Campus</Typography>
                                    <Typography sx={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        textDecoration: "underline"
                                    }}>{details?.branch?.name}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{
                                    borderRadius: "15px",
                                    border: "1px solid #000",
                                    p: 1,
                                    position: "relative"
                                }}>
                                    <Box sx={{
                                        minHeight: 150,
                                    }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: 0, fontWeight: "bold", fontSize: 12 }}>Sr#</TableCell>
                                                    <TableCell sx={{ padding: 0, fontWeight: "bold", fontSize: 12 }} width={"70%"}>Type of Fee</TableCell>
                                                    <TableCell sx={{ padding: 0, fontWeight: "bold", fontSize: 12, textAlign: "right" }}>Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {details?.voucher_meta?.length > 0 ?
                                                    details?.voucher_meta?.map((v, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell sx={{ padding: 0, fontWeight: "normal", fontSize: 12 }}>{idx + 1}</TableCell>
                                                            <TableCell sx={{ padding: 0, fontWeight: "normal", fontSize: 12 }}>
                                                                <Typography sx={{ padding: 0, fontWeight: "normal", fontSize: 12, lineHeight: 0.5 }}>{v.type}</Typography>
                                                                {v.description && <span style={{ fontSize: 10, m: 0 }}>({v.description})</span>}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: 0, fontWeight: "normal", fontSize: 12, textAlign: "right", fontWeight: 600 }}>Rs. {details?.voucher?.is_installment ? parseInt(v.amount) / 2 : v.amount}.00/-</TableCell>
                                                        </TableRow>
                                                    ))
                                                    : ""}

                                            </TableBody>
                                        </Table>

                                    </Box>
                                    <Box sx={{
                                        borderTop: "1px solid #000"
                                    }}>
                                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Typography sx={{
                                                fontSize: 12,
                                                fontWeight: "bold"
                                            }}>Amount Payable</Typography>
                                            <Typography sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                textAlign: "right",
                                            }}>Rs. {parseFloat(details?.amount_payable)}.00/-</Typography>
                                        </Stack>
                                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Typography sx={{
                                                fontSize: 10,
                                            }}>Amount Payable after due date</Typography>
                                            <Typography sx={{
                                                fontSize: 10,
                                                textAlign: "right",
                                                fontStyle: "italic"
                                            }}>{dayjs(new Date()).diff(dayjs(details.due_date), "day") > 0 ? parseFloat(details.amount_payable) + (parseFloat(dayjs(new Date()).diff(dayjs(details.issue_date), "day")) * 100) : details.amount_payable}.00/-</Typography>
                                        </Stack>
                                    </Box>

                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>1</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }} width={"95%"} >Tuition Fee is payable in advance and once paid is<b> Non-Refundable</b></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>2</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12, textDecoration: "underline" }} width={"95%"} >Tuition Fee must be paid before the last date of payment stated on the Fee Bill. A fine of <b>Rs. 100/- per day</b> will be charged after lapse of last date of payment.</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>3</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12, }} width={"95%"} >If a student fails to pay tuition fee within 5 days after the last date of payment. He/She will not be permitted to sit in the class.</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>4</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12, }} width={"95%"} >	Tuition Fee for the month(s) of June - August Quarter must be paid before the begining of <b>Summer Vacation </b></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>5</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12, }} width={"95%"} > If a student is to be withdrawn, A notice of one month must be given in writing or one month's fee is payment on lieu of the notice </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ verticalAlign: "top" }}>
                                            <TableCell sx={{ padding: 0, fontSize: 12 }}>6</TableCell>
                                            <TableCell sx={{ padding: 0, fontSize: 12, }} width={"95%"} > If a student fails to give fee bill to his/her parents. It is the responsibility of the parents to bring it to the notice of the school account officer <b>Rs. 100/-</b> will be charged if a fee bill is reported lost and duplicate copy asked for </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Grid>


                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} >
                                    <Box sx={{ display: "flex", flex: 1 }}>
                                        <Typography sx={{
                                            fontSize: 12,
                                            width: "30%",
                                            fontWeight: 600,
                                        }}>Issue Date</Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                        }}>{dayjs(details?.issue_date).format("DD-MMMM-YYYY")}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
                                        <Typography sx={{
                                            fontSize: 12,
                                            width: "30%",
                                            fontWeight: 600,
                                        }}>Due Date</Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                        }}>{dayjs(details?.due_date).format("DD-MMMM-YYYY")}</Typography>
                                    </Box>
                                </Stack>
                            </Grid>


                            <Grid item xs={12}>
                                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} >
                                    <Box sx={{ display: "flex", flex: 1 }}>
                                        <Typography sx={{
                                            marginTop: 6,
                                            fontSize: 12,
                                            fontWeight: 600,
                                            borderTop: "1px solid #000"
                                        }}>Accounts Office</Typography>

                                    </Box>
                                </Stack>
                            </Grid>


                        </Grid>
                    </Grid>
                ))}
            </Grid> : "Loading"}
        </Container>
    )
}

export default VoucherPreview