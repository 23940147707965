
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SchoolIcon from '@mui/icons-material/School';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ClassIcon from '@mui/icons-material/Class';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CreateIcon from '@mui/icons-material/Create';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ContactsIcon from '@mui/icons-material/Contacts';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import GroupIcon from '@mui/icons-material/Group';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

export default {
    DoneAll: DoneAllIcon,
    RemoveDone: RemoveDoneIcon,
    NewReleases:NewReleasesIcon,
    ExpandLess: ExpandLessIcon,
    ExpandMore: ExpandMoreIcon,
    Group: GroupIcon,
    BorderColor: BorderColorIcon,
    FileDownload: FileDownloadIcon,
    Dashboard: DashboardIcon,
    Inventory2: Inventory2Icon,
    School: SchoolIcon,
    Restaurant: RestaurantIcon,
    Class: ClassIcon,
    AccountBalance: AccountBalanceIcon,
    RequestQuote: RequestQuoteIcon,
    PeopleAlt: PeopleAltIcon,
    Security: SecurityIcon,
    AccountBox: AccountBoxIcon,
    Visibility: VisibilityIcon,
    VisibilityOff: VisibilityOffIcon,
    Create: CreateIcon,
    OpenInFull: OpenInFullIcon,
    DeleteForever: DeleteForeverIcon,
    Settings: SettingsIcon,
    Search: SearchIcon,
    Add: AddIcon,
    Close: CloseIcon,
    ChildCare: ChildCareIcon,
    FamilyRestroom: FamilyRestroomIcon,
    Contacts: ContactsIcon,
    EscalatorWarning: EscalatorWarningIcon,
    PlaylistAddCheck: PlaylistAddCheckIcon,
    GppGood: GppGoodIcon,
    MedicalInformation: MedicalInformationIcon,
    LunchDining: LunchDiningIcon,
    ArrowRightAlt: ArrowRightAltIcon,
    Check: CheckIcon

}