import icons from "../../common/icons";

export const ADD_STUDENT_SLICE_NAME = 'addStudentSlice'

export const STEPS = [
    { label: "Personal Information", icon: icons.ChildCare, isStepCompleted: false },
    { label: "Parental Information", icon: icons.FamilyRestroom, isStepCompleted: false },
    { label: "Emergency Contacts", icon: icons.Contacts, isStepCompleted: false },
    { label: "Parental Responsibilities", icon: icons.EscalatorWarning, isStepCompleted: false },
    { label: "Collection Arrangements", icon: icons.PlaylistAddCheck, isStepCompleted: false },
    { label: "Permissions", icon: icons.GppGood, isStepCompleted: false },
    { label: "Medical History", icon: icons.MedicalInformation, isStepCompleted: false },
    { label: "Class & Package", icon: icons.Inventory2, isStepCompleted: false },
    { label: "Meals", icon: icons.LunchDining, isStepCompleted: false },
]