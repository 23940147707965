import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { PERSIST_REDUX_KEY, ROOT_PERSIST_KEY } from "./constant";
import storage from "redux-persist/lib/storage";
import { clear, removeItem } from '../utilities/storage';
import {
    persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { HANDLE_LOGOUT } from '../screen/Auth/constants';
import mealReducer from '../screen/Meals/mealSlice';
import authReducer from '../screen/Auth/authSlice';
import packageReducer from '../screen/Package/packageSlice';
import classesReducer from '../screen/Classes/classSlice';
import groupsReducer from '../screen/Groups/groupsSlice';
import addStudentReducer from '../screen/AddStudents/addStudentSlice';
import createStudentReducer from '../screen/CreateStudent/createStudentSlice';
import studentsReducer from '../screen/Students/studentsSlice';
import voucherReducer from '../screen/Voucher/voucherSlice';
import financeReducer from '../screen/Finance/financeSlice';
import voucherConfigReducer from '../screen/Voucher/VoucherConfig/voucherConfigSlice';
import usersReducer from '../screen/Users/usersSlice';
import designationsReducer from '../screen/Designations/designationsSlice';
import titleReducer from '../screen/Titles/titleSlice';
import addtutorReducer from '../screen/AddTutor/addtutorslice';
import employeeReducer from '../screen/Employee/employeeSlice';
import branchesReducer from '../screen/Branches/branchesSlice';
import languagesReducer from '../screen/Languages/languagesSlice';
import ethnicitiesReducer from '../screen/Ethnicities/ethnicitiesSlice';
import roleReducer from '../screen/Roles/roleSlice';
import commonReducer from '../common/commonSlice';
// import employeeReducer from '../screen/Employee/employeeSlice';


const persistConfig = {
    key: ROOT_PERSIST_KEY,
    storage,
    version: 1,
    // blacklist: ['auth']
}

const appReducers = combineReducers({
    auth: authReducer,
    meal: mealReducer,
    package: packageReducer,
    classes: classesReducer,
    groups: groupsReducer,
    addStudent: addStudentReducer,
    createStudent: createStudentReducer,
    students: studentsReducer,
    vouchers: voucherReducer,
    finance: financeReducer,
    voucherConfig: voucherConfigReducer,
    users: usersReducer,
    designations: designationsReducer,
    title: titleReducer,
    addTutor:addtutorReducer,
    employees: employeeReducer,
    branches: branchesReducer,
    languages: languagesReducer,
    ethnicities: ethnicitiesReducer,
    role: roleReducer,
    common: commonReducer
})


const rootReducer = (state, action) => {
    if (action.type === HANDLE_LOGOUT) {
        removeItem(PERSIST_REDUX_KEY)
        clear()
        state = undefined
    }
    return appReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            },
        }),
})

export const dispatch = store.dispatch

export default store