import { toast } from "react-toastify";


export const AUTH_KEY_ITEM = "secret"


export const COMMON_STYLES = {
    body1: {
        fontSize: 14,
        lineHeight: 1.5
    },
    chip: {
        fontSize: 12,
        px: 1,
        py: 0.5,
        mx:1,
        my: 0.5
    },
    icon: {
        width: 20,
        height: 20,
        padding: 0
    }
}


export const TOAST = {
    POSITION: "bottom-left",
    ICON: true, //false, <Icon />
    THEME: 'light', //"dark", "colored",
    TIMEOUT: 5000,
    PAUSE_ON_BLUR: false,
    DELAY: 0
};

export const DEFAULT_TOAST_ERROR = 'Error Occured!';