
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import MuiTextField from '@mui/material/TextField'

const TextField = styled(MuiTextField)(({ theme }) => ({

}))

const CustomTextField = (props) => {
    return (
        <>
            {props.error && <Typography sx={{
                background: theme => theme.palette.error.light,
                width: "fit-content",
                padding: "3px 10px",
                marginBottom: 0.25,
                float: "right",
                borderRadius: 0.5,
                color: theme => theme.palette.background.paper
            }}>{props.error}</Typography>}
            <TextField {...props} />
        </>
    )
}

export default CustomTextField