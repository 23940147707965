import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LANGUAGES_REQUESTED, LANGUAGES_SLICE_NAME } from "./constants";
import { getLanguagesApi } from "../../api";


export const languagesRequested = createAsyncThunk(LANGUAGES_REQUESTED, async () => {
    try {
        const response = await getLanguagesApi()
        return response
    } catch (err) {
        throw err
    }
})


const languagesSlice = createSlice({
    name: LANGUAGES_SLICE_NAME,
    initialState: {
        languageId: null,
        languageModelOpen: false,
        languageName: "",
        languagesList: []
    },
    reducers: {
        handleChangelanguageId: (state, action) => {
            state.languageId = action.payload
        },
        handleChangelanguageModelOpen: (state, action) => {
            state.languageModelOpen = action.payload
        },
        handleChangelanguageName: (state, action) => {
            state.languageName = action.payload
        },
        handleResetModel: (state) => {
            state.languageId = null
            state.languageName = ""
            state.languageModelOpen = false
        }
    },
    extraReducers: builder => { 
        builder.addCase(languagesRequested.fulfilled, (state, action) => {
            state.languagesList = action.payload.data.languages
        })
    }
})


export const {
    handleChangelanguageId,
    handleChangelanguageModelOpen,
    handleChangelanguageName,
    handleResetModel
} = languagesSlice.actions

export const getlanguageName = state => state.languages.languageName
export const getlanguageModelOpen = state => state.languages.languageModelOpen
export const getlanguageId = state => state.languages.languageId
export const getlanguageList = state => state.languages.languagesList

export default languagesSlice.reducer