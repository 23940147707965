export const BREADCRUMBS = [
    {
        name: "Employees",
        icon: "",
        path: `/maange-inventory`
    },
    {
        name: "Designations",
        icon: "",
        path: `/Designations`
    },
]

export const TABLE_HEADS = [
    { name: "Designation Id" },
    { name: "Designation Name" },
    { name: "Action", align: "right" },
]

export const DESIGNATIONS_SLICE_NAME = 'designationsSlice'
export const DESIGNATIONS_REQUESTED = `${DESIGNATIONS_SLICE_NAME}/designationRequested`