import { Box, Button, Container, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import hexy from '../../utilities/hexy'
import CustomTextField from '../../components/TextField'
import PasswordStrengthBar from 'react-password-strength-bar';
import Icons from '../../common/icons';
import { updatePasswordApi } from '../../api';
import { getUser, handleLogout } from '../Auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { handleRemoveLoader, handleShowLoader, handleShowLogoutModel } from '../../common/commonSlice';
import { openErrorToast } from '../../common/toast';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [rePassword, setRePassword] = React.useState("")
  const [match, setMatch] = React.useState(false)
  const [strength, setStrength] = React.useState(0)
  const [show, setShow] = React.useState(false)

  const user = useSelector(getUser)
  const dispatch = useDispatch()

  const handlePasswordChange = (e) => {
    const value = e.target.value
    var passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (passwordRegex.test(value)) {
      setStrength(1)
    } else {
      setStrength(0)
    }
    setPassword(value)
  }

  return (
    <Container maxWidth="xlg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box sx={{
            background: theme => theme.palette.common.white,
            p: 2,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography component={"h3"} variant='h4' color="primary">Change Password</Typography>

            <CustomTextField type={!show ? "password" : "text"} value={oldPassword} onChange={e => setOldPassword(e.target.value)} label="Old Password" fullWidth size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {!show ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
            <Box>
              <CustomTextField type={!show ? "password" : "text"} InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {!show ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                  </IconButton>
                  {strength === 1 ? <Icons.Check color='success' /> : <Icons.NewReleases color='error' />}
                </InputAdornment>,
              }} value={password} onChange={e => handlePasswordChange(e)} label="New Password" fullWidth size="small" />
            </Box>
            <CustomTextField type={!show ? "password" : "text"} value={rePassword} onChange={e => {
              setRePassword(e.target.value)
              if (password == e.target.value) {
                setMatch(true)
              } else {
                setMatch(false)
              }
            }} label="ConFirm Password" fullWidth size="small" InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton onClick={() => setShow(!show)}>
                  {!show ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                </IconButton>
                {match ? <Icons.DoneAll color='success' /> : <Icons.RemoveDone color='error' />}
              </InputAdornment>,
            }} />

            <Button variant="contained" size="small" disabled={!match}
              onClick={async () => {
                try {
                  dispatch(handleShowLoader())
                  await updatePasswordApi({
                    id: user.id,
                    old_password: oldPassword,
                    new_password: password
                  })
                  dispatch(handleRemoveLoader())
                  dispatch(handleShowLogoutModel())
                } catch (err) {
                  dispatch(handleRemoveLoader())
                  openErrorToast(err.message ? err.message : err)
                }
              }}

            >Change Password</Button>
          </Box>
        </Grid>
      </Grid>

    </Container>
  )
}

export default ChangePassword