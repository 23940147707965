import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTitlesApi } from "../../api";
import { TITLES_REQUESTED } from "./constants";
import { TITLES_SLICE_NAME } from "./constants";

export const titlesRequested = createAsyncThunk(TITLES_REQUESTED, async () => {
    try {
        const response = await getTitlesApi()
        return response
    } catch (err) {
        throw err
    }
})

const titleSlice = createSlice({
    name: TITLES_SLICE_NAME,
    initialState: {
        title: {
            id: null,
            name: "",
            skills: []
        },
        titlesList: [],
        titleModelOpen: false,
        currentPage: 0,
        rowsPerPage: 10
    },
    reducers: {
        handleChangeTitleId: (state, action) => { state.title.id = action.payload },
        handleChangeTitleModelOpen: (state, action) => { state.titleModelOpen = action.payload },
        handleChangeTitleCurrentPage: (state, action) => { state.currentPage = action.payload },
        handleChangeTitleRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeTitleName: (state, action) => { state.title.name = action.payload },
        handleChangeTitleSkill: (state, action) => {
            if (action.payload.op === "add") {
                state.title.skills = [...state.title.skills, { skill: action.payload.value }]
            } else if (action.payload.op === "del") {
                state.title.skills.splice(action.payload.index, 1)
            } else {
                state.title.skills = action.payload
            }
        },
        handleResetTitle: (state) => {
            state.title.id = null
            state.title.name = ""
            state.title.skills = []
            state.titleModelOpen =false
            state.currentPage = 0
            state.rowsPerPage = 10
        },
        handleResetTitleSlice: (state) => {
            state.title.id = null
            state.title.name = ""
            state.title.skills = []
            state.titlesList = []
            state.titleModelOpen =false
            state.currentPage = 0
            state.rowsPerPage = 10
        }
    },
    extraReducers: builder => {
        builder.addCase(titlesRequested.fulfilled, (state, action) => {
            state.titlesList = action.payload.data.titles.map(d => ({ ...d, erpId: `TA-HRMS-00${d.id}` }))
        })
    }
})

export const {
    handleChangeTitleId,
    handleChangeTitleName,
    handleChangeTitleSkill,
    handleResetTitle,
    handleResetTitleSlice,
    handleChangeTitleModelOpen,
    handleChangeTitleCurrentPage,
    handleChangeTitleRowsPerPage
} = titleSlice.actions

export const getTitleId = state => state.title.title.id
export const getTitleName = state => state.title.title.name
export const getTitleSkill = state => state.title.title.skill
export const getTitleList = state => state.title.titlesList
export const getTitleModelOpen = state => state.title.titleModelOpen
export const getTitleCurrentPage = state => state.title.currentPage
export const getTitleRowsPerPAge = state => state.title.rowsPerPage

export default titleSlice.reducer