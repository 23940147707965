export const ADD_STUDENTS_TABLE_COLUMN_HEADS = [
    { name: "Registration Id" },
    { name: "Full Name" },
    { name: "Nick Name" },
    { name: "Date Of Birth" },
    { name: "Gender" },
    { name: "Status" },
    { name: "Registration Date" },
    { name: "Action" , align: "right"},
]

export const STUDENTS_SLICE_NAME = 'studentSlice'
export const STUDENTS_REQUESTED = `${STUDENTS_SLICE_NAME}/studentsRequested`
export const STUDENTS_CONFIG_PRE_REQUISITES_REQUESTED = `${STUDENTS_SLICE_NAME}/studentConfigPreRequisitesRequested`