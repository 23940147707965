import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GROUPS_REQUESTED, GROUPS_SLICE_NAME } from "./constatns";
import { getAllGroupsApi } from "../../api";

export const groupsRequested = createAsyncThunk(GROUPS_REQUESTED, async () => {
    try {
        const response = await getAllGroupsApi()
        return response
    } catch (err) {
        throw err
    }
})

const groupsSlice = createSlice({
    name: GROUPS_SLICE_NAME,
    initialState: {
        groupModelOpen: false,
        groupName: "",
        groupId: null,
        groupList: []
    },
    reducers: {
        handleChangeGroupModelOpen: (state, action) => { state.groupModelOpen = action.payload },
        handleChangeGroupName: (state, action) => { state.groupName = action.payload },
        handleChangeGroupId: (state, action) => { state.groupId = action.payload },
        handleResetModel: (state, action) => {
            state.groupModelOpen = false
            state.groupName = ""
            state.groupId = null
        },
        handleResetSlice: (state, action) => {
            state.groupModelOpen = false
            state.groupName = ""
            state.groupId = null
            state.groupList = []
        }
    },
    extraReducers: builder => {
        builder.addCase(groupsRequested.fulfilled, (state, action) => {
            state.groupList = action.payload.data.group
        })
    }
})


export const {
    handleChangeGroupModelOpen,
    handleChangeGroupName,
    handleChangeGroupId,
    handleResetModel,
    handleResetSlice
} = groupsSlice.actions

export const getGroupModelOpen = state => state.groups.groupModelOpen
export const getGroupName = state => state.groups.groupName
export const getGroupId = state => state.groups.groupId
export const getGroupList = state => state.groups.groupList

export default groupsSlice.reducer