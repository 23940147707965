import { createSlice } from "@reduxjs/toolkit";


const commonSlice = createSlice({
    name: "common",
    initialState: {
        loading: false,
        logoutModel: false,
    },
    reducers: {
        handleShowLoader: (state, action) => { state.loading = true },
        handleRemoveLoader: (state, action) => { state.loading = false },
        handleShowLogoutModel: (state, action) => { state.logoutModel = true },
        handleRemoveLogoutModel: (state, action) => { state.logoutModel = false },
    }
})

export const {
    handleShowLoader,
    handleRemoveLoader,
    handleShowLogoutModel,
    handleRemoveLogoutModel
} = commonSlice.actions

export const getLoading = state => state.common.loading
export const getLogOutModel = state => state.common.logoutModel

export default commonSlice.reducer