import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH_SLICE_NAME, HANDLE_LOGIN_REQUESTED, HANDLE_SIGNUP_REQUESTED } from "./constants";
import { getAuthorizationApi, postAuthorizationApi } from "../../api";
import { removeAuthToken } from "../../api/utils";
import { clear } from "../../utilities/storage";


export const userLoginRequested = createAsyncThunk(HANDLE_LOGIN_REQUESTED, async ({ email, password }) => {
    try {
        const response = await getAuthorizationApi({
            email, password
        })
        return response
    } catch (err) {
        throw err
    }
})

export const signupRequested = createAsyncThunk(HANDLE_SIGNUP_REQUESTED, async ({ name, email, password, role }) => {
    try {
        const response = await postAuthorizationApi({
            name, email, password, role
        })
        return response
    } catch (err) {
        throw err
    }
})

const authSlice = createSlice({
    name: AUTH_SLICE_NAME,
    initialState: {
        isLoggedIn: false,
        user: null,
        role: null,
        permissions: []
    },
    reducers: {
        handleChangeIsLoggedIN: (state, action) => { state.isLoggedIn = action.payload },
        handleChangeLoginName: (state, action) => { state.login.name = action.payload },
        handleChangeLogin: (state, action) => {
           
        },
        handleResetSlice: (state, action) => {
            state.user = null
            state.role = null
            state.permissions = []
        },
        handleLogout: (state, action) => {
            removeAuthToken()
            state.user = null
            state.role = null
            state.permissions = []
            state.isLoggedIn = false
            clear()
        }
    },
    extraReducers: builder => {
        builder.addCase(userLoginRequested.fulfilled, (state, action) => {
            state.user = action.payload.data.user
            state.role = action.payload.data.user.role
            state.permissions = action.payload.data.permissions
            state.isLoggedIn = true
        });
        builder.addCase(userLoginRequested.rejected, (state, action) => {
            state.isLoggedIn = false
        });
        builder.addCase(signupRequested.fulfilled, (state, action) => {
        });
    }
})

export const {
    handleChangeIsLoggedIN,
    handleChangeLoginEmail,
    handleChangeLoginPassword,
    handleChangeLoginName,
    handleLogout
} = authSlice.actions

export const getIsLoggedIn = (state) => state.auth.isLoggedIn
export const getRole = (state) => state.auth.role
export const getPermissions = (state) => state.auth.permissions
export const getUser = (state) => state.auth.user


export default authSlice.reducer