import { List, ListItem, Container, Grid, Box, Typography, Stack, Divider } from "@mui/material"
import Calendar from "../../components/Calendar"
import Icons from "../../common/icons"
import BarChart from "../../components/BarChart"
import NoticeCard from "../../components/NoticeCard"


const Dashboard = () => {
    return (
        <Container maxWidth={"xl"}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={9} xl={9}>
                    <Grid container sx={{ mb: 2 }} spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack gap={1} alignItems={"center"} sx={{
                                background: "#e8e6fb",
                                borderRadius: 4,
                                py: 3,
                                px: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-around"
                            }}>
                                <Typography className="hexagon" variant="span" sx={{
                                    background: "#5f49ff",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <Icons.Group />
                                </Typography>
                                <Box>
                                    <Typography variant="h6">Total Students</Typography>
                                    <Typography variant="h2">500</Typography>
                                </Box>
                            </Stack>
                        </Grid>


                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack gap={1} alignItems={"center"} sx={{
                                background: "#f8e5e9",
                                borderRadius: 4,
                                py: 3,
                                px: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-around"
                            }}>
                                <Typography className="hexagon" variant="span" sx={{
                                    background: "#ff2d5d",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <Icons.Group />
                                </Typography>
                                <Box>
                                    <Typography variant="h6">Total Students</Typography>
                                    <Typography variant="h2">500</Typography>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack gap={1} alignItems={"center"} sx={{
                                background: "#dfedf8",
                                borderRadius: 4,
                                py: 3,
                                px: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-around"
                            }}>
                                <Typography className="hexagon" variant="span" sx={{
                                    background: "#218cde",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <Icons.Group />
                                </Typography>
                                <Box>
                                    <Typography variant="h6">Total Students</Typography>
                                    <Typography variant="h2">500</Typography>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack gap={1} alignItems={"center"} sx={{
                                background: "#fbecd7",
                                borderRadius: 4,
                                py: 3,
                                px: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-around"
                            }}>
                                <Typography className="hexagon" variant="span" sx={{
                                    background: "#f49d1c",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <Icons.Group />
                                </Typography>
                                <Box>
                                    <Typography variant="h6">Total Students</Typography>
                                    <Typography variant="h2">500</Typography>
                                </Box>
                            </Stack>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                background: theme => theme.palette.common.white,
                                borderRadius: 4
                            }}>
                                <Typography sx={{
                                    px: 2,
                                    py: 1.5
                                }} variant="h5">Notice Board</Typography>
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                background: theme => theme.palette.common.white,
                                borderRadius: 4
                            }}>
                                <Typography sx={{
                                    px: 2,
                                    py: 1.5
                                }} variant="h5">Activity Log</Typography>
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                background: theme => theme.palette.common.white,
                                borderRadius: 4
                            }}>
                                <Typography sx={{
                                    px: 2,
                                    py: 1.5
                                }} variant="h5">Audit Log</Typography>
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                                <Divider />
                                <NoticeCard />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} xl={3}>
                    <Box sx={{
                        background: theme => theme.palette.common.white,
                        p: 2,
                        borderRadius: 4,
                        mb: 2
                    }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Calendar</Typography>
                        <Calendar />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: theme => theme.palette.common.white,
                        borderRadius: 4
                    }}>
                        <Typography sx={{
                            px: 2,
                            py: 1.5
                        }} variant="h5">Active Staff</Typography>
                        <NoticeCard />
                        <Divider />
                        <NoticeCard />
                        <Divider />
                        <NoticeCard />
                        <Divider />
                        <NoticeCard />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Dashboard