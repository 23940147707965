export const BREADCRUMBS = [
    {
        name: "Employees",
        icon: "",
        path: `/maange-inventory`
    },
    {
        name: "Titles",
        icon: "",
        path: `/Departments`
    },
]

export const TABLE_HEADS = [
    { name: "Title Id" },
    { name: "Title Name" },
    // { name: "Title Skills" },
    { name: "Action", align: "right" },
]

export const TITLES_SLICE_NAME = 'titleSlice'
export const TITLES_REQUESTED = `${TITLES_SLICE_NAME}/titleRequested`