import React from 'react'
import { Container, Grid, Typography, TextField, Tooltip, Fab, IconButton, Box, Stack, useTheme } from '@mui/material'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import Icons from '../../common/icons'
import { PACKAGE_TABLE_HEADS } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAmount, getDeleteModelOpen, getName, getPackageClassesList, getPackageClassId, getPackageEndTime, getPackageId, getPackageModelOpen, getPackages, getPackageStartTime, getTypeOfMeal, handleChangeAmount, handleChangeDeleteModelOpen, handleChangeName, handleChangePackageClassId, handleChangePackageEndTime, handleChangePackageId, handleChangePackageModelOpen, handleChangePackageStartTime, handleChangeTypeOfMeal, handleResetPackageModel, packageClassesRequested, packagesRequested } from './packageSlice'
import Dialog from '../../components/Modal'
import { deletePackageApi, postPackageApi, updatePackageApi } from '../../api'
import DeleteModel from '../../common/deleteModel'
import AppTimePicker from '../../components/TimePicker'
import dayjs from 'dayjs'
import CustomizeSelect, { MenuItem } from '../../components/Select'
import useCan from '../../hooks/useCan'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'

const Packages = () => {

  const [search, setSearch] = React.useState(null)

  const packageModelOpen = useSelector(getPackageModelOpen)
  const deleteModelOpen = useSelector(getDeleteModelOpen)
  const name = useSelector(getName)
  const typeOfMeal = useSelector(getTypeOfMeal)
  const amount = useSelector(getAmount)
  const packageId = useSelector(getPackageId)
  const packages = useSelector(getPackages)
  const startTime = useSelector(getPackageStartTime)
  const endTime = useSelector(getPackageEndTime)
  const classId = useSelector(getPackageClassId)
  const classesList = useSelector(getPackageClassesList)


  const add = useCan("can_add_packages")
  const edit = useCan("can_edit_packages")
  const del = useCan("can_delete_packages")

  const dispatch = useDispatch()


  const load = async () => {
    try {
      dispatch(handleShowLoader())
      await dispatch(packagesRequested()).unwrap()
      await dispatch(packageClassesRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)
    }
  }
  React.useEffect(() => {
    load()
  }, [])

  const ACTION_BUTTON_ARRAY = [
    {
      permission: edit,
      label: "Edit",
      icon: Icons.Create,
      color: "primary",
      action: (_package) => {
        dispatch(handleChangePackageId(_package.id))
        dispatch(handleChangeName(_package.name))
        dispatch(handleChangeAmount(_package.amount))
        dispatch(handleChangeTypeOfMeal(_package.pkg_type))
        dispatch(handleChangePackageClassId(_package.class_id))
        dispatch(handleChangePackageStartTime(new Date(null, null, null, _package.start_time?.split(":")[0], _package.start_time?.split(":")[1], _package.start_time?.split(":")[2])))
        dispatch(handleChangePackageEndTime(new Date(null, null, null, _package.end_time?.split(":")[0], _package.end_time?.split(":")[1], _package.end_time?.split(":")[2])))
        dispatch(handleChangePackageModelOpen(true))
      }
    },
    {
      permission: del,
      label: "Delete",
      icon: Icons.DeleteForever,
      color: "error",
      action: async (_package) => {
        try {
          dispatch(handleShowLoader())
          await deletePackageApi({ id: _package.id })
          await dispatch(packagesRequested()).unwrap()
          dispatch(handleRemoveLoader())
          openSuccessToast("Success")
        } catch (err) {
          dispatch(handleRemoveLoader())
          openErrorToast(err.message ? err.message : err)
        }
      }
    },
  ]

  const theme = useTheme()
  return (
    <Container maxWidth="xl" spacing={2}>

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container gap={2}>
          <Grid item xs={12} sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}>
            <Typography sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4'>Packages</Typography>
            <TextField sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%"
              },
            }} size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
              startAdornment: <Icons.Search />
            }} />
          </Grid>
          <Grid item xs={12}>
            <ExplicitTable
              columns={PACKAGE_TABLE_HEADS}
              size="small"
            >
              {packages.length > 0 ?
                packages.map(_package => (
                  <StyledTableRow key={_package.id}>
                    <StyledTableCell sx={{ fontWeight: 700 }}>{_package.name}</StyledTableCell>
                    <StyledTableCell>
                      <Stack>
                        <Typography component={"p"} sx={{ fontSize: 16 }}>{_package.class_name}</Typography>
                        <Typography component={"span"} sx={{ fontSize: 10 }}>{_package.description}</Typography>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>{_package.pkg_type}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right", fontWeight: "bold" }}>{dayjs(new Date(null, null, null, _package.start_time.split(":")[0], _package.start_time.split(":")[1], _package.start_time.split(":")[2])).format("hh:mm:ss A")}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right", fontWeight: "bold" }}>{dayjs(new Date(null, null, null, _package.end_time.split(":")[0], _package.end_time.split(":")[1], _package.end_time.split(":")[2])).format("hh:mm:ss A")}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right", fontStyle: "italic", fontWeight: 700 }}>{_package.amount}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      {ACTION_BUTTON_ARRAY.filter(r => r.permission).map(btn => (
                        <IconButton size='small' key={btn.label} onClick={() => btn.action(_package)} >
                          <btn.icon color={btn.color} />
                        </IconButton>
                      ))
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))
                : <StyledTableRow>
                  <StyledTableCell colSpan={PACKAGE_TABLE_HEADS.length} >Nothing Found Here</StyledTableCell>
                </StyledTableRow>}
            </ExplicitTable>
          </Grid>
        </Grid>
      </Box>


      {add && <Tooltip placement='left' title="Add New Meal">
        <Fab color='primary' onClick={e => dispatch(handleChangePackageModelOpen(true))} sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}

      <Dialog
        dailogOpen={packageModelOpen}
        title={"Add Package"}
        hasCloseIcon={true}
        size="xs"
        clickAwayListener={false}
        handleClose={() => { dispatch(handleResetPackageModel()) }}
        actionsButtonArray={[
          {
            label: "Cancel",
            color: "error",
            variant: "contained",
            action: () => { dispatch(handleResetPackageModel()) },
            size: "small"
          },
          {
            label: "Save",
            color: "primary",
            variant: "contained",
            action: async (e) => {
              try {
                dispatch(handleShowLoader())
                packageId === null ? await postPackageApi({ name, pkg_type: typeOfMeal, amount, start_time: dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"), end_time: dayjs(endTime).format("YYYY-MM-DD HH:mm:ss"), class_id: classId }) : await updatePackageApi({ id: packageId, name, pkg_type: typeOfMeal, amount, start_time: dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"), end_time: dayjs(endTime).format("YYYY-MM-DD HH:mm:ss"), class_id: classId })
                await dispatch(packagesRequested()).unwrap()
                dispatch(handleResetPackageModel())
                dispatch(handleRemoveLoader())
                openSuccessToast("Success")
              } catch (err) {
                dispatch(handleRemoveLoader())
                openErrorToast(err.message ? err.message : err)
              }
            },
            size: "small"
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Name" value={name} onChange={e => dispatch(handleChangeName(e.target.value))} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Type" value={typeOfMeal} onChange={e => dispatch(handleChangeTypeOfMeal(e.target.value))} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <CustomizeSelect fullWidth size="small" label="Select Class"
              value={classId}
              onChange={(e) => {
                dispatch(handleChangePackageClassId(e.target.value))
              }}
            >
              <MenuItem value={null}>-- Please Select ---</MenuItem>
              {classesList && classesList.length > 0 && classesList.map((c) => (
                <MenuItem value={c.id} key={c.id} sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}>
                  <Typography variant='p' component={"p"} sx={{
                    fontSize: 16,
                    fontWeight: 600
                  }}>{c.name}</Typography>
                  <Typography component={"span"} sx={{
                    fontSize: 11
                  }} >{c.description}</Typography>
                </MenuItem>
              ))}
            </CustomizeSelect>
          </Grid>
          <Grid item xs={!2} sm={12}>
            <AppTimePicker fullWidth size="small" label="Start Time" value={dayjs(startTime)} onChange={(e) => {
              dispatch(handleChangePackageStartTime(e))
            }} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <AppTimePicker fullWidth size="small" label="End Time" value={dayjs(endTime)} onChange={e => dispatch(handleChangePackageEndTime(e))} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Amount" type='number' value={amount} onChange={e => dispatch(handleChangeAmount(e.target.value))} />
          </Grid>
        </Grid>
      </Dialog>


      <DeleteModel open={deleteModelOpen} handleDelete={async () => {
        try {
          dispatch(handleShowLoader())
          await deletePackageApi({ id: packageId })
          dispatch(packagesRequested()).unwrap()
          dispatch(handleChangeDeleteModelOpen(false))
          dispatch(handleChangePackageId(null))
          dispatch(handleRemoveLoader())
          openSuccessToast("Success")
        } catch (err) {
          dispatch(handleRemoveLoader())
          openErrorToast(err.message ? err.message : err)
        }
      }} handleClose={() => {
        dispatch(handleChangeDeleteModelOpen(false))
        dispatch(handleChangePackageId(null))
      }} />
    </Container>
  )
}

export default Packages