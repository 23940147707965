import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <Box sx={{
      width: "100%",
      height: "100vh",
      position:"absolute",
      flexDirection:"column",
      gap: 1,
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      backdropFilter: "blur(100%)",
      zIndex: 99999
    }}>
      <CircularProgress  sx={{
        color:theme => theme.palette.secondary.main,
      }} />
      <Typography sx={{fontSize: 18}}>Please Wait</Typography>
    </Box>
  )
}

export default Loader