import React from "react"
import { useLocation } from "react-router-dom"
import { getStudentByIdApi } from "../../api"
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import hexy from "../../utilities/hexy"


const StudentProfile = () => {

    const location = useLocation()

    const [profile, setProfile] = React.useState(null)

    const getStudentProfile = async () => {
        try {
            let response = await getStudentByIdApi({ id: location.state.id })
            response = response?.data?.student
            setProfile(response)
        } catch (err) {
            console.log(err.message ? err.message : err)
        }
    }
    React.useEffect(() => {
        if (location.state && location.state.id) {
            getStudentProfile()
        }
    }, [location.state])

    return (
        <Container maxWidth="xl" >
            {profile && profile.full_name ?
                <>
                    <Box mb={2} sx={{
                        background: theme => theme.palette.common.white,
                        p: 2,
                        borderRadius: 4
                    }}>
                        <Grid container spacing={2} sx={{
                        }}>
                            <Grid item xs={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Grid container spacing={2} >
                                    <Grid xs={12} md={4} xl={2} sx={{
                                        padding: 3,
                                        margin: "äuto",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box sx={{
                                            borderRadius: "50%",
                                            padding: 1,
                                            boxShadow: "0 10px 20px #aaa"
                                        }}>
                                            <img src={"/logo512.png"} style={{
                                                width: "100%"
                                            }} />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={8}>
                                        <Stack padding={3} flexDirection={"column"}>
                                            <Typography variant="h2" mb={2} >{profile.full_name}</Typography>

                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }} >Registration Id</Typography>
                                                <Typography variant="span">{profile.id ? profile.id : "NA"}</Typography>
                                            </Typography>


                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Nick Name</Typography>
                                                <Typography variant="span">{profile.date_of_birth ? dayjs(profile.date_of_birth).format("DD-MMM-YYYY") : "NA"}</Typography>
                                            </Typography>


                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Gender</Typography>
                                                <Typography variant="span">{profile.gender ? profile.gender : "NA"}</Typography>
                                            </Typography>


                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Date Of Birth</Typography>
                                                <Typography variant="span">{profile.branch ? profile.branch?.name : "NA"}</Typography>
                                            </Typography>

                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Branch</Typography>
                                                <Typography variant="span">{profile.branch ? profile.branch?.name : "NA"}</Typography>
                                            </Typography>

                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Session</Typography>
                                                <Typography variant="span">{profile.session ? `${dayjs(profile.session.from).format("YYYY")} - ${dayjs(profile.session.to).format("YYYY")}` : "NA"}</Typography>
                                            </Typography>

                                            <Typography variant="p" sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }} >
                                                <Typography variant="span" sx={{ marginRight: 2 }}>Branch</Typography>
                                                <Typography variant="span">{profile.branch ? profile.branch?.name : "NA"}</Typography>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mb={2} sx={{
                        background: theme => theme.palette.common.white,
                        p: 2,
                        borderRadius: 4,

                    }}>
                        <Grid container spacing={2} sx={{
                        }}>
                            <Grid item xs={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Grid container px={3} py={2} spacing={2} >
                                    <Grid xs={12} sx={{
                                        margin: "äuto",
                                    }}>
                                        <Typography variant="h5" >Classes & Packages Enrolled</Typography>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Grid container spacing={1} >
                                    {profile.student_package && profile.student_package.length > 0 ?
                                        profile.student_package.map((pkg, idx) => (
                                            <Grid xs={12} md={3} sx={{
                                                padding: 1,
                                                margin: "äuto",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <Box sx={{
                                                    border: "1px solid #aaa",
                                                    width: "100%",
                                                    padding: 2,
                                                    borderRadius: 5
                                                }}>
                                                    <Typography variant="h5" sx={{ color: hexy(), textAlign: "center", marginBottom: 1 }} >{pkg.package.name}</Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Package Type</Typography>
                                                        <Typography variant="span">{pkg.package.pkg_type ? pkg.package.pkg_type : "NA"}</Typography>
                                                    </Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Class</Typography>
                                                        <Typography variant="span">{pkg.package.classes.name ? pkg.package.classes.name : "NA"}</Typography>
                                                    </Typography>


                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Timing From</Typography>
                                                        <Typography sx={{ color: "forestgreen", fontWeight: 600 }} variant="span">{pkg.package.start_time ? dayjs(new Date(null, null, null, pkg.package.start_time.split(":")[0], pkg.package.start_time.split(":")[1], pkg.package.start_time.split(":")[2])).format("hh:mm:ss A") : "NA"}</Typography>
                                                    </Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Timing To</Typography>
                                                        <Typography sx={{ color: "red", fontWeight: 600 }} variant="span">{pkg.package.end_time ? dayjs(new Date(null, null, null, pkg.package.end_time.split(":")[0], pkg.package.end_time.split(":")[1], pkg.package.end_time.split(":")[2])).format("hh:mm:ss A") : "NA"}</Typography>
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        ))
                                        : "Nothing to show here"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>



                    <Box mb={2} sx={{
                        background: theme => theme.palette.common.white,
                        p: 2,
                        borderRadius: 4,

                    }}>
                        <Grid container spacing={2} sx={{
                        }}>
                            <Grid item xs={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Grid container px={3} py={2} spacing={2} >
                                    <Grid xs={12} sx={{
                                        margin: "äuto",
                                    }}>
                                        <Typography variant="h5" >Meals</Typography>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Grid container spacing={1} >
                                    {profile.student_meal && profile.student_meal.length > 0 ?
                                        profile.student_meal.map((meal, idx) => (
                                            <Grid xs={12} md={3} sx={{
                                                padding: 1,
                                                margin: "äuto",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <Box sx={{
                                                    border: "1px solid #aaa",
                                                    width: "100%",
                                                    padding: 2,
                                                    borderRadius: 5
                                                }}>
                                                    <Typography variant="h5" sx={{ color: hexy(), textAlign: "center", marginBottom: 1 }} >{meal.meals.name}</Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Offered To</Typography>
                                                        <Typography variant="span">{meal.meals.meal_for}</Typography>
                                                    </Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Price</Typography>
                                                        <Typography variant="span">PKR Rs.{meal.meals.price}.00/-</Typography>
                                                    </Typography>
                                                    {/* <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Package Type</Typography>
                                                        <Typography variant="span">{pkg.package.pkg_type ? pkg.package.pkg_type : "NA"}</Typography>
                                                    </Typography>

                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Class</Typography>
                                                        <Typography variant="span">{pkg.package.classes.name ? pkg.package.classes.name : "NA"}</Typography>
                                                    </Typography>

                                                  
                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Timing From</Typography>
                                                        <Typography sx={{color: "forestgreen", fontWeight:600}} variant="span">{pkg.package.start_time ? dayjs(new Date(null, null, null, pkg.package.start_time.split(":")[0], pkg.package.start_time.split(":")[1], pkg.package.start_time.split(":")[2])).format("hh:mm:ss A") : "NA"}</Typography>
                                                    </Typography>
                                                  
                                                    <Typography variant="p" sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }} >
                                                        <Typography variant="span" sx={{ marginRight: 2 }} >Timing To</Typography>
                                                        <Typography sx={{color: "red", fontWeight:600}} variant="span">{pkg.package.end_time ? dayjs(new Date(null, null, null, pkg.package.end_time.split(":")[0], pkg.package.end_time.split(":")[1], pkg.package.end_time.split(":")[2])).format("hh:mm:ss A") : "NA"}</Typography>
                                                    </Typography> */}

                                                </Box>
                                            </Grid>
                                        ))
                                        : "Nothing to show here"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </> : "Loading"}
        </Container>
    )
}

export default StudentProfile