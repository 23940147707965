import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllUsersApi } from '../../api'

export const usersRequested= createAsyncThunk("usersSlice/usersRequested", async() => {
    try {
        const resposne = await getAllUsersApi()
        return resposne
    } catch (err) {
        throw err
    }
})

const usersSlice = createSlice({
    name: "usersSlice",
    initialState: {
        usersList: [],
        userId: null
    },
    reducers: {
        handleChangeUsersId: (state, action) => {
            state.userId = action.payload
        },
        handleResetUsersSlice: (state) => {
            state.usersList = []
            state.userId = null
        }
    },
    extraReducers: builder => {
        builder.addCase(usersRequested.fulfilled, (state, action) => {
            state.usersList = action.payload.data.users
        })
    }
})

export const {
    handleChangeUsersId,
    handleResetUsersSlice
} = usersSlice.actions

export const getUsersList = state => state.users.usersList

export default usersSlice.reducer
