import React from 'react'
import Dialog from '../components/Modal'
import { Grid, Typography } from '@mui/material'
import Icons from './icons'
import AnimatedCheckMark from '../components/AnimatedCheckMark'

const DeleteModel = ({ open, handleClose, handleDelete }) => {
    const [innerText, setInnerText] = React.useState(false)
    const [innerModelOpen, setInnerModelOpen] = React.useState(false)

    React.useEffect(() => {
        let timer = setTimeout(() => {
            setInnerText(true)
        }, 800);

        return () => {
            clearTimeout(timer)
        }
    }, [])
    return (
        <>
            <Dialog title={"Delete Record"} dailogOpen={open} size="sm" clickAwayListener={false} hasCloseIcon={true}
                handleClose={handleClose}
                actionsButtonArray={[
                    {
                        label: "Cancel",
                        color: "error",
                        variant: "contained",
                        action: () => handleClose(),
                        size: "small"
                    },
                    {
                        label: "Yes Delete It",
                        color: "primary",
                        variant: "contained",
                        action: async () => {
                            setInnerModelOpen(!innerModelOpen)
                            await handleDelete()
                        },
                        size: "small"
                    }
                ]}
            >
                <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Icons.DeleteForever color="error" sx={{ width: 100, height: 100 }} />
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Typography color="error" sx={{ fontWeight: 700 }} variant='h4' component={"h4"} >Are you sure?</Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Typography sx={{ textAlign: "center" }} >Do you really want to delete this record?<br />This process can not be undone.</Typography>
                    </Grid>
                </Grid>

                <Dialog title={"Deleting...."} dailogOpen={innerModelOpen} size="sm" clickAwayListener={false} hasCloseIcon={true}
                    actionsButtonArray={[
                        {
                            label: "Close",
                            color: "error",
                            variant: "contained",
                            action: () => {
                                setInnerModelOpen(!innerModelOpen)
                                handleClose()
                            },
                            size: "small"
                        },
                    ]}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            {innerModelOpen && <AnimatedCheckMark />}
                        </Grid>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            <Typography sx={{
                                fontSize: 24
                            }}>{innerText ? "Record Successfully Deleted" : "Processing..."}</Typography>
                        </Grid>
                    </Grid>
                </Dialog>
            </Dialog>
        </>
    )
}

export default DeleteModel