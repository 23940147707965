import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DESIGNATIONS_REQUESTED, DESIGNATIONS_SLICE_NAME } from "./constant";
import { getAllDesignationApi } from "../../api";

export const designationRequested = createAsyncThunk(DESIGNATIONS_REQUESTED, async () => {
    try {
        const response = await getAllDesignationApi()
        return response
    } catch (err) {
        throw err
    }
})

const designationsSlice = createSlice({
    name: DESIGNATIONS_SLICE_NAME,
    initialState: {
        designation: {
            id: null,
            name: "",
            skills: []
        },
        designationList: [],
        designationModelOpen: false,
        currentPage: 0,
        rowsPerPage: 10
    },
    reducers: {
        handleChangeDesignationModelOpen: (state, action) => { state.designationModelOpen = action.payload },
        handleChangeDesignationCurrentPage: (state, action) => { state.currentPage = action.payload },
        handleChangeDesignationRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeDesignationId: (state, action) => { state.designation.id = action.payload },
        handleChangeDesignationName: (state, action) => { state.designation.name = action.payload },
        handleChangeDesignationSkill: (state, action) => {
            if (action.payload.op === "add") {
                state.designation.skills = [...state.designation.skills, { skill: action.payload.value }]
            } else if (action.payload.op === "del") {
                state.designation.skills.splice(action.payload.index, 1)
            } else {
                state.designation.skills = action.payload
            }
        },
        handleResetDesignation: (state) => {
            state.designation.id = null
            state.designation.name = ""
            state.designation.skills = []
            state.currentPage = 0
            state.rowsPerPage = 10
            state.designationModelOpen = false
        },
        handleResetDesignationSlice: (state) => {
            state.designation.id = null
            state.designation.name = ""
            state.designation.skills = []
            state.designationList = []
            state.currentPage = 0
            state.rowsPerPage = 10
            state.designationModelOpen = false
        }
    },
    extraReducers: builder => {
        builder.addCase(designationRequested.fulfilled, (state, action) => {
            state.designationList = action.payload.data.designations.map(d => ({ ...d, erpId: `TSN-DESID-${d.id}` }))
        })
    }
})

export const {
    handleChangeDesignationId,
    handleChangeDesignationName,
    handleChangeDesignationSkill,
    handleResetDesignation,
    handleResetDesignationSlice,
    handleChangeDesignationModelOpen,
    handleChangeDesignationCurrentPage,
    handleChangeDesignationRowsPerPage
} = designationsSlice.actions

export const getDesignation = state => state.designations.designation
export const getDesignationList = state => state.designations.designationList
export const getDesignationModelOpen = state => state.designations.designationModelOpen
export const getDesignationCurrentPage = state => state.designations.currentPage
export const getDesignationRowsPerPage = state => state.designations.rowsPerPage

export default designationsSlice.reducer