import React from 'react'
import Editor from 'react-simple-wysiwyg';


const RichTextEditor = ({value, onChange, label}) => {

  
    return (
        <>
        <label>{label}</label>
            <Editor
            value={value}
            onChange={onChange}
            />
        </>
    )
}

export default RichTextEditor