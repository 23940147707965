import { Autocomplete, Box, ButtonGroup, Container, Fab, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CustomizedStepper from '../../components/Stepper'
import Icons from '../../common/icons'
import { postAuthorizationApi, postStudentBasicInfo, putAuthorizationApi } from '../../api'
import CustomDatePicker from '../../components/DatePicker'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { createStudentPreloadsRequested, getAccountEmail, getAccountFullName, getAccountId, getActiveStep, getBranchesList, getClassesList, getEthnicitiesList, getLanguagesList, getMealsList, getPackagesList, getReligionsList, getSessionList, getStdBranchId, getStdClassId, getStdDateOfBirth, getStdEthnicitiesId, getStdFullName, getStdLanguageId, getStdMealId, getStdPackageId, getStdReligionId, getStdSessionId, getSteps, handleChangeCreateStudentStdBranchId, handleChangeCreateStudentStdEthnicitiesId, handleChangeCreateStudentStdLanguageId, handleChangeCreateStudentStdReligionId, handleChangeCreateStudentStdSessionId, handleChangeCreateStudnetAccountEmail, handleChangeCreateStudnetAccountFullName, handleChangeCreateStudnetAccountId, handleChangeCreateStudnetActiveStep, handleChangeCreateStudnetStdClassId, handleChangeCreateStudnetStdDateOfBirth, handleChangeCreateStudnetStdFullName, handleChangeCreateStudnetStdMealId, handleChangeCreateStudnetStdPackageId } from './createStudentSlice'
import { useNavigate } from 'react-router-dom'


const CreateAccount = () => {
    const dispatch = useDispatch()
    const accountFullName = useSelector(getAccountFullName)
    const accountEmail = useSelector(getAccountEmail)

    return (
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth size='small' label="Full Name" value={accountFullName} onChange={e => dispatch(handleChangeCreateStudnetAccountFullName(e.target.value))} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth size='small' label="Email Address" value={accountEmail} onChange={e => dispatch(handleChangeCreateStudnetAccountEmail(e.target.value))} />
                </Grid>
            </Grid>
        </Container>
    )
}


const CreateStudentProfile = () => {

    const dispatch = useDispatch()
    const StdFullName = useSelector(getStdFullName)
    const StdDateOfBirth = useSelector(getStdDateOfBirth)
    const StdClassId = useSelector(getStdClassId)
    const StdPackageId = useSelector(getStdPackageId)
    const StdMealId = useSelector(getStdMealId)
    const accountId = useSelector(getAccountId)
    const classesList = useSelector(getClassesList)
    const packagesList = useSelector(getPackagesList)
    const mealsList = useSelector(getMealsList)

    const ethnicitiesList = useSelector(getEthnicitiesList)
    const languagesList = useSelector(getLanguagesList)
    const branchesList = useSelector(getBranchesList)
    const religionsList = useSelector(getReligionsList)
    const sessionList = useSelector(getSessionList)
    const stdEthnicitiesId = useSelector(getStdEthnicitiesId)
    const stdLanguageId = useSelector(getStdLanguageId)
    const stdBranchId = useSelector(getStdBranchId)
    const stdReligionId = useSelector(getStdReligionId)
    const stdSessionId = useSelector(getStdSessionId)

    const getPreReqs = async () => {
        try {
            dispatch(createStudentPreloadsRequested()).unwrap()
        } catch (err) {
            console.log({ err })
        }
    }

    console.log({ packagesList })

    React.useEffect(() => {
        // if (accountId) {
        getPreReqs()
        // }
    }, [accountId])
    return (
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth size='small' label="Full Name" value={StdFullName} onChange={e => dispatch(handleChangeCreateStudnetStdFullName(e.target.value))} />
                </Grid>
                <Grid item xs={12}>
                    <CustomDatePicker fullWidth size='small' label="Date Of Birth" value={dayjs(StdDateOfBirth)} onChange={e => dispatch(handleChangeCreateStudnetStdDateOfBirth(dayjs(e)))} />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={classesList.length > 0 ? classesList : []}
                        getOptionLabel={option => option.name}
                        fullWidth
                        value={StdClassId}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudnetStdClassId(val))
                        }}
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Class" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={StdPackageId}
                        options={packagesList.length > 0 ? packagesList : []}
                        getOptionLabel={option => `${option.name} (${option.pkg_type} - ${option.start_time} ${option.end_time} )`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudnetStdPackageId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Package" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={StdMealId}
                        options={mealsList.length > 0 ? mealsList : []}
                        getOptionLabel={option => `${option.name} (PKR ${option.price}.00/-)`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudnetStdMealId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Meal" />}
                    />
                </Grid>
               
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={stdBranchId}
                        options={branchesList.length > 0 ? branchesList : []}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudentStdBranchId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Branch" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={stdEthnicitiesId}
                        options={ethnicitiesList.length > 0 ? ethnicitiesList : []}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudentStdEthnicitiesId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Ethnicity" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={stdReligionId}
                        options={religionsList.length > 0 ? religionsList : []}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudentStdReligionId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Religion" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={stdSessionId}
                        options={sessionList.length > 0 ? sessionList : []}
                        getOptionLabel={option => `${dayjs(option.from).format("YYYY")} - ${dayjs(option.to).format("YYYY")}`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudentStdSessionId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Session" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={stdLanguageId}
                        options={languagesList.length > 0 ? languagesList : []}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, val) => {
                            dispatch(handleChangeCreateStudentStdLanguageId(val))
                        }}
                        fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Language" />}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

const CreateStudent = () => {
    const navigate = useNavigate()
    const activeStep = useSelector(getActiveStep)
    const accountId = useSelector(getAccountId)
    const steps = useSelector(getSteps)

    const dispatch = useDispatch()
    const StdFullName = useSelector(getStdFullName)
    const StdDateOfBirth = useSelector(getStdDateOfBirth)
    const StdClassId = useSelector(getStdClassId)
    const StdPackageId = useSelector(getStdPackageId)
    const StdMealId = useSelector(getStdMealId)

    const religionsList = useSelector(getReligionsList)
    const sessionList = useSelector(getSessionList)
    const stdEthnicitiesId = useSelector(getStdEthnicitiesId)
    const stdLanguageId = useSelector(getStdLanguageId)
    const stdBranchId = useSelector(getStdBranchId)
    const stdReligionId = useSelector(getStdReligionId)
    const stdSessionId = useSelector(getStdSessionId)


    const accountFullName = useSelector(getAccountFullName)
    const accountEmail = useSelector(getAccountEmail)


    return (
        <Container maxWidth="xl" spacing={2}>

            <Box sx={{
                width: "100%"
            }}>
                <CustomizedStepper steps={steps} activeStep={activeStep}>
                    {activeStep === 0 ?
                        <CreateAccount /> :
                        <CreateStudentProfile />
                    }
                </CustomizedStepper>
            </Box>
            <ButtonGroup sx={{
                position: "absolute",
                bottom: 50,
                right: 50
            }}>
                <Tooltip placement="top" title={"Previous Step"}  >
                    <Fab variant="contained" color="primary" sx={{ mr: 2 }}
                        onClick={async () => {
                            dispatch(handleChangeCreateStudnetActiveStep(activeStep - 1))
                        }}
                    >
                        <Icons.ArrowRightAlt sx={{ transform: "scaleX(-1)" }} />
                    </Fab>
                </Tooltip>
                <Tooltip placement="top" title={"Next Step"} >
                    <Fab variant="contained" color="primary"
                        onClick={async () => {
                            switch (activeStep) {
                                case 0:
                                    try {

                                        if(accountId && accountId.userId){
                                           await putAuthorizationApi({
                                                name: accountFullName,
                                                email: accountEmail,
                                                role_id: 4,
                                                id: accountId.userId
                                            })
                                        }else{
                                            const response = await postAuthorizationApi({
                                                name: accountFullName,
                                                email: accountEmail,
                                                role_id: 4
                                            })
                                            dispatch(handleChangeCreateStudnetAccountId(response.data.user))
                                        }
                                        dispatch(handleChangeCreateStudnetActiveStep(activeStep + 1))
                                    } catch (err) {
                                        console.log({ err })
                                    }
                                    break;
                                case 1:
                                    try {
                                        await postStudentBasicInfo({
                                            full_name: StdFullName,
                                            date_of_birth: dayjs(StdDateOfBirth).format("YYYY-MM-DD"),
                                            class_id: StdClassId.id,
                                            package_id: StdPackageId.id,
                                            ethnicity_id : stdEthnicitiesId.id,
                                            religion_id: stdReligionId.id,
                                            first_language : stdLanguageId.id,
                                            second_language  : stdLanguageId.id,
                                            session_id : stdSessionId.id,
                                            branch_id : stdBranchId.id,
                                            parent_id : accountId.parentId
                                        })
                                        navigate("/student-corner/students")
                                    } catch (err) {
                                        console.log({ err })
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        <Icons.ArrowRightAlt />
                    </Fab>
                </Tooltip>
            </ButtonGroup>
        </Container>
    )
}

export default CreateStudent