import { Box, Button, Checkbox, Container, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import Icons from '../../common/icons'
import { useDispatch, useSelector } from 'react-redux'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import { getAllRolePermissionsApi, postUserPermissionsApi } from '../../api'
import { getRoleId, getRolePermissionList, handleChangeRoleId, handleChangeRolePermissionSelected, handleChangeRolePermissionSelectedAll, rolePermissionByRoleIdRequested, rolePermissionRequested } from '../Roles/roleSlice'
import { useNavigate } from 'react-router-dom'
import useCan from '../../hooks/useCan'

const Permissions = () => {
    const dispatch = useDispatch()
    const [permList, setPermList] = React.useState([])
    const rolesPermissionList = useSelector(getRolePermissionList)
    const roleId = useSelector(getRoleId)
    const navigate = useNavigate()


    const add = useCan("can_add_permissions")
    const edit = useCan("can_edit_permissions")
    const del = useCan("can_delete_permissions")

    const loadAllPermissions = async () => {
        try {
            await dispatch(rolePermissionRequested()).unwrap()
        } catch (err) {

        }
    }

    const loadAllPermissionsById = async () => {
        try {
            await dispatch(rolePermissionByRoleIdRequested({ role_id: roleId })).unwrap()
        } catch (err) {

        }
    }

    React.useEffect(() => {
        loadAllPermissions()

        return () => {
            dispatch(handleChangeRoleId(null))
        }
    }, [])

    React.useEffect(() => {
        if (roleId) {
            loadAllPermissionsById()
        }

        if (!roleId) {
            navigate("/student-corner/roles-and-permissions")
        }
    }, [roleId])


    return (
        <Container maxWidth="xlg">
            <Box sx={{
                background: theme => theme.palette.common.white,
                p: 2,
                borderRadius: 4
            }}>
                <Grid container gap={2}>
                    <Grid item xs={12} md={12} sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={1} sx={{ width: "100%", mb: 2 }} >
                            <div>
                                <Typography variant='h4'>Permissions</Typography>
                                <Typography variant='body2' component={"em"}>Please check the permissions you want to assign to this role.</Typography>
                            </div>
                            {(add || edit) && <Button
                                onClick={async () => {
                                    let permissions = rolesPermissionList.filter(e => e.isSelected).map(obj => {
                                        // Create a shallow copy of the object using spread syntax
                                        let newObj = { role_id: roleId, permission_id: obj.id };
                                        // Delete isSelected property from the copied object
                                        delete newObj.isSelected;
                                        return newObj; // Return modified object
                                    });


                                    try {
                                        await postUserPermissionsApi({ permissions })
                                        dispatch(handleChangeRoleId(null))
                                        navigate("/student-corner/roles-and-permissions")
                                    } catch (err) {

                                    }
                                }}
                                size="small" variant="contained" sx={{
                                    textTransform: "initial",
                                    px: 2,
                                    fontSize: 12
                                }}>Assign Permissions</Button>}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ExplicitTable size="small" columns={[
                        { name: "" },
                        { name: "Permission" },
                        { name: "Description" },
                    ]}>
                        <StyledTableRow>
                            <StyledTableCell sx={{ width: 50 }}> <Switch color='secondary'
                                checked={rolesPermissionList.filter(e => e.isSelected).length === rolesPermissionList.length}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={e => dispatch(handleChangeRolePermissionSelectedAll(e.target.checked))}
                            />  </StyledTableCell>
                            <StyledTableCell sx={{ width: 350 }}>Grant All</StyledTableCell>
                            <StyledTableCell>{"Grant All Permissions to this role"}</StyledTableCell>
                        </StyledTableRow>
                        <> {rolesPermissionList && rolesPermissionList.length > 0 && rolesPermissionList.map((permission, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell sx={{ width: 50, pl: 5 }}>
                                    <Switch
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checked={permission.isSelected}
                                        color='secondary'
                                        onChange={e => {
                                            dispatch(handleChangeRolePermissionSelected({
                                                index: idx,
                                                value: e.target.checked
                                            }))
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell sx={{ width: 350 }}>{permission.name.split("_").map((x) => x.charAt(0).toUpperCase() + x.slice(1)).join(" ")}</StyledTableCell>
                                <StyledTableCell>{permission.description}</StyledTableCell>
                            </StyledTableRow>
                        ))}</>
                    </ExplicitTable>
                </Grid>
            </Box>
        </Container>
    )
}

export default Permissions