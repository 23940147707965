import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const AppTimePicker = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker  views={['hours', 'minutes', 'seconds']} {...props}
                slotProps={{ textField: { ...props } }}
            />
        </LocalizationProvider>
    )
}

export default AppTimePicker