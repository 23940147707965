/* App.js */
import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const BarChart = () => {
    const options = {
        title: {
            text: ""
        },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints: [
                    { label: "Apple", y: 10 },
                    { label: "Orange", y: 15 },
                    { label: "Banana", y: 25 },
                    { label: "Mango", y: 30 },
                    { label: "Grape", y: 28 }
                ]
            }
        ],
        
    }
    return (
        <div style={{
            maxHeight: 200
        }}>
            <CanvasJSChart options={options} maxHeight="200px"
        /* onRef={ref => this.chart = ref} */
        />
        </div>
    )
}
export default BarChart