
import MuiSelect from '@mui/material/Select'
import MuiFormControl from '@mui/material/FormControl'
import MuiInputLabel from '@mui/material/InputLabel'
import MuiMenuItem from '@mui/material/MenuItem'
import styled from '@emotion/styled'

const FormControl = styled(MuiFormControl)(({ theme }) => ({}))
const Select = styled(MuiSelect)(({ theme }) => ({}))
const InputLabel = styled(MuiInputLabel)(({ theme }) => ({}))
export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({}))

const CustomizeSelect = (props) => {
    return (
        <FormControl fullWidth={props.fullWidth} size={props.size}>
            <InputLabel>{props.label}</InputLabel>
            <Select {...props}>
                {props.children}
            </Select>
        </FormControl>
    )
}

export default CustomizeSelect