// const BASE_URI = `http://app.indepthsol.com/api/1.0`
// export const BASE_URI = `http://localhost:5000/api/1.0`
// export const BASE_URI = `http://localhost:8000/api`
export const BASE_URI = `https://app.kidzrepublik.com.pk/api/public/api`


export const API_URL_AUTH_LOGIN = `${BASE_URI}/auth/login`
export const API_URL_AUTH_SIGN_UP = `${BASE_URI}/auth/register`
export const API_URL_MEALS = `${BASE_URI}/meals`
export const API_URL_MEAL = `${BASE_URI}/meal`

export const API_URL_USER_PERMISSIONS = `${BASE_URI}/user-permissions`
export const API_URL_USER_PERMISSION = `${BASE_URI}/user-permission`

export const API_URL_PACKAGES = `${BASE_URI}/packages`
export const API_URL_PACKAGE = `${BASE_URI}/package`

export const API_URL_CLASSES = `${BASE_URI}/classes`
export const API_URL_CLASS = `${BASE_URI}/class`

export const API_URL_BRANCHES = `${BASE_URI}/branches`
export const API_URL_BRANCH = `${BASE_URI}/branch`

export const API_URL_GROUPS = `${BASE_URI}/group`

export const API_URL_STUDENTS = `${BASE_URI}/students`
export const API_URL_STUDENT = `${BASE_URI}/student`
export const API_URL_STUDENT_ASSIGN = `${BASE_URI}/student/assign`
export const API_URL_STUDENT_PACKAGE_CLASS_STUDENT = `${BASE_URI}/student_packages/class/students`
export const API_URL_STUDENT_PACKAGE_DETAILS = `${BASE_URI}/student/package/details`

export const API_URL_LANGUAGES = `${BASE_URI}/languages`
export const API_URL_LANGUAGE = `${BASE_URI}/language`

export const API_URL_ROLES = `${BASE_URI}/roles`
export const API_URL_ROLE = `${BASE_URI}/role`

export const API_URL_ROLE_PERMISSIONS = `${BASE_URI}/role-permissions`

export const API_URL_RELIGIONS = `${BASE_URI}/religions`
export const API_URL_SESSIONS = `${BASE_URI}/sessions`


export const API_URL_ETHNICITIES = `${BASE_URI}/ethnicities`
export const API_URL_ETHNICITY = `${BASE_URI}/ethnicity`

export const API_URL_VOUCHERS = `${BASE_URI}/vouchers`
export const API_URL_VOUCHER = `${BASE_URI}/voucher`

export const API_URL_VOUCHER_DETAILS = `${BASE_URI}/voucher-details`
export const API_URL_VOUCHER_DETAIL = `${BASE_URI}/voucher/details`

export const API_URL_USERS = `${BASE_URI}/users`
export const API_URL_USER_LOGOUT = `${BASE_URI}/user/logout`
export const API_URL_USER_CHANGE_PASSWORD = `${BASE_URI}/user/chpw`

export const API_URL_DESIGNATION = `${BASE_URI}/designation`
export const API_URL_DESIGNATIONS = `${BASE_URI}/designations`
export const API_URL_TITLES = `${BASE_URI}/titles`
export const API_URL_TITLE = `${BASE_URI}/title`
export const API_URL_STAFF = `${BASE_URI}/staff`
export const API_URL_STAFFS = `${BASE_URI}/staffs`