import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ETHNICITIES_REQUESTED, ETHNICITIES_SLICE_NAME } from "./constants";
import { getEthnicitiesApi, getethnicitiesApi } from "../../api";


export const ethnicitiesRequested = createAsyncThunk(ETHNICITIES_REQUESTED, async () => {
    try {
        const response = await getEthnicitiesApi()
        return response
    } catch (err) {
        throw err
    }
})


const ethnicitiesSlice = createSlice({
    name: ETHNICITIES_SLICE_NAME,
    initialState: {
        ethnicityId: null,
        ethnicityModelOpen: false,
        ethnicityName: "",
        ethnicitiesList: []
    },
    reducers: {
        handleChangeethnicityId: (state, action) => {
            state.ethnicityId = action.payload
        },
        handleChangeethnicityModelOpen: (state, action) => {
            state.ethnicityModelOpen = action.payload
        },
        handleChangeethnicityName: (state, action) => {
            state.ethnicityName = action.payload
        },
        handleResetModel: (state) => {
            state.ethnicityId = null
            state.ethnicityName = ""
            state.ethnicityModelOpen = false
        }
    },
    extraReducers: builder => { 
        builder.addCase(ethnicitiesRequested.fulfilled, (state, action) => {
            state.ethnicitiesList = action.payload.data.Ethnicities
        })
    }
})


export const {
    handleChangeethnicityId,
    handleChangeethnicityModelOpen,
    handleChangeethnicityName,
    handleResetModel
} = ethnicitiesSlice.actions

export const getethnicityName = state => state.ethnicities.ethnicityName
export const getethnicityModelOpen = state => state.ethnicities.ethnicityModelOpen
export const getethnicityId = state => state.ethnicities.ethnicityId
export const getethnicityList = state => state.ethnicities.ethnicitiesList

export default ethnicitiesSlice.reducer