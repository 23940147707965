import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Fab, Grid, IconButton, TextField, Tooltip, Typography, Box, Stack, useTheme } from '@mui/material'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import Icons from '../../common/icons'
import Dialog from '../../components/Modal'
import { ethnicitiesRequested, getethnicityId, getethnicityList, getethnicityModelOpen, getethnicityName, handleChangeethnicityId, handleChangeethnicityModelOpen, handleChangeethnicityName, handleResetModel } from './ethnicitiesSlice'
import { deleteEthnicityApi, postEthnicityApi, updateEthnicityApi } from '../../api'
import { ETHNICITIES_TABLE_HEADS } from './constants'
import useCan from '../../hooks/useCan'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'

const Ethnicity = () => {

  const [search, setSearch] = React.useState("")

  const ethnicitiesList = useSelector(getethnicityList)
  const ethnicityId = useSelector(getethnicityId)
  const ethnicityName = useSelector(getethnicityName)
  const ethnicityModelOpen = useSelector(getethnicityModelOpen)
  const dispatch = useDispatch()


  const add = useCan("can_add_ethnicities")
  const edit = useCan("can_edit_ethnicities")
  const del = useCan("can_delete_ethnicities")

  const loadEthnicities = async() => {
    try {
      dispatch(handleShowLoader())
      await dispatch(ethnicitiesRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)

    }
  }
  React.useEffect(() => {
   loadEthnicities()
  }, [])

  const ACTION_BUTTON_ARRAY = [
    {
      permission: edit,
      label: "Edit",
      icon: Icons.Create,
      color: "primary",
      action: (_package) => {
        dispatch(handleShowLoader())
        dispatch(handleChangeethnicityModelOpen(true))
        dispatch(handleChangeethnicityName(_package.name))
        dispatch(handleChangeethnicityId(_package.id))
        dispatch(handleRemoveLoader())
      }
    },
    {
      permission: del,
      label: "Delete",
      icon: Icons.DeleteForever,
      color: "error",
      action: async (_package) => {
        try {
          dispatch(handleShowLoader())
          await deleteEthnicityApi({ id: _package.id })
          await dispatch(ethnicitiesRequested()).unwrap()
          dispatch(handleRemoveLoader())
          openSuccessToast("Success")
        } catch (err) {
          dispatch(handleRemoveLoader())
          openErrorToast(err.message ? err.message : err)
    
        }
      }
    },
  ]

  const theme = useTheme()

  return (
    <Container maxWidth="xl" spacing={2}>
      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container gap={2}>
          <Grid item xs={12} sx={{
             display: "flex",
             alignItems: "start",
             flexDirection: "column",
             [theme.breakpoints.up("md")]: {
               flexDirection: "row",
               alignItems: "center",
               justifyContent: "space-between",
             },
          }}>
            <Typography  sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4'>Ethnicities</Typography>
            <TextField sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%"
              },
            }} size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
              startAdornment: <Icons.Search />
            }} />
          </Grid>
          <Grid item xs={12}>
            <ExplicitTable
              columns={ETHNICITIES_TABLE_HEADS}
              size="small"
            >
              {ethnicitiesList.length > 0 ?
                ethnicitiesList.map(_ethnicity => (
                  <StyledTableRow key={_ethnicity.id}>
                    <StyledTableCell>{_ethnicity.name}</StyledTableCell>
                    <StyledTableCell>{_ethnicity.name}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      {ACTION_BUTTON_ARRAY.filter(r => r.permission).map(btn => (
                        <IconButton size='small' key={btn.label} onClick={() => btn.action(_ethnicity)} >
                          <btn.icon color={btn.color} />
                        </IconButton>
                      ))
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))
                : <StyledTableRow>
                  <StyledTableCell colSpan={ETHNICITIES_TABLE_HEADS.length} >Nothing Found Here</StyledTableCell>
                </StyledTableRow>}
            </ExplicitTable>
          </Grid>
        </Grid>
      </Box>


      {add && <Tooltip placement='left' title="Add New Meal">
        <Fab color='primary' onClick={e => dispatch(handleChangeethnicityModelOpen(true))} sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}

      <Dialog
        dailogOpen={ethnicityModelOpen}
        title={"Add Class"}
        hasCloseIcon={true}
        size="xs"
        clickAwayListener={true}
        handleClose={() => { dispatch(handleResetModel()) }}
        actionsButtonArray={[
          {
            label: "Cancel",
            color: "error",
            variant: "contained",
            action: () => { dispatch(handleResetModel()) },
            size: "small"
          },
          {
            label: "Save",
            color: "primary",
            variant: "contained",
            action: async (e) => {
              try {
                dispatch(handleShowLoader())
                ethnicityId === null ? await postEthnicityApi({ name: ethnicityName }) : await updateEthnicityApi({ id: ethnicityId, name: ethnicityName })
                dispatch(ethnicitiesRequested()).unwrap()
                dispatch(handleResetModel())
                dispatch(handleRemoveLoader())
                openSuccessToast("Success")
              } catch (err) {
                dispatch(handleRemoveLoader())
                openErrorToast(err.message ? err.message : err)
          
              }
            },
            size: "small"
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Name" value={ethnicityName} onChange={e => dispatch(handleChangeethnicityName(e.target.value))} />
          </Grid>
        </Grid>
      </Dialog>
    </Container>
  )
}

export default Ethnicity