import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PACKAGES_REQUESTED, PACKAGE_CLASSES_REQUESTED, PACKAGE_SLICE_NAME } from "./constants";
import { getAllClassesApi, getAllPackagesApi } from "../../api";


export const packagesRequested = createAsyncThunk(PACKAGES_REQUESTED, async () => {
    try {
        const response = await getAllPackagesApi()
        return response
    } catch (err) {
        throw err
    }
})

export const packageClassesRequested = createAsyncThunk(PACKAGE_CLASSES_REQUESTED, async () => {
    try {
        const response = await getAllClassesApi()
        return response
    } catch (err) {
        throw err
    }
})

const packageSlice = createSlice({
    name: PACKAGE_SLICE_NAME,
    initialState: {
        packageModelOpen: false,
        deleteModelOpen: false,
        name: "",
        typeOfMeal: "",
        amount: "",
        packageId: null,
        packages: [],
        classesList: [],
        classId: null,
        startTime: new Date(),
        endTime: new Date(),
    },
    reducers: {
        handleChangePackageClassId: (state, action) => { state.classId = action.payload },
        handleChangePackageStartTime: (state, action) => { state.startTime = action.payload },
        handleChangePackageEndTime: (state, action) => { state.endTime = action.payload },

        handleChangePackageModelOpen: (state, action) => { state.packageModelOpen = action.payload },
        handleChangeDeleteModelOpen: (state, action) => { state.deleteModelOpen = action.payload },
        handleChangeName: (state, action) => { state.name = action.payload },
        handleChangeTypeOfMeal: (state, action) => { state.typeOfMeal = action.payload },
        handleChangeAmount: (state, action) => { state.amount = action.payload },
        handleChangePackageId: (state, action) => { state.packageId = action.payload },
        handleChangePackages: (state, action) => { state.packages = action.payload },
        handleResetPackageModel: (state) => {
            state.packageModelOpen = false
            state.name = ""
            state.typeOfMeal = ""
            state.amount = ""
            state.packageId = null
            state.classId = null
            state.startTime = new Date()
            state.endTime= new Date()
        },
        handleResetSlice: (state) => {
            state.packageModelOpen = false
            state.name = ""
            state.typeOfMeal = ""
            state.amount = ""
            state.packageId = null
            state.packageModelOpen = false
            state.packages = []
            state.classesList = []
            state.classId = null
            state.startTime = new Date()
            state.endTime= new Date()
        }
    },
    extraReducers: builder => {
        builder.addCase(packagesRequested.fulfilled, (state, action) => {
            state.packages = action.payload.data.packages
        })
        builder.addCase(packageClassesRequested.fulfilled, (state, action) => {
            state.classesList = action.payload.data.classes
        })
    }
})

export const {
    handleChangePackageModelOpen,
    handleChangeDeleteModelOpen,
    handleChangeName,
    handleChangeTypeOfMeal,
    handleChangeAmount,
    handleChangePackageId,
    handleChangePackages,
    handleResetPackageModel,
    handleResetSlice,
    handleChangePackageClassId,
    handleChangePackageStartTime,
    handleChangePackageEndTime
} = packageSlice.actions

export const getPackageModelOpen = state => state.package.packageModelOpen
export const getDeleteModelOpen = state => state.package.deleteModelOpen
export const getName = state => state.package.name
export const getTypeOfMeal = state => state.package.typeOfMeal
export const getAmount = state => state.package.amount
export const getPackageId = state => state.package.packageId
export const getPackages = state => state.package.packages

export const getPackageClassesList = state => state.package.classesList
export const getPackageClassId = state => state.package.classId
export const getPackageStartTime = state => state.package.startTime
export const getPackageEndTime = state => state.package.endTime

export default packageSlice.reducer