
import { Box, Button, Container, Grid, IconButton, Stack, TextField, Typography, useTheme } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { postRoleApi, putRoleApi } from "../../api"
import Icons from "../../common/icons"
import Dialog from "../../components/Modal"
import ExplicitTable, { StyledTableCell, StyledTableRow } from "../../components/Table/ExplicitTable"
import TablePaginationActions from "../../components/TablePaginationActions"
import { TABLE_HEADS } from "./constants"
import { getRoleCurrentPage, getRoleDescription, getRoleId, getRoleList, getRoleModelOpen, getRoleName, getRoleRowsPerPAge, handleChangeRoleCurrentPage, handleChangeRoleDescription, handleChangeRoleId, handleChangeRoleModelOpen, handleChangeRoleName, handleChangeRoleRowsPerPage, handleResetRole, handleResetRoleSlice, rolesRequested } from "./roleSlice"
import useCan from "../../hooks/useCan"
import { handleRemoveLoader, handleShowLoader } from "../../common/commonSlice"
import { openErrorToast, openSuccessToast } from "../../common/toast"

const TitleModel = ({ open, loadAllroles }) => {
    const roleId = useSelector(getRoleId)
    const roleName = useSelector(getRoleName)
    const roleDescription = useSelector(getRoleDescription)
    const dispatch = useDispatch()

    return (
        <Dialog dailogOpen={open} size={"md"} title={roleId ? "Edit Role" : "Add Role"} hasCloseIcon={true} handleClose={() => dispatch(handleResetRole())} clickAwayListener={false}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField fullWidth size="small" autoComplete="off" value={roleName} onChange={e => dispatch(handleChangeRoleName(e.target.value))} label={"Name"} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth size="small" autoComplete="off" value={roleDescription} onChange={e => dispatch(handleChangeRoleDescription(e.target.value))} label="Description" />
                    {/* <RichTextEditor value={roleDescription} onChange={e => dispatch(handleChangeRoleDescription(e.target.value))} label="Description" /> */}
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" onClick={async e => {
                        try {
                            roleId === null ? await postRoleApi({ name: roleName, description: roleDescription }) : await putRoleApi({ id: roleId, name: roleName, description: roleDescription })
                            dispatch(handleResetRole())
                            loadAllroles()
                        } catch (err) {
                            // openErrorToast(err.message ? err.message : err)
                        }
                    }}>Save</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}


const Roles = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const rolesList = useSelector(getRoleList)
    const titleModelOpen = useSelector(getRoleModelOpen)

    const currentPage = useSelector(getRoleCurrentPage)
    const rowsPerPage = useSelector(getRoleRowsPerPAge)

    const [filteredRecords, setFilteredRecords] = React.useState([])
    const [searchId, setSearchId] = React.useState("")
    const [searchName, setSearchName] = React.useState("")

    const loadAllroles = async () => {
        try {
            dispatch(handleShowLoader())
            await dispatch(rolesRequested()).unwrap()
            dispatch(handleRemoveLoader())
            openSuccessToast("Success")
          } catch (err) {
            dispatch(handleRemoveLoader())
            openErrorToast(err.message ? err.message : err)
          }
    }

    React.useEffect(() => {
        if (searchId) {
            setFilteredRecords(filteredRecords.filter(roles => roles.erpId.includes(searchId)))
        }
        else if (searchId && searchId) {
            setFilteredRecords(filteredRecords.filter(roles => roles.erpId.includes(searchId)).filter(roles => roles.name.toLowerCase().includes(searchName.toLowerCase())))
        }
        else {
            setFilteredRecords(rolesList)
            setSearchId("")
            setSearchName("")
        }
    }, [searchId])

    React.useEffect(() => {
        if (searchName) {
            setFilteredRecords(filteredRecords.filter(roles => roles.name.toLowerCase().includes(searchName.toLowerCase())))
        }
        else if (setSearchId && searchId) {
            setFilteredRecords(filteredRecords.filter(roles => roles.name.toLowerCase().includes(searchName.toLowerCase())).filter(roles => roles.erpId.includes(searchId)))
        }
        else {
            setFilteredRecords(rolesList)
            setSearchId("")
            setSearchName("")
        }
    }, [searchName])

    React.useEffect(() => {
        loadAllroles()
        return () => {
            setFilteredRecords([])
            setSearchId("")
            setSearchName("")
        }
    }, [])
    React.useEffect(() => {
        if (rolesList) {
            setFilteredRecords(rolesList)
        }
    }, [rolesList])



    const add = useCan("can_add_roles")
    const edit = useCan("can_edit_roles")
    const del = useCan("can_delete_roles")

    const theme = useTheme()

    return (
        <Container maxWidth="xlg">

            <Box sx={{
                background: theme => theme.palette.common.white,
                p: 2,
                borderRadius: 4,

            }}>
                <Grid container gap={2}>
                    <Grid item xs={12} sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        [theme.breakpoints.up("md")]: {
                            flexDirection: "row"
                        },
                    }}>
                        <Stack sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            mb: 1.5,
                            [theme.breakpoints.up("md")]: {
                                alignItems: "center",
                                justifyContent: "start",
                                width: "fit-content",
                            },
                        }} flexDirection={"row"} gap={1}  >
                            <Typography variant='h4'>Roles</Typography>
                            {add && <Button
                                onClick={() => dispatch(handleChangeRoleModelOpen(true))}
                                size="small" variant="contained" sx={{
                                    textTransform: "initial",
                                    px: 2,
                                    fontSize: 12
                                }}>Add New</Button>}
                        </Stack>
                        <TextField size='small' placeholder='Search' sx={{
                            [theme.breakpoints.down("md")]: {
                                width: "100%"
                            },
                        }}
                            // value={search} onChange={e => setSearch(e.target.value)} 
                            InputProps={{
                                startAdornment: <Icons.Search  />
                            }} />
                    </Grid>


                    <Grid item xs={12}>
                        <ExplicitTable size="small" columns={TABLE_HEADS}>
                            {filteredRecords ?
                                filteredRecords.length > 0 ?
                                    (filteredRecords.length > 0 ? filteredRecords.slice(currentPage * rowsPerPage, (currentPage * rowsPerPage) + rowsPerPage) : filteredRecords).map(d => (
                                        <StyledTableRow key={d.id}>
                                            <StyledTableCell sx={{ width: 200 }}>{d.name}</StyledTableCell>
                                            <StyledTableCell>{d.description}</StyledTableCell>
                                            <StyledTableCell sx={{ textAlign: "right" }}>
                                                <IconButton sx={{ p: 0 }} onClick={e => {
                                                    // dispatch(handleChangeRoleModelOpen(true))
                                                    dispatch(handleChangeRoleId(d.id))
                                                    // dispatch(handleChangeRoleName(d.name))
                                                    navigate("/student-corner/permission")
                                                }} >
                                                    <Icons.Settings sx={{
                                                        color: theme => theme.palette.secondary.main,
                                                        mr: 1
                                                        // fontSize: theme => theme.typography.icon.fontSize
                                                    }} />
                                                </IconButton>

                                                {edit && <IconButton sx={{ p: 0 }} onClick={e => {
                                                    dispatch(handleChangeRoleModelOpen(true))
                                                    dispatch(handleChangeRoleId(d.id))
                                                    dispatch(handleChangeRoleName(d.name))
                                                    dispatch(handleChangeRoleDescription(d.description))
                                                }} >
                                                    <Icons.BorderColor sx={{
                                                        // fontSize: theme => theme.typography.icon.fontSize
                                                    }} />
                                                </IconButton>}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : <StyledTableRow >
                                        <StyledTableCell colSpan={TABLE_HEADS.length}>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                p: 1
                                            }}>
                                                <Typography variant="p" component={"p"}>No Records Found</Typography>
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                : <StyledTableCell colSpan={TABLE_HEADS.length} sx={{
                                    borderBottom: "none !important",
                                }}>
                                </StyledTableCell>}
                        </ExplicitTable>
                    </Grid>
                    <Grid item xs={12}>
                        <TablePaginationActions
                            count={filteredRecords ? filteredRecords.length : []}
                            page={currentPage}
                            rowsPerPage={rowsPerPage}
                            onPageChange={(e, val) => { dispatch(handleChangeRoleCurrentPage(val)) }}
                            onRowsPerPageChange={(e) => dispatch(handleChangeRoleRowsPerPage(e.target.value))}
                        />
                    </Grid>
                </Grid>
            </Box>
            <TitleModel open={titleModelOpen} loadAllroles={loadAllroles} />
        </Container >
    )
}

export default Roles