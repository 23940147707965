import { API_URL_AUTH_LOGIN, API_URL_AUTH_SIGN_UP, API_URL_BRANCH, API_URL_BRANCHES, API_URL_CLASS, API_URL_CLASSES, API_URL_DESIGNATION, API_URL_DESIGNATIONS, API_URL_ETHNICITIES, API_URL_ETHNICITY, API_URL_GROUPS, API_URL_LANGUAGE, API_URL_LANGUAGES, API_URL_MEAL, API_URL_MEALS, API_URL_PACKAGE, API_URL_PACKAGES, API_URL_RELIGIONS, API_URL_ROLE, API_URL_ROLE_PERMISSIONS, API_URL_ROLES, API_URL_SESSIONS, API_URL_STAFF, API_URL_STAFFS, API_URL_STUDENT, API_URL_STUDENT_ASSIGN, API_URL_STUDENT_PACKAGE_CLASS_STUDENT, API_URL_STUDENT_PACKAGE_DETAILS, API_URL_STUDENTS, API_URL_TITLE, API_URL_TITLES, API_URL_USER_CHANGE_PASSWORD, API_URL_USER_LOGOUT, API_URL_USER_PERMISSION, API_URL_USER_PERMISSIONS, API_URL_USERS, API_URL_VOUCHER, API_URL_VOUCHER_DETAIL, API_URL_VOUCHER_DETAILS, API_URL_VOUCHERS } from "./constants"
import { post, get, del, put } from "./utils";

export const getAuthorizationApi = ({ email, password }) => {
    return get(API_URL_AUTH_LOGIN, {}, { email, password })
}

export const postAuthorizationApi = ({ name, email, password, role_id }) => {
    return post(API_URL_AUTH_SIGN_UP, { name, email, password, role_id }, {})
}

export const putAuthorizationApi = ({ name, email, password, role_id, id }) => {
    return put(API_URL_AUTH_SIGN_UP, { name, email, password, role_id }, { id })
}

export const postMealApi = ({ name, meal_for, price }) => {
    return post(API_URL_MEAL, { name, meal_for, price }, {})
}

export const getAllMealsApi = () => {
    return get(`${API_URL_MEALS}`, {}, {})
}

export const updateMealApi = ({ id, name, price }) => {
    return put(API_URL_MEAL, { name, price }, { id })
}

export const deleteMealApi = ({ id }) => {
    return del(API_URL_MEAL, {}, { id })
}

export const getAllPackagesApi = () => {
    return get(`${API_URL_PACKAGES}`, {}, {})
}

export const postPackageApi = ({ name, pkg_type, amount, start_time, end_time, class_id }) => {
    return post(API_URL_PACKAGE, { name, pkg_type, amount, start_time, end_time, class_id }, {})
}

export const updatePackageApi = ({ id, name, pkg_type, amount, start_time, end_time, class_id }) => {
    return put(API_URL_PACKAGE, { name, pkg_type, amount, start_time, end_time, class_id }, { id })
}

export const deletePackageApi = ({ id }) => {
    return del(API_URL_PACKAGE, {}, { id })
}

export const getAllGroupsApi = () => {
    return get(`${API_URL_GROUPS}/all`, {}, {})
}

export const postGroupsApi = ({ name }) => {
    return post(API_URL_GROUPS, { name }, {})
}

export const updateGroupsApi = ({ id, name }) => {
    return put(API_URL_GROUPS, { name }, { id })
}

export const deleteGroupsApi = ({ id }) => {
    return put(API_URL_GROUPS, {}, { id })
}

export const getAllClassesApi = () => {
    return get(`${API_URL_CLASSES}`, {}, {})
}
export const getAllStudentsByClassesApi = ({ classId }) => {
    return get(API_URL_STUDENT_PACKAGE_CLASS_STUDENT, {}, { id: classId })
}
export const getStudentPackage = ({ studentId }) => {
    return get(API_URL_STUDENT_PACKAGE_DETAILS, {}, { id: studentId })
}

export const postClassesApi = ({ name, description }) => {
    return post(API_URL_CLASS, { name, description }, {})
}

export const updateClassesApi = ({ id, name, description }) => {
    return put(API_URL_CLASS, { name, description }, { id })
}

export const deleteClassesApi = ({ id }) => {
    return del(API_URL_CLASS, {}, { id })
}


export const postStudentBasicInfo = ({ parent_id, full_name, date_of_birth, class_id, meal_id, package_id, ethnicity_id, religion_id, first_language, second_language, session_id, branch_id, }) => {
    return post(API_URL_STUDENT, { parent_id, full_name, date_of_birth, class_id, meal_id, package_id, ethnicity_id, religion_id, first_language, second_language, session_id, branch_id, }, {})
}

export const getAlltudentsApi = () => {
    return get(`${API_URL_STUDENTS}`, {}, {})
}

export const getAllVouchersApi = () => {
    return get(`${API_URL_VOUCHERS}`, {}, {})
}

export const postVoucherApi = ({ branch_id, class_id, month, due_date, issue_date, student_id, duration }) => {
    return post(`${API_URL_VOUCHER}`, { branch_id, class_id, month, due_date, issue_date, student_id, duration }, {})
}

export const getAllVoucherDetailsApi = ({ voucherId }) => {
    return get(API_URL_VOUCHER, {}, { id: voucherId })
}

export const postVoucherDetailsApi = ({ type = "Regular", amount, description, voucherId }) => {
    return post(`${API_URL_VOUCHER_DETAILS}`, { type, amount, description, voucherId }, {})
}
export const updateVoucherDetailsApi = ({ type = "Regular", amount, description, voucherId, id }) => {
    return put(`${API_URL_VOUCHER_DETAILS}`, { type, amount, description, voucherId }, { id })
}

export const deleteVoucherDetailsApi = ({ id }) => {
    return del(`${API_URL_VOUCHER_DETAILS}`, {}, { id })
}

export const updateVoucherStatusApi = ({ id, status }) => {
    return put(`${API_URL_VOUCHER}`, { status }, { id })
}

export const getVoucherDetailByIdApi = ({ voucherId }) => {
    return get(`${API_URL_VOUCHER_DETAIL}`, {}, { voucherId })
}

export const getAllUsersApi = () => {
    return get(`${API_URL_USERS}`, {}, {})
}


export const postDesignationApi = ({ name }) => {
    return post(API_URL_DESIGNATION, { name }, {})
}

export const putDesignationApi = ({ name, id }) => {
    return put(API_URL_DESIGNATION, { name }, { id })
}

export const getAllDesignationApi = () => {
    return get(API_URL_DESIGNATIONS, {}, {})
}

export const getTitlesApi = () => {
    return get(API_URL_TITLES, {}, {})
}

export const postTitleApi = ({ name }) => {
    return post(API_URL_TITLE, { name }, {})
}

export const updateTitleApi = ({ name, id }) => {
    return put(API_URL_TITLE, { name }, { id })
}

export const getStaffApi = () => {
    return get(API_URL_STAFFS, {}, {})
}

export const postStaffApi = ({ full_name,
    email,
    date_of_birth,
    gender,
    marital_status,
    cnic,
    address,
    joining_date,
    avatar,
    titleId,
    designationId,
    groupId,
    feedback,
    offer_date,
    resignation_date,
    exit_date,
    contact_number }) => {
    return post(API_URL_STAFF, {
        full_name,
        email,
        date_of_birth,
        gender,
        marital_status,
        cnic,
        address,
        joining_date,
        avatar,
        titleId,
        designationId,
        groupId,
        feedback,
        offer_date,
        resignation_date,
        exit_date,
        contact_number
    }, {})
}

export const updateStaffApi = ({ id, full_name,
    email,
    date_of_birth,
    gender,
    marital_status,
    cnic,
    address,
    joining_date,
    avatar,
    titleId,
    designationId,
    groupId,
    feedback,
    offer_date,
    resignation_date,
    exit_date,
    contact_number }) => {
    return put(API_URL_STAFF, {
        full_name,
        email,
        date_of_birth,
        gender,
        marital_status,
        cnic,
        address,
        joining_date,
        avatar,
        titleId,
        designationId,
        groupId,
        feedback,
        offer_date,
        resignation_date,
        exit_date,
        contact_number
    }, { id })
}


export const getBranchesApi = () => {
    return get(API_URL_BRANCHES, {}, {})
}

export const postBranchApi = ({ name, address }) => {
    return post(API_URL_BRANCH, { name, address }, {})
}

export const updateBranchApi = ({ id, name, address }) => {
    return put(API_URL_BRANCH, { name, address }, { id })
}

export const deleteBranchApi = ({ id }) => {
    return del(API_URL_BRANCH, {}, { id })
}


export const getLanguagesApi = () => {
    return get(API_URL_LANGUAGES, {}, {})
}

export const postLanguageApi = ({ name }) => {
    return post(API_URL_LANGUAGE, { name }, {})
}

export const updateLanguageApi = ({ id, name }) => {
    return put(API_URL_LANGUAGE, { name }, { id })
}

export const deleteLanguageApi = ({ id }) => {
    return del(API_URL_LANGUAGE, {}, { id })
}



export const getEthnicitiesApi = () => {
    return get(API_URL_ETHNICITIES, {}, {})
}

export const postEthnicityApi = ({ name }) => {
    return post(API_URL_ETHNICITY, { name }, {})
}

export const updateEthnicityApi = ({ id, name }) => {
    return put(API_URL_ETHNICITY, { name }, { id })
}

export const deleteEthnicityApi = ({ id }) => {
    return del(API_URL_ETHNICITY, {}, { id })
}

export const getAllRolesApi = () => {
    return get(API_URL_ROLES, {}, {})
}

export const postRoleApi = ({ name, description }) => {
    return post(API_URL_ROLE, { name, description }, {})
}

export const putRoleApi = ({ id, name, description }) => {
    return put(API_URL_ROLE, { name, description }, { id })
}

export const deleteRoleApi = ({ id }) => {
    return del(API_URL_ROLE, {}, { id })
}

export const getAllRolePermissionsApi = () => {
    return get(API_URL_ROLE_PERMISSIONS, {}, {})
}

export const postUserPermissionsApi = ({ permissions }) => {
    return post(API_URL_USER_PERMISSIONS, { permissions }, {})
}

export const getUserPermissionByRoleIdApi = ({ role_id }) => {
    return get(API_URL_USER_PERMISSION, {}, { role_id })
}



export const getAllReligionsApi = () => {
    return get(API_URL_RELIGIONS, {}, {})
}


export const getAllSessionsApi = () => {
    return get(API_URL_SESSIONS, {}, {})
}

export const postStudentAssignApi = ({ student_id, meal_id, class_id, package_id }) => {
    return post(API_URL_STUDENT_ASSIGN, { student_id, meal_id, class_id, package_id }, {})
}

export const getStudentByIdApi = ({ id }) => {
    return get(API_URL_STUDENT, {}, { id })
}

export const getUserLoggedOutApi = () => {
    return get(API_URL_USER_LOGOUT, {}, {})
}

export const updatePasswordApi = ({ id, old_password, new_password }) => {
    return post(API_URL_USER_CHANGE_PASSWORD, { old_password, new_password }, { id })
}
