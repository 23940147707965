import { Box, Container, Fab, Grid, IconButton, Paper, TextField, Tooltip, Typography, Chip, Stack, InputLabel, Select, FormControl, MenuItem } from '@mui/material'
import React from 'react'
import Icons from '../../common/icons'
import { useNavigate } from 'react-router-dom'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import { FINANACE_TABLE_COLUMNS } from './constants'
import Dialog from '../../components/Modal'
import Voucher from '../Voucher'
import { useDispatch, useSelector } from 'react-redux'
import { financeRequested, getFinaneCurrentPage, getFinaneList, getFinaneRowsPerPage, handleChangeFinanceCurrentPage, handleChangeFinanceRowsPerPage } from './financeSlice'
import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { COMMON_STYLES } from '../../common/constant'
import icons from '../../common/icons'
import { handleChangeVoucherId } from '../Voucher/voucherSlice'
import TablePaginationActions from '../../components/TablePaginationActions'
import useCan from '../../hooks/useCan'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'


const TableRow = React.memo(({ item, actionButtonArray }) => {

  const theme = useTheme()
  return (
    <StyledTableRow sx={{ verticalAlign: "top" }}>
      <StyledTableCell sx={{ fontWeight: 700, fontSize: theme.typography.body1.fontSize }}>{item.vstid}</StyledTableCell>
      <StyledTableCell >
        <Typography sx={{ ...COMMON_STYLES.body1 }}  ><b>Registration Id:</b> {item.regId}</Typography>
        <Typography sx={{ ...COMMON_STYLES.body1 }}   ><b>Full Name:</b> {item.student.full_name}</Typography>
        <Typography sx={{ ...COMMON_STYLES.body1 }}  ><b>Billing Month:</b> {dayjs(item.month).format("MMMM - YYYY")}</Typography>
        <Typography sx={{ ...COMMON_STYLES.body1, color: theme => theme.palette.success.dark, fontWeight: 700, mt: 1.5 }}   ><b>Issue Date:</b> {dayjs(item.issue_date).format("MMMM DD, YYYY")}</Typography>
        <Typography sx={{ ...COMMON_STYLES.body1, color: theme => theme.palette.error.dark, fontWeight: 700 }}   ><b>Due Date:</b> {dayjs(item.expiry_date).format("MMMM DD, YYYY")}</Typography>

      </StyledTableCell>
      <StyledTableCell>
         <Stack gap={0.5} flexDirection={"row"} boxSizing={"content-box"}>
          {item.duration?.toLowerCase() === "half" ? <Chip sx={{ ...COMMON_STYLES.chip }} label="Applied Installments" color="error" size='small' />: 
           <Chip sx={{ ...COMMON_STYLES.chip }} label="One Time Payment" color="secondary" size='small' />}
        </Stack>
        <Chip sx={{ ...COMMON_STYLES.chip }} label={item.status} color="primary" size='small' />
      </StyledTableCell>
      <StyledTableCell sx={{ textAlign: "right" }}>
        {actionButtonArray.filter(r => r.permission).map((btn, idx) => (
          <IconButton onClick={() => { btn.action(item) }} key={idx}>
            <btn.icon color={btn.color} sx={{
              width: btn.size,
              height: btn.size
            }} />
          </IconButton>
        ))}
      </StyledTableCell>
    </StyledTableRow>
  )
})

const Finance = () => {
  const [search, setSearch] = React.useState("")
  const [voucherModelOpen, setVoucherModelOpen] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const financesList = useSelector(getFinaneList)
  const rowsPerPage = useSelector(getFinaneRowsPerPage)
  const currentPage = useSelector(getFinaneCurrentPage)


  const [filteredRows, setFilteredRows] = React.useState([])
  const [filteredRecords, setFilteredRecords] = React.useState(0)
  const [columnHeads, setColumnHeads] = React.useState([])
  const [searchCol, setSearchCol] = React.useState("vstid")


  const add = useCan("can_add_vouchers")
  const edit = useCan("can_edit_vouchers")
  const del = useCan("can_delete_vouchers")

  const theme = useTheme()

  const actionButtonArray = [
    // {
    //   label: "Edit",
    //   icon: icons.BorderColor,
    //   size: 25,
    //   color: "primary",
    //   action: (item) => { }
    // },
    {
      permission: true,
      label: "Preview",
      icon: icons.OpenInFull,
      size: 25,
      color: "warning",
      action: (item) => {
        navigate("/voucher-preview", {
          state: {
            voucherId: item.id
          }
        })
      }
    },
    {
      permission: edit,
      label: "Edit",
      icon: icons.Settings,
      size: 25,
      color: "success",
      action: (item) => {
        dispatch(handleChangeVoucherId(item.id))
        navigate("/config-voucher", {
          state: {
            studentId: item.studentId,
            studentName: item.student.full_name,
            billingMonth: item.month,
            is_installment: item.is_installment,
            status: item.status
          }
        })
      }
    }
  ]



  const load = async () => {
    try {
      dispatch(handleShowLoader())
      await dispatch(financeRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)
    }
  }
  React.useEffect(() => {
    load()
  }, [])

  React.useEffect(() => {

    if (financesList.length > 0) {
      setFilteredRecords(financesList.length)
      setFilteredRows(financesList.length)
      setColumnHeads(Object.keys(financesList[0]))
    }else{
      setFilteredRecords([])
      setFilteredRows([])
      setColumnHeads([])
    }
  }, [financesList])

  
  return (
    <Container maxWidth="xl">

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container maxWidth={"xl"} spacing={2}>
          <Grid item xs={12} sx={{
             display: "flex",
             alignItems: "start",
             flexDirection: "column",
             [theme.breakpoints.up("md")]: {
               flexDirection: "row",
               alignItems: "center",
               justifyContent: "space-between",
             },
          }}>
            <Typography  sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }}  variant='h4'>Vouchers</Typography>
            <Stack flexDirection={"row"}>
              <FormControl size='small' sx={{
                minWidth: "fit-content"
              }}>
                <InputLabel>Column</InputLabel>
                <Select value={searchCol} label="Column" onChange={(e) => setSearchCol(e.target.value)} >
                  <MenuItem value={"vstid"}>VSTID</MenuItem>
                  <MenuItem value={"regId"}>Student Id</MenuItem>
                  <MenuItem value={"full_name"}>Full Name</MenuItem>
                  <MenuItem value={"issue_date"}>Issue Date</MenuItem>
                  <MenuItem value={"expiry_date"}>Expiry Date</MenuItem>
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"session"}>Session</MenuItem>
                  <MenuItem value={"status"}>Status</MenuItem>
                </Select>
              </FormControl>
              <TextField size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
                startAdornment: <Icons.Search sx={{ mr: 1 }} />
              }} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {financesList.length > 0 ?
              <ExplicitTable columns={FINANACE_TABLE_COLUMNS} size={"small"} >
                {(
                  financesList.length > 0 ? financesList.slice(currentPage * rowsPerPage, (currentPage * rowsPerPage) + rowsPerPage) : financesList
                ).filter((v) => {
                  if (searchCol) {
                    if (search) {
                      if(searchCol === "full_name"){
                        return v.student[searchCol].toLowerCase().includes(search.toLowerCase())
                      }else{
                        return v[searchCol].toLowerCase().includes(search.toLowerCase())
                      }
                    }else{
                      return v
                    }
                  }
                }).map((finance, idx) => (
                  <TableRow actionButtonArray={actionButtonArray} key={idx} item={finance} />
                ))}
              </ExplicitTable>
              : ""}
          </Grid>

          <Grid item xs={!2} md={12} >
            <TablePaginationActions
              count={financesList.length}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onPageChange={(e, val) => {
                dispatch(handleChangeFinanceCurrentPage(val))
              }}
              onRowsPerPageChange={(e) => { dispatch(handleChangeFinanceRowsPerPage(e.target.value)) }}
            />
          </Grid>

        </Grid>
      </Box>
      {add && <Tooltip placement='top' title="Create New Voucher">
        <Fab color='primary' sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }} onClick={() => {
          setVoucherModelOpen(true)
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}

      <Voucher voucherModelOpen={voucherModelOpen} setVoucherModelOpen={setVoucherModelOpen} loadAllFinances={load} />
    </Container>
  )
}

export default Finance