import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getAllClassesApi, getAllStudentsByClassesApi, getStudentPackage } from "../../api";
import { VOUCHERS_CLASSES_REQUESTED, VOUCHERS_STUDENTS_PACKAGES_REQUESTED, VOUCHERS_STUDENTS_REQUESTED, VOUCHER_SICE_NAME } from "./constants";

export const voucherClassesRequested = createAsyncThunk(VOUCHERS_CLASSES_REQUESTED, async () => {
    try {
        const response = await getAllClassesApi()
        return response
    } catch (err) {
        throw err
    }
})

export const voucherStudentsRequested = createAsyncThunk(VOUCHERS_STUDENTS_REQUESTED, async ({ classId }) => {
    try {
        const response = await getAllStudentsByClassesApi({ classId })
        return response
    } catch (err) {
        throw err
    }
})

export const voucherStudentPackageRequested = createAsyncThunk(VOUCHERS_STUDENTS_PACKAGES_REQUESTED, async ({ studentId }) => {
    try {
        const response = await getStudentPackage({ studentId })
        return response
    } catch (err) {
        throw err
    }
})

const voucherSlice = createSlice({
    name: VOUCHER_SICE_NAME,
    initialState: {
        voucherId: null,
        classId: null,
        studentId: null,
        session: "",
        billingMonth: dayjs(new Date()).format("MM-YYYY"),
        billingInstallments: false,
        issueDate: new Date(),
        expiryDate: new Date(),
        amountPayable: 0,
        voucherDetails: [
            {
                fee_type: "",
                amount: "",
                description: "",
                disabled: false
            }
        ],

        classesList: [],
        studentsList: [],
        studentPackagesList: []
    },
    reducers: {
        handleChangeVoucherId: (state, action) => { state.voucherId = action.payload },
        handleChangeVoucherClassId: (state, action) => { state.classId = action.payload },
        handleChangeVoucherStudentId: (state, action) => { state.studentId = action.payload },
        handleChangeVoucherSession: (state, action) => { state.session = action.payload },
        handleChangeVoucherBillingMonth: (state, action) => { state.billingMonth = action.payload },
        handleChangeVoucherBillingInstallments: (state, action) => {
            state.billingInstallments = action.payload
        },
        handleChangeVoucherIssueDate: (state, action) => {
            state.issueDate = action.payload
        },
        handleChangeVoucherExpiryDate: (state, action) => { state.expiryDate = action.payload },
        handleChangeVoucherVoucherDetails: (state, action) => {
            if (action.payload.op === "add") {
                state.voucherDetails[action.payload.index][action.payload.key] = action.payload.value
            } else if (action.payload.op === "del") {
                state.voucherDetails.splice(action.payload.index, 1)
            } else if (action.payload.op === "newEntry") {
                state.voucherDetails.splice(action.payload.index + 1, 0, {
                    fee_type: "",
                    amount: "",
                    description: "",
                    disabled: false
                })
            }
        },
        handleResetVoucherBasic: (state, action) => {
            state.classId = null
            state.studentId = null
            state.session = ""
            state.billingMonth = new Date()
            state.billingInstallments = false
            state.issueDate = new Date()
            state.expiryDate = new Date()
            state.amountPayable = 0
        }
    },
    extraReducers: builder => {
        builder.addCase(voucherClassesRequested.fulfilled, (state, action) => {
            state.classesList = action.payload.data.classes
        })
        builder.addCase(voucherStudentsRequested.fulfilled, (state, action) => {
            state.studentsList = action.payload.data.students
        })
        builder.addCase(voucherStudentPackageRequested.fulfilled, (state, action) => {
            state.studentPackagesList = action.payload.data.students

            const meals = action.payload.data.student.student_meal
            const pkg = action.payload.data.student.student_package

            state.voucherDetails = [
                {
                    fee_type: "",
                    amount: "",
                    description: "",
                    disabled: false
                }
            ]
            meals.map(meal => {
                state.voucherDetails.push({
                    fee_type: meal.meals.name,
                    amount: meal.meals.price,
                    description: "",
                    disabled: true
                })
            })
            pkg.map(p => {
                state.voucherDetails.push({
                    fee_type: `${p.package.name} - ${p.package.type}`,
                    amount: p.package.amount,
                    description: "",
                    disabled: true
                })
            })
        })
    }
})


export const getVoucherClassId = state => state.vouchers.classId
export const getVoucherStudentId = state => state.vouchers.studentId
export const getVoucherSession = state => state.vouchers.session
export const getVoucherBillingMonth = state => state.vouchers.billingMonth
export const getVoucherBillingInstallments = state => state.vouchers.billingInstallments
export const getVoucherIssueDate = state => state.vouchers.issueDate
export const getVoucherExpiryDate = state => state.vouchers.expiryDate
export const getVoucherVoucherDetails = state => state.vouchers.voucherDetails
export const getVoucherClassesList = state => state.vouchers.classesList
export const getVoucherStudentsList = state => state.vouchers.studentsList
export const getVoucherStudentPackagesList = state => state.vouchers.studentPackagesList
export const getVoucherId = state => state.vouchers.voucherId

export const {
    handleChangeVoucherClassId,
    handleChangeVoucherStudentId,
    handleChangeVoucherSession,
    handleChangeVoucherBillingMonth,
    handleChangeVoucherBillingInstallments,
    handleChangeVoucherIssueDate,
    handleChangeVoucherExpiryDate,
    handleChangeVoucherVoucherDetails,
    handleResetVoucherBasic,
    handleChangeVoucherId
} = voucherSlice.actions

export default voucherSlice.reducer