import icons from "../../common/icons"

export const CREATE_STUDENT_SLICE_NAME = "createStudentSlice"

export const CREATE_STUDENT_PRELOADS_REQUESTED = `${CREATE_STUDENT_SLICE_NAME}/createStudentPreloadsRequested`


export const STEPS = [
    { label: "Account Information", icon: icons.FamilyRestroom, isStepCompleted: false },
    { label: "Personal Information", icon: icons.ChildCare, isStepCompleted: false },
]