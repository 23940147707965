import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EMPLOYEES_REQUESTED, EMPLOYEE_ITEMS_REQUESTED, EMPLOYEE_SLICE_NAME } from "./constant";
import { getStaffApi } from "../../api";

export const employeesRequested = createAsyncThunk(EMPLOYEES_REQUESTED, async () => {
    try {
        const response = await getStaffApi()
        return response
    } catch (err) {
        throw err
    }
})

// export const employeeItemsRequested = createAsyncThunk(EMPLOYEE_ITEMS_REQUESTED, async ({ email }) => {
//     try {
//         const response = await getItemsByEmailApi({ email })
//         return response
//     } catch (err) {
//         throw err
//     }
// })

const employeeSlice = createSlice({
    name: EMPLOYEE_SLICE_NAME,
    initialState: {
        employeesList: [],
        employeeId: null,

        employeeDetails: {},
        issuedItems: [],
        issuedItemsModelOpen: false,
        currentPage: 0,
        rowsPerPage: 10
    },
    reducers: {
        handleChangeEmployeeCurrentPage: (state, action) => { state.currentPage = action.payload },
        handleChangeEmployeeRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeEmployeeId: (state, action) => {
            state.employeeId = action.payload
        },
        handleResetSlice: (state, action) => {
            state.employeeId = null
            state.employeesList = []
            state.employeeDetails = {}
            state.issuedItems = []
            state.issuedItemsModelOpen = false
            state.currentPage = 0
            state.rowsPerPage = 10
        },
        handleChangeIssuedModelOpen: (state, action) => {
            state.issuedItemsModelOpen = action.payload
        },
        handleChangeEmployeeDetails: (state, action) => {
            state.employeeDetails = action.payload
        },
        handleResetModel: (state, action) => {
            state.employeeId = null
            state.employeeDetails = {}
            state.issuedItems = []
            state.issuedItemsModelOpen = false
            state.currentPage = 0
            state.rowsPerPage = 10
        }
    },
    extraReducers: builder => {
        builder.addCase(employeesRequested.fulfilled, (state, action) => {
            state.employeesList = action.payload.data.staffs.map(u => ({ ...u, erpId: `TSN-EMPID-${u.id}` }))
        })
        // builder.addCase(employeeItemsRequested.fulfilled, (state, action) => {
        //     state.issuedItems = action.payload.data.items
        // })
    }
})

export const {
    handleChangeEmployeeId,
    handleResetSlice,
    handleChangeIssuedModelOpen,
    handleChangeEmployeeDetails,
    handleResetModel,
    handleChangeEmployeeCurrentPage,
    handleChangeEmployeeRowsPerPage
} = employeeSlice.actions

export const getEmployeesList = state => state.employees.employeesList
export const getEmployeeId = state => state.employees.employeeId
export const getEmployeeDetails = state => state.employees.employeeDetails
export const getIssuedItems = state => state.employees.issuedItems
export const getIssuedItemsModelOpen = state => state.employees.issuedItemsModelOpen
export const getEmployeesCurrentPage = state => state.employees.currentPage
export const getEmployeesRowsPerPage = state => state.employees.rowsPerPage

export default employeeSlice.reducer