import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllRolePermissionsApi, getAllRolesApi, getUserPermissionByRoleIdApi } from "../../api";
import { ROLE_PERIMISSION_REQUESTED, ROLE_PERIMISSION_REQUESTED_BY_ROLEID, ROLES_REQUESTED } from "./constants";
import { ROLES_SLICE_NAME } from "./constants";

export const rolesRequested = createAsyncThunk(ROLES_REQUESTED, async () => {
    try {
        const response = await getAllRolesApi()
        return response
    } catch (err) {
        throw err
    }
})

export const rolePermissionRequested = createAsyncThunk(ROLE_PERIMISSION_REQUESTED, async () => {
    try {
        const response = await getAllRolePermissionsApi()
        return response
    } catch (err) {
        throw err
    }
})

export const rolePermissionByRoleIdRequested = createAsyncThunk(ROLE_PERIMISSION_REQUESTED_BY_ROLEID, async ({role_id}) => {
    try {
        const response = await getUserPermissionByRoleIdApi({ role_id })
        return response
    } catch (err) {
        throw err
    }
})

const roleSlice = createSlice({
    name: ROLES_SLICE_NAME,
    initialState: {
        role: {
            id: null,
            name: "",
            description: ""
        },
        rolesList: [],
        roleModelOpen: false,
        currentPage: 0,
        rowsPerPage: 10,
        rolesPermissionList: []
    },
    reducers: {
        handleChangeRoleId: (state, action) => { state.role.id = action.payload },
        handleChangeRoleModelOpen: (state, action) => { state.roleModelOpen = action.payload },
        handleChangeRoleCurrentPage: (state, action) => { state.currentPage = action.payload },
        handleChangeRoleRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeRoleName: (state, action) => { state.role.name = action.payload },
        handleChangeRoleDescription: (state, action) => { state.role.description = action.payload },
        handleChangeRolePermissionSelected: (state, action) => {
            state.rolesPermissionList[action.payload.index].isSelected = action.payload.value
        },
        handleChangeRolePermissionSelectedAll: (state, action) => {
            state.rolesPermissionList = state.rolesPermissionList.map(e => ({ ...e, isSelected: action.payload }))
        },
        handleResetRole: (state) => {
            state.role.id = null
            state.role.name = ""
            state.role.description = ""
            state.roleModelOpen = false
            state.currentPage = 0
            state.rowsPerPage = 10
        },
        handleResetRoleSlice: (state) => {
            state.role.id = null
            state.role.name = ""
            state.role.description = ""
            state.rolesList = []
            state.roleModelOpen = false
            state.currentPage = 0
            state.rowsPerPage = 10
        }
    },
    extraReducers: builder => {
        builder.addCase(rolesRequested.fulfilled, (state, action) => {
            state.rolesList = action.payload.data.roles.map(d => ({ ...d, erpId: `TSN-00${d.id}` }))
        })
        builder.addCase(rolePermissionRequested.fulfilled, (state, action) => {
            state.rolesPermissionList = action.payload.data.permissions.map(d => ({ ...d, isSelected: false }))
        })
        builder.addCase(rolePermissionByRoleIdRequested.fulfilled, (state, action) => {
            let assigned = action.payload.data.role_permissions
            let permissions = [...state.rolesPermissionList]
            assigned.map(e => {
                if (permissions.filter(p => p.id === e.permission_id).length > 0) {
                    permissions.find(p => p.id === e.permission_id).isSelected = true
                }
            })
            state.rolesPermissionList = permissions
        })
    }
})

export const {
    handleChangeRoleId,
    handleChangeRoleName,
    handleChangeRoleSkill,
    handleResetRole,
    handleResetRoleSlice,
    handleChangeRoleModelOpen,
    handleChangeRoleCurrentPage,
    handleChangeRoleRowsPerPage,
    handleChangeRoleDescription,
    handleChangeRolePermissionSelected,
    handleChangeRolePermissionSelectedAll
} = roleSlice.actions

export const getRoleId = state => state.role.role.id
export const getRoleName = state => state.role.role.name
export const getRoleDescription = state => state.role.role.description
export const getRoleList = state => state.role.rolesList
export const getRoleModelOpen = state => state.role.roleModelOpen
export const getRoleCurrentPage = state => state.role.currentPage
export const getRoleRowsPerPAge = state => state.role.rowsPerPage
export const getRolePermissionList = state => state.role.rolesPermissionList

export default roleSlice.reducer