import { Checkbox, Container, Fab, FormControlLabel, Grid, IconButton, Chip, Box, Stack, Typography, useTheme, TextField } from '@mui/material'
import React from 'react'
import Icons from '../../common/icons'
import Dialog from '../../components/Modal'
import CustomTextField from '../../components/TextField'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import { USERS_HEADS } from './constants'
import { getUsersList, usersRequested } from './usersSlice'
import { useDispatch, useSelector } from 'react-redux'
import hexy from '../../utilities/hexy'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'

const UsersTableRow = React.memo(({ item }) => {
  return (
    <StyledTableRow>
      <StyledTableCell sx={{ fontWeight: 700 }}>{item.id}</StyledTableCell>
      <StyledTableCell>{item.name}</StyledTableCell>
      <StyledTableCell>{item.email}</StyledTableCell>
      <StyledTableCell sx={{ color: hexy(), textTransform: "uppercase", fontWeight: 700 }}>{item.roles.name}</StyledTableCell>
      <StyledTableCell></StyledTableCell>
    </StyledTableRow>
  )
})

const Users = () => {
  const usersList = useSelector(getUsersList)
  const dispatch = useDispatch()

  const loadAllUsers = async () => {
    try {
      dispatch(handleShowLoader())
      await dispatch(usersRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)

    }
  }
  React.useEffect(() => {
    loadAllUsers()
  }, [])

  const theme = useTheme()

  return (
    <Container maxWidth="xl">

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container gap={2}>
          <Grid item xs={12} sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}>
            <Typography sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4'>Users</Typography>
            {/* <TextField sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%"
              },
            }} size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
              startAdornment: <Icons.Search />
            }} /> */}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container maxWidth={"xl"} >
          <Grid item xs={12}>
            <ExplicitTable size="small" columns={USERS_HEADS} >
              {usersList.length > 0 ?
                usersList.map((u, idx) => (
                  <UsersTableRow item={u} key={idx} />
                ))
                : ""}
            </ExplicitTable>
          </Grid>
        </Grid>
      </Box>


      {/* <Fab color='primary' size='small' sx={{
        position: "absolute",
        bottom: 50,
        right: 50
      }}>
        <Icons.Add />
      </Fab> */}


      <Dialog
        dailogOpen={false}
        title={"User"}
        hasCloseIcon={true}
        size="xs"
        clickAwayListener={true}
        handleClose={() => { }}
        actionsButtonArray={[
          {
            label: "Cancel",
            color: "error",
            variant: "contained",
            action: () => { },
            size: "small"
          },
          {
            label: "Save",
            color: "primary",
            variant: "contained",
            action: async (e) => {
              try {

              } catch (err) {
                throw err
              }
            },
            size: "small"
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={!2} sm={12}>
            <CustomTextField fullWidth size="small" label="Verification Code" InputProps={{
              endAdornment: <IconButton>
                <Icons.Add />
              </IconButton>
            }} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <CustomTextField fullWidth size="small" label="Role" />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <FormControlLabel control={<Checkbox icon={<Icons.Check />} checkedIcon={<Icons.Check />} defaultChecked />} label="Force password reset at first login" />
          </Grid>
        </Grid>
      </Dialog>
    </Container>
  )
}

export default Users