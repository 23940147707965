import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CLASSES_REQUESTED, CLASSES_SLICE_NAME } from "./constants";
import { getAllClassesApi } from "../../api";


export const classesRequested = createAsyncThunk(CLASSES_REQUESTED, async () => {
    try {
        const response = await getAllClassesApi()
        return response
    } catch (err) {
        throw err
    }
})


const classesSlice = createSlice({
    name: CLASSES_SLICE_NAME,
    initialState: {
        classId: null,
        classModelOpen: false,
        className: "",
        classDescription: "",
        classesList: []
    },
    reducers: {
        handleChangeClassId: (state, action) => {
            state.classId = action.payload
        },
        handleChangeClassModelOpen: (state, action) => {
            state.classModelOpen = action.payload
        },
        handleChangeClassName: (state, action) => {
            state.className = action.payload
        },
        handleChangeClassDescription: (state, action) => {
            state.classDescription = action.payload
        },
        handleResetModel: (state) => {
            state.classId = null
            state.className = ""
            state.classModelOpen = false
        }
    },
    extraReducers: builder => { 
        builder.addCase(classesRequested.fulfilled, (state, action) => {
            state.classesList = action.payload.data.classes
        })
    }
})


export const {
    handleChangeClassId,
    handleChangeClassModelOpen,
    handleChangeClassName,
    handleResetModel,
    handleChangeClassDescription
} = classesSlice.actions

export const getClassName = state => state.classes.className
export const getClassModelOpen = state => state.classes.classModelOpen
export const getClassId = state => state.classes.classId
export const getClassList = state => state.classes.classesList
export const getClassDescription = state => state.classes.classDescription

export default classesSlice.reducer