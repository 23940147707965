import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Fab, Grid, IconButton, TextField, Tooltip, Typography, Box, Stack, useTheme, CircularProgress } from '@mui/material'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import { branchesRequested, getbranchAddress, getbranchId, getbranchList, getbranchModelOpen, getbranchName, handleChangebranchAddress, handleChangebranchId, handleChangebranchModelOpen, handleChangebranchName, handleResetModel } from './branchesSlice'
import Icons from '../../common/icons'
import { BRANCHES_TABLE_HEADS } from './constants'
import { deleteBranchApi, postBranchApi, updateBranchApi } from '../../api'
import Dialog from '../../components/Modal'
import useCan from '../../hooks/useCan'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'

const Branches = () => {

  const [search, setSearch] = React.useState("")
  const [showLoading, setShowLoading] = React.useState(false)

  const addBrnach = useCan("can_add_branches")
  const editBrnach = useCan("can_edit_branches")
  const deleternach = useCan("can_delete_branches")

  const branchesList = useSelector(getbranchList)
  const branchId = useSelector(getbranchId)
  const branchName = useSelector(getbranchName)
  const branchAddress = useSelector(getbranchAddress)
  const branchModelOpen = useSelector(getbranchModelOpen)
  const dispatch = useDispatch()

  const loadAllBranches = async () => {
    try {
      dispatch(handleShowLoader())
      await dispatch(branchesRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Loaded Branches")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)
    }
  }

  React.useEffect(() => {
    loadAllBranches()
  }, [])

  const ACTION_BUTTON_ARRAY = [
    {
      permission: editBrnach,
      label: "Edit",
      icon: Icons.Create,
      color: "primary",
      action: (_package) => {
        dispatch(handleShowLoader())
        dispatch(handleChangebranchModelOpen(true))
        dispatch(handleChangebranchId(_package.id))
        dispatch(handleChangebranchName(_package.name))
        dispatch(handleChangebranchAddress(_package.address))
        dispatch(handleRemoveLoader())
      }
    },
    {
      permission: deleternach,
      label: "Delete",
      icon: Icons.DeleteForever,
      color: "error",
      action: async (_package) => {
        try {
          dispatch(handleShowLoader())
          await deleteBranchApi({ id: _package.id })
          dispatch(branchesRequested()).unwrap()
          dispatch(handleRemoveLoader())
          openSuccessToast("Success")
        } catch (err) {
          dispatch(handleRemoveLoader())
          openErrorToast(err.message ? err.message : err)
        }
      }
    },
  ]

  const theme = useTheme()

  return (
    showLoading ? <CircularProgress /> : <Container maxWidth="xl" spacing={2}>
      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container gap={2}>
          <Grid item xs={12} sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}>
            <Typography sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4'>Branches</Typography>
            <TextField size='small' sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%"
              },
            }} placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
              startAdornment: <Icons.Search />
            }} />
          </Grid>
          <Grid item xs={12}>
            <ExplicitTable
              columns={BRANCHES_TABLE_HEADS}
              size="small"
            >
              {branchesList.length > 0 ?
                branchesList.map(_branch => (
                  <StyledTableRow key={_branch.id}>
                    <StyledTableCell>{_branch.name}</StyledTableCell>
                    <StyledTableCell>{_branch.address}</StyledTableCell>
                    <StyledTableCell sx={{
                      textAlign: "right"
                    }}>
                      {ACTION_BUTTON_ARRAY.filter(btn => btn.permission).map(btn => (
                        <IconButton size='small' key={btn.label} onClick={() => btn.action(_branch)} >
                          <btn.icon color={btn.color} />
                        </IconButton>
                      ))
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))
                : <StyledTableRow>
                  <StyledTableCell colSpan={BRANCHES_TABLE_HEADS.length} >Nothing Found Here</StyledTableCell>
                </StyledTableRow>}
            </ExplicitTable>
          </Grid>
        </Grid>
      </Box>


      {addBrnach && <Tooltip placement='left' title="Add New Branch">
        <Fab color='primary' onClick={e => dispatch(handleChangebranchModelOpen(true))} sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}

      <Dialog
        dailogOpen={branchModelOpen}
        title={"Add Branch"}
        hasCloseIcon={true}
        size="xs"
        clickAwayListener={true}
        handleClose={() => { dispatch(handleResetModel()) }}
        actionsButtonArray={[
          {
            label: "Cancel",
            color: "error",
            variant: "contained",
            action: () => { dispatch(handleResetModel()) },
            size: "small"
          },
          {
            label: "Save",
            color: "primary",
            variant: "contained",
            action: async (e) => {
              try {
                dispatch(handleShowLoader())
                branchId === null ? await postBranchApi({ name: branchName, address: branchAddress }) : await updateBranchApi({ id: branchId, name: branchName, address: branchAddress })
                dispatch(branchesRequested()).unwrap()
                dispatch(handleResetModel())
                dispatch(handleRemoveLoader())
                openSuccessToast("Success")
              } catch (err) {
                dispatch(handleRemoveLoader())
                openErrorToast(err.message ? err.message : err)
              }
            },
            size: "small"
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Name" value={branchName} onChange={e => dispatch(handleChangebranchName(e.target.value))} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Branch Address" value={branchAddress} onChange={e => dispatch(handleChangebranchAddress(e.target.value))} />
          </Grid>
        </Grid>
      </Dialog>
    </Container>
  )
}

export default Branches