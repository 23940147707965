import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CREATE_STUDENT_PRELOADS_REQUESTED, CREATE_STUDENT_SLICE_NAME, STEPS } from "./constants";
import { getAllClassesApi, getAllMealsApi, getAllPackagesApi, getAllReligionsApi, getAllSessionsApi, getBranchesApi, getEthnicitiesApi, getLanguagesApi } from "../../api";

export const createStudentPreloadsRequested = createAsyncThunk(CREATE_STUDENT_PRELOADS_REQUESTED, async () => {
    try {
        const classes = await getAllClassesApi();
        const packages = await getAllPackagesApi();
        const meals = await getAllMealsApi();
        const ethnicities = await getEthnicitiesApi();
        const religionsList = await getAllReligionsApi();
        const languagesList = await getLanguagesApi();
        const sessionList = await getAllSessionsApi();
        const branchesList = await getBranchesApi();
        return {
            classes, packages, meals, ethnicities, languagesList, branchesList, religionsList, sessionList
        }
    } catch (err) {
        throw err
    }
})

const createStudentSlice = createSlice({
    name: CREATE_STUDENT_SLICE_NAME,
    initialState: {
        steps: STEPS,
        activeStep: 0,
        accountFullName: "",
        accountEmail: "",
        accountId: null,
        stdFullName: "",
        stdDateOfBirth: new Date(),
        stdClassId: null,
        stdPackageId: null,
        stdMealId: null,
        stdEthnicitiesId: null,
        stdLanguageId: null,
        stdBranchId: null,
        stdReligionId: null,
        stdSessionId: null,

        classesList: [],
        packagesList: [],
        mealsList: [],
        ethnicitiesList: [],
        religionsList: [],
        languagesList: [],
        sessionList: [],
        branchesList: [],
        status: "Pending",
    },
    reducers: {
        handleChangeCreateStudentStdEthnicitiesId: (state, action) => { state.stdEthnicitiesId = action.payload },
        handleChangeCreateStudentStdLanguageId: (state, action) => { state.stdLanguageId = action.payload },
        handleChangeCreateStudentStdBranchId: (state, action) => { state.stdBranchId = action.payload },
        handleChangeCreateStudentStdReligionId: (state, action) => { state.stdReligionId = action.payload },
        handleChangeCreateStudentStdSessionId: (state, action) => { state.stdSessionId = action.payload },
        handleChangeCreateStudnetAccountId: (state, action) => { state.accountId = action.payload },
        handleChangeCreateStudnetActiveStep: (state, action) => { state.activeStep = action.payload },
        handleChangeCreateStudnetAccountFullName: (state, action) => { state.accountFullName = action.payload },
        handleChangeCreateStudnetAccountEmail: (state, action) => { state.accountEmail = action.payload },
        handleChangeCreateStudnetStdFullName: (state, action) => { state.stdFullName = action.payload },
        handleChangeCreateStudnetStdDateOfBirth: (state, action) => { state.stdDateOfBirth = action.payload },
        handleChangeCreateStudnetStdClassId: (state, action) => { state.stdClassId = action.payload },
        handleChangeCreateStudnetStdPackageId: (state, action) => { state.stdPackageId = action.payload },
        handleChangeCreateStudnetStdMealId: (state, action) => { state.stdMealId = action.payload },
    },
    extraReducers: builder => {
        builder.addCase(createStudentPreloadsRequested.fulfilled, (state, action) => {

            state.classesList = action.payload.classes.data.classes
            state.mealsList = action.payload.meals.data.meals
            state.packagesList = action.payload.packages.data.packages
            state.ethnicitiesList = action.payload.ethnicities.data.Ethnicities
            state.languagesList = action.payload.languagesList.data.languages
            state.branchesList = action.payload.branchesList.data.branches
            state.religionsList = action.payload.religionsList.data.Religions
            state.sessionList = action.payload.sessionList.data.session
        })
    }
})

export const getSteps = state => state.createStudent.steps
export const getActiveStep = state => state.createStudent.activeStep
export const getAccountFullName = state => state.createStudent.accountFullName
export const getAccountEmail = state => state.createStudent.accountEmail
export const getStdFullName = state => state.createStudent.stdFullName
export const getStdDateOfBirth = state => state.createStudent.stdDateOfBirth
export const getStdClassId = state => state.createStudent.stdClassId
export const getStdPackageId = state => state.createStudent.stdPackageId
export const getStdMealId = state => state.createStudent.stdMealId
export const getClassesList = state => state.createStudent.classesList
export const getPackagesList = state => state.createStudent.packagesList
export const getMealsList = state => state.createStudent.mealsList
export const getAccountId = state => state.createStudent.accountId
export const getEthnicitiesList = state => state.createStudent.ethnicitiesList
export const getLanguagesList = state => state.createStudent.languagesList
export const getBranchesList = state => state.createStudent.branchesList
export const getReligionsList = state => state.createStudent.religionsList
export const getSessionList = state => state.createStudent.sessionList
export const getStdEthnicitiesId = state => state.createStudent.stdEthnicitiesId
export const getStdLanguageId = state => state.createStudent.stdLanguageId
export const getStdBranchId = state => state.createStudent.stdBranchId
export const getStdReligionId = state => state.createStudent.stdReligionId
export const getStdSessionId = state => state.createStudent.stdSessionId


export const {
    handleChangeCreateStudnetActiveStep,
    handleChangeCreateStudnetAccountFullName,
    handleChangeCreateStudnetAccountEmail,
    handleChangeCreateStudnetStdFullName,
    handleChangeCreateStudnetStdDateOfBirth,
    handleChangeCreateStudnetStdClassId,
    handleChangeCreateStudnetStdPackageId,
    handleChangeCreateStudnetStdMealId,
    handleChangeCreateStudnetAccountId,
    handleChangeCreateStudentStdEthnicitiesId,
    handleChangeCreateStudentStdLanguageId,
    handleChangeCreateStudentStdBranchId,
    handleChangeCreateStudentStdReligionId,
    handleChangeCreateStudentStdSessionId
} = createStudentSlice.actions


export default createStudentSlice.reducer