import { Autocomplete, Grid, TextField } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { postVoucherApi } from "../../api"
import Icons from "../../common/icons"
import CustomDatePicker from "../../components/DatePicker"
import Dialog from "../../components/Modal"
import CustomizeSelect, { MenuItem } from "../../components/Select"
import CustomTextField from "../../components/TextField"
import useCan from "../../hooks/useCan"
import { getVoucherBillingInstallments, getVoucherBillingMonth, getVoucherClassId, getVoucherClassesList, getVoucherExpiryDate, getVoucherIssueDate, getVoucherSession, getVoucherStudentId, getVoucherStudentPackagesList, getVoucherStudentsList, getVoucherVoucherDetails, handleChangeVoucherBillingInstallments, handleChangeVoucherBillingMonth, handleChangeVoucherClassId, handleChangeVoucherExpiryDate, handleChangeVoucherId, handleChangeVoucherIssueDate, handleChangeVoucherSession, handleChangeVoucherStudentId, handleChangeVoucherVoucherDetails, handleResetVoucherBasic, voucherClassesRequested, voucherStudentPackageRequested, voucherStudentsRequested } from "./voucherSlice"

const Voucher = ({ voucherModelOpen, setVoucherModelOpen, loadAllFinances }) => {

    const [additionalFee, setAdditionalFee] = React.useState([])
    const dispatch = useDispatch()
    const classList = useSelector(getVoucherClassesList)
    const studentsList = useSelector(getVoucherStudentsList)

    const classId = useSelector(getVoucherClassId)
    const studentId = useSelector(getVoucherStudentId)
    const session = useSelector(getVoucherSession)
    const month = useSelector(getVoucherBillingMonth)
    const installments = useSelector(getVoucherBillingInstallments)
    const issueDate = useSelector(getVoucherIssueDate)
    const expiryDate = useSelector(getVoucherExpiryDate)
    const voucherDetails = useSelector(getVoucherVoucherDetails)

    const voucherPackageList = useSelector(getVoucherStudentPackagesList)



    const add = useCan("can_add_vouchers")
    const edit = useCan("can_edit_vouchers")
    const del = useCan("can_delete_vouchers")

    React.useEffect(() => {
        dispatch(voucherClassesRequested()).unwrap()
        setAdditionalFee(curr => [...curr, {
            fee_type: "",
            amount: "",
            description: ""
        }])
        // return () => setAdditionalFee([])
    }, [])

    React.useEffect(() => {
        if (classId?.id) {
            dispatch(voucherStudentsRequested({ classId: classId.id })).unwrap()
        }
    }, [classId])

    // React.useEffect(() => {
    //     if (studentId?.id) {
    //         dispatch(voucherStudentPackageRequested({ studentId: studentId.students.id })).unwrap()
    //     }
    // }, [studentId])

    const actionButtonArray = [
        {
            label: "Remove",
            icon: Icons.DeleteForever,
            color: "error",
            action: (index) => {
                dispatch(handleChangeVoucherVoucherDetails({
                    op: "del",
                    index: index,
                }))
            }
        }
    ]
    return (
        <Dialog
            actionsButtonArray={[
                {
                    label: "Save",
                    variant: "contained",
                    size: "small",
                    action: async () => {
                        try {
                            const response = await postVoucherApi({
                                class_id: classId.id,
                                month: dayjs(month).format("YYYY-MM-DD"),
                                due_date: dayjs(expiryDate).format("YYYY-MM-DD"),
                                issue_date: dayjs(issueDate).format("YYYY-MM-DD"),
                                duration: installments,
                                student_id: studentId.students.id,
                                branch_id: studentId.students.branch_id
                            })
                            dispatch(handleChangeVoucherId(response.data.voucher.id))
                            dispatch(handleResetVoucherBasic())
                            loadAllFinances()
                            setVoucherModelOpen(false)
                        } catch (err) {
                            console.log({ err })
                        }
                    }
                }
            ]}
            title={"New Voucher"} handleClose={() => {
                setVoucherModelOpen(false)
            }} hasCloseIcon={true} size={"md"} clickAwayListener={false} dailogOpen={voucherModelOpen}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        disableClearable
                        id="combo-box-demo"
                        options={classList.length > 0 ? classList : []}
                        getOptionLabel={option => option.name}
                        fullWidth
                        value={classId}
                        onChange={(e, val) => {
                            dispatch(handleChangeVoucherClassId(val))
                        }}
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Class" />}
                    />
                </Grid>


                {classId?.id &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                disableClearable
                                id="combo-box-demo"
                                options={studentsList.length > 0 ? studentsList : []}
                                getOptionLabel={option => `${option.students.id} - ${option.students.full_name}`}
                                fullWidth
                                value={studentId}
                                onChange={(e, val) => {
                                    dispatch(handleChangeVoucherStudentId(val))
                                }}
                                size='small'
                                renderInput={(params) => <TextField {...params} label="Student" />}
                            />
                        </Grid>
                        {studentId?.id && <>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField size="small" fullWidth label="Session" value={session} onChange={e => dispatch(handleChangeVoucherSession(e.target.value))} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDatePicker size="small" views={['month', 'year']} fullWidth label="Billing Month" value={dayjs(month)} onChange={e => dispatch(handleChangeVoucherBillingMonth(e))} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomizeSelect fullWidth label="Billing Duration" size="small"
                                    value={installments}
                                    onChange={e => dispatch(handleChangeVoucherBillingInstallments(e.target.value))}
                                >
                                    <MenuItem value={"Half"}>Half Month</MenuItem>
                                    <MenuItem value={"Full"}>Full Month</MenuItem>
                                </CustomizeSelect>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDatePicker size="small" fullWidth label="Issue Date"
                                    value={dayjs(issueDate)}
                                    onChange={e => dispatch(handleChangeVoucherIssueDate(e))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDatePicker size="small" fullWidth label="Due Date"
                                    value={dayjs(expiryDate)}
                                    onChange={e => dispatch(handleChangeVoucherExpiryDate(e))} />
                            </Grid>
                            
                        </>}
                    </>

                }
            </Grid>
        </Dialog>
    )
}

export default Voucher