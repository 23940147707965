import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, ListItemIcon, ListItemText, ListItemButton, useTheme, Collapse, Menu, Fade, MenuItem, Stack } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import Navigation from './navigation';
import { APP_NAVIGATION } from './navigation/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn, getPermissions, getRole, getUser, handleLogout } from '../screen/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import Icons from '../common/icons';
import { getUserLoggedOutApi } from '../api';
import { clear } from '../utilities/storage';
import { getLoading, getLogOutModel, handleRemoveLogoutModel, handleShowLogoutModel } from '../common/commonSlice';
import Loader from '../components/Loader';
import Dialog from '../components/Modal';
import { openErrorToast } from '../common/toast';


const drawerWidth = 240;


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      borderRight: "none",
      background: "#fff",
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);



const NestedRoutes = ({ open, route, navigate, uRole }) => {

  const permissions = useSelector(getPermissions)
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(false)
  return (
    <React.Fragment>
      <ListItemButton sx={{

      }} disableRipple key={route.label} onClick={() => {
        setAnchorEl(anchorEl ? "" : route.label)
      }}>
        <ListItemIcon sx={{
          minWidth: "30px",
        }}>
          <route.icon sx={{
            fontSize: "1.25rem"
          }} />
        </ListItemIcon>
        {open ? <ListItemText primaryTypographyProps={{
          fontSize: '0.8125rem',
          fontWeight: 600
        }}
          primary={
            route.label.includes("-") ?
              route.label.split("-").map((l) => (
                l.charAt(0).toUpperCase() + l.slice(1) + " "
              ))
              : route.label.charAt(0).toUpperCase() + route.label.slice(1)
          }
        ></ListItemText> : ""}
        {open ? <ListItemIcon sx={{
          minWidth: "fit-content",
        }}>
          {!anchorEl ? <Icons.ExpandMore /> : <Icons.ExpandLess />}
        </ListItemIcon> : ""}
      </ListItemButton>
      <Collapse in={anchorEl} timeout="auto" unmountOnExit>
        <List component="nav"
          sx={{
            ml: 2,

            paddingLeft: 1,
            paddingRight: 1,
            '&& .Mui-selected, && .Mui-selected:hover': {
              background: theme.palette.primary.main,
              borderRadius: 50,
              color: "#fff",
              '&, & .MuiListItemIcon-root': {
                color: "#fff",
              },
              '&, & .MuiListItemText-root .MuiListItemText-primary': {
                color: "#fff",
              },
            },
            '& .MuiListItemButton-root:hover': {
              transition: ".3s all ease_in-out",
              bgcolor: theme.palette.primary.main,
              background: theme.palette.primary.main,
              borderRadius: 50,
              '&, & .MuiListItemIcon-root': {
                color: "#fff",
              },
              '&, & .MuiListItemText-root .MuiListItemText-primary': {
                color: "#fff",
              },
            },
            "& .MuiListItemButton-root": {
              transition: ".3s all ease_in-out",
              "&, & .MuiListItemText-root .MuiListItemText-primary": {
                color: theme.palette.customFontColor.main,
                borderRadius: 50,
              },
              "&, & .MuiListItemIcon-root": {
                color: theme.palette.customFontColor.main,
                borderRadius: 50,
              }
            }

          }}>
          {route.childrens.filter(s => s.showInLeftNav && permissions.includes(s.permission)).map(sroute => (
            <ListItemButton
              disableRipple
              // selected={route.url.toLowerCase() === window.location.pathname.toLowerCase()}
              onClick={() => { navigate(`${route.path.toLowerCase()}${sroute.path.toLowerCase()}`); }} key={sroute.label}
              sx={{
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "30px",
                }}>
                <route.icon sx={{
                  fontSize: "1.25rem"
                }} />
              </ListItemIcon>
              {open && <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8125rem',
                  fontWeight: 600
                }}
                primary={
                  sroute.label.includes("-") ?
                    sroute.label.split("-").map(splitted => (
                      splitted.charAt(0).toUpperCase() + splitted.slice(1) + " "
                    ))
                    : sroute.label.charAt(0).toUpperCase() + sroute.label.slice(1)
                } />}
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

const AppLayout = () => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const theme = useTheme()

  const isLoggedIn = useSelector(getIsLoggedIn)

  const permissions = useSelector(getPermissions)
  // const userRole = useSelector(getRole)
  const userRole = "admin"
  const navigate = useNavigate()

  const [menuAnchor, setMenuAnchor] = React.useState(null)
  // const [timer, setTimer] = React.useState(5)
  const [timeLeft, setTimeLeft] = React.useState(15);

  const menuOpen = Boolean(menuAnchor)
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const loader = useSelector(getLoading)
  const isLogout = useSelector(getLogOutModel)

  const logout = async () => {
    dispatch(handleRemoveLogoutModel());
    setTimeLeft(15)
    await getUserLoggedOutApi()
    setMenuAnchor(null)
    dispatch(handleLogout());
  }

  React.useEffect(() => {
    let timer = null;

    if (isLogout) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(timer);
            logout()
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setTimeLeft(15)
    }

    return () => {
      clearInterval(timer)
      setTimeLeft(15)
    }
  }, [isLogout]);

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setOpen(false)
    }
  }, [])



  return (
    <Box sx={{ display: 'flex', backgroundColor: (theme) => isLoggedIn ? theme.palette.mainBackground.contentarea : "transparent" }}>
      <CssBaseline />
      {isLoggedIn &&
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1],
            }}
          >
            {open ?
              //   <img style={{
              //     mixBlendMode: "multiply",
              //     filter:"revert"
              // }} src={process.env.PUBLIC_URL + "/static/logo.png"} />
              "Kidzrepublik"
              : "KRPL"}
          </Toolbar>

          <List component="nav" sx={{
            paddingLeft: 1,
            paddingRight: 1,
            '&& .Mui-selected, && .Mui-selected:hover': {
              background: theme.palette.primary.main,
              borderRadius: 50,
              color: "#fff",
              '&, & .MuiListItemIcon-root': {
                color: "#fff",
              },
              '&, & .MuiListItemText-root .MuiListItemText-primary': {
                color: "#fff",
              },
            },
            '& .MuiListItemButton-root:hover': {
              transition: ".3s all ease_in-out",
              bgcolor: theme.palette.primary.main,
              background: theme.palette.primary.main,
              borderRadius: 50,
              '&, & .MuiListItemIcon-root': {
                color: "#fff",
              },
              '&, & .MuiListItemText-root .MuiListItemText-primary': {
                color: "#fff",
              },
            },
            "& .MuiListItemButton-root": {
              transition: ".3s all ease_in-out",
              "&, & .MuiListItemText-root .MuiListItemText-primary": {
                color: theme.palette.customFontColor.main,
                borderRadius: 50,
              },
              "&, & .MuiListItemIcon-root": {
                color: theme.palette.secondary.main,
                borderRadius: 50,
              }
            }

          }}>
            {APP_NAVIGATION.filter(nav => nav.showInLeftNav && permissions.includes(nav.permission)).map((route, index) => (
              route.childrens && route.childrens.length > 0 ?
                <NestedRoutes open={open} route={route} navigate={navigate} key={route.label} uRole={route.roles} />
                :
                <ListItemButton
                  disableRipple
                  // selected={route.url.toLowerCase() === window.location.pathname.toLowerCase()}
                  onClick={() => { navigate(route.path.toLowerCase()); }} key={route.label}
                  sx={{
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}>
                    <route.icon sx={{
                      fontSize: "1.25rem"
                    }} />
                  </ListItemIcon>
                  {open && <ListItemText
                    primaryTypographyProps={{
                      fontSize: '0.8125rem',
                      fontWeight: 600
                    }}
                    primary={
                      route.label.includes("-") ?
                        route.label.split("-").map((l) => (
                          l.charAt(0).toUpperCase() + l.slice(1) + " "
                        ))
                        : route.label.charAt(0).toUpperCase() + route.label.slice(1)
                    } />}
                </ListItemButton>
            ))}
          </List>
        </Drawer>
      }
      <Box
        component="main"
        sx={{
          position: "relative",
          flexGrow: 1,
          height: '100vh',
          overflow: isLoggedIn ? 'auto' : "hidden",
        }}
      >
        {isLoggedIn && <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          {!open ?
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton> : <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton color="inherit"

            id="fade-button"
            aria-controls={menuOpen ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            variant="extended"
          >
            <Avatar sizes='small' sx={{ bgcolor: theme => theme.palette.secondary.main }}>A</Avatar>
            <Stack alignItems={"start"} ml={1}>
              <Typography sx={{ fontSize: 16, lineHeight: 1, fontWeight: 600, textTransform: "capitalize" }}>{user?.name}</Typography>
              <Typography sx={{ fontStyle: "italic" }}>{user?.role}</Typography>
            </Stack>
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={() => setMenuAnchor(null)}
            TransitionComponent={Fade}
            PaperProps={{
              style: {
                width: 200,
              },
            }}
          >
            {/* <MenuItem onClick={()=> {}}>Profile</MenuItem> */}
            {/* <MenuItem onClick={()=> {}}>My account</MenuItem> */}
            <MenuItem onClick={async () => {
              try {
                await getUserLoggedOutApi()
                setMenuAnchor(null)
                dispatch(handleLogout());
              } catch (err) {
                openErrorToast(err.message ? err.message : err)
              }
            }}>Logout</MenuItem>
          </Menu>

        </Toolbar>}
        <Navigation />
        {loader && <Loader />}
      </Box>

      {isLogout ?
        <Dialog dailogOpen={isLogout} size={"sm"} title={""}>
          <Typography sx={{fontSize: 18, textAlign:"center"}}>Your password has changed successfully. The system needs your updated credentials for smooth functionality. Therefore, You will be logged out in<span style={{fontWeight:700, fontSize:22, color: "red"}}> {timeLeft} </span>seconds.</Typography>
        </Dialog>
        : null}
    </Box>
  );
}


export default AppLayout