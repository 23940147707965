import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FINANCE_REQUESTED, FINANCE_SLICE_NAME } from "./constants";
import { getAllVouchersApi } from "../../api";

export const financeRequested = createAsyncThunk(FINANCE_REQUESTED, async () => {
    try {
        const response = await getAllVouchersApi()
        return response
    } catch (err) {
        throw err
    }
})

const financeSlice = createSlice({
    name: FINANCE_SLICE_NAME,
    initialState: {
        financesList: [],
        currentPage: 0,
        rowsPerPage: 10
    },
    reducers: {
        handleChangeFinanceRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeFinanceCurrentPage: (state, action) => { state.currentPage = action.payload },
    },
    extraReducers: builder => {
        builder.addCase(financeRequested.fulfilled, (state, action) => {
            if(action.payload.data.vouchers.length > 0){
                state.financesList = action.payload.data.vouchers.map(v => ({ ...v, vstid: `VSTID-${v.id}`, regId: `${v.student.id}` }))
            }else{
                state.financesList = []
            }
        })
    }
})

export const getFinaneList = state => state.finance.financesList
export const getFinaneCurrentPage = state => state.finance.currentPage
export const getFinaneRowsPerPage = state => state.finance.rowsPerPage

export const {
    handleChangeFinanceRowsPerPage,
    handleChangeFinanceCurrentPage
} = financeSlice.actions

export default financeSlice.reducer