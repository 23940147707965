import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADD_TUTOR_PRE_REQUISITES_REQUESTED, ADD_TUTOR_SLICE } from "./constants";
import { getAllDesignationApi, getAllGroupsApi, getTitlesApi } from "../../api";

export const prerequisitesRequested = createAsyncThunk(ADD_TUTOR_PRE_REQUISITES_REQUESTED, async () => {
    try {
        const groups = await getAllGroupsApi()
        const designations = await getAllDesignationApi()
        const titles = await getTitlesApi()
        return { groups, designations, titles }
    } catch (err) {
        throw err
    }
})

const addTutorSlice = createSlice({
    name: ADD_TUTOR_SLICE,
    initialState: {
        id: null,
        full_name: "",
        email: "",
        contact_number: "",
        date_of_birth: null,
        gender: "male",
        marital_status: "single",
        cnic: "",
        address: "",
        joining_date: new Date(),
        offer_date: new Date(),
        resignation_date: null,
        exit_date: null,
        feedback: "",

        avatar: "",
        titleId: null,
        designationId: null,
        groupId: null,

        titleList: [],
        designatinList: [],
        groupList: []
    },
    reducers: {
        handleChangeAddtutorId: (state, action) => { state.id = action.payload },
        handleChangeAddtutorFullName: (state, action) => { state.full_name = action.payload },
        handleChangeAddtutorEmail: (state, action) => { state.email = action.payload },
        handleChangeAddtutorContactNumber: (state, action) => { state.contact_number = action.payload },
        handleChangeAddtutorDateOfBirth: (state, action) => { state.date_of_birth = action.payload },
        handleChangeAddtutorGender: (state, action) => { state.gender = action.payload },
        handleChangeAddtutorMaritalStatus: (state, action) => { state.marital_status = action.payload },
        handleChangeAddtutorCnic: (state, action) => { state.cnic = action.payload },
        handleChangeAddtutorAddress: (state, action) => { state.address = action.payload },
        handleChangeAddtutorJoiningDate: (state, action) => { state.joining_date = action.payload },
        handleChangeAddtutorOfferDate: (state, action) => { state.offer_date = action.payload },
        handleChangeAddtutorResignationDate: (state, action) => { state.resignation_date = action.payload },
        handleChangeAddtutorExitDate: (state, action) => { state.exit_date = action.payload },
        handleChangeAddtutorFeedback: (state, action) => { state.feedback = action.payload },
        handleChangeAddtutorAvatar: (state, action) => { state.avatar = action.payload },
        handleChangeAddtutorTitleId: (state, action) => { state.titleId = action.payload },
        handleChangeAddtutorDesignationId: (state, action) => { state.designationId = action.payload },
        handleChangeAddtutorGroupId: (state, action) => { state.groupId = action.payload },
        handleResetAddTutorSlice: (state) => {
            state.id = null
            state.full_name = ""
            state.email = ""
            state.date_of_birth = new Date()
            state.gender = "male"
            state.marital_status = "single"
            state.cnic = ""
            state.address = ""
            state.joining_date = new Date()
            state.avatar = ""
            state.titleId = null
            state.designationId = null
            state.groupId = null
            state.resignation_date = null
            state.offer_date = new Date()
            state.exit_date = null
            state.feedback = ""
            state.contact_number = ""

            state.groupList = []
            state.designatinList = []
            state.titleList = []
        },
        handleUpdateTutorFProfile: (state, action) => {
            state.id = action.payload
            state.full_name = action.payload
            state.email = action.payload
            state.date_of_birth = action.payload
            state.gender = action.payload
            state.marital_status = action.payload
            state.cnic = action.payload
            state.address = action.payload
            state.joining_date = action.payload
            state.avatar = action.payload
            state.titleId = action.payload
            state.designationId = action.payload
            state.groupId = action.payload
            state.resignation_date = action.payload
            state.offer_date = action.payload
            state.exit_date = action.payload
            state.feedback = action.payload
            state.contact_number = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(prerequisitesRequested.fulfilled, (state, action) => {
            state.designatinList = action.payload.designations.data.designations.map(d => ({ ...d, erpId: `TNS-DESID-${d.id}` }))
            state.groupList = action.payload.groups.data.group.map(d => ({ ...d, erpId: `TNS-GRPID-${d.id}` }))
            state.titleList = action.payload.titles.data.titles.map(d => ({ ...d, erpId: `TNS-TITID-${d.id}` }))
        })
    }
})

export const {
    handleChangeAddtutorId,
    handleChangeAddtutorFullName,
    handleChangeAddtutorEmail,
    handleChangeAddtutorDateOfBirth,
    handleChangeAddtutorGender,
    handleChangeAddtutorMaritalStatus,
    handleChangeAddtutorCnic,
    handleChangeAddtutorAddress,
    handleChangeAddtutorJoiningDate,
    handleChangeAddtutorAvatar,
    handleChangeAddtutorTitleId,
    handleChangeAddtutorDesignationId,
    handleChangeAddtutorGroupId,
    handleResetAddTutorSlice,
    handleChangeAddtutorOfferDate,
    handleChangeAddtutorResignationDate,
    handleChangeAddtutorExitDate,
    handleChangeAddtutorFeedback,
    handleChangeAddtutorContactNumber
} = addTutorSlice.actions

export const getAddtutorId = state => state.addTutor.id
export const getAddtutorFullName = state => state.addTutor.full_name
export const getAddtutorEmail = state => state.addTutor.email
export const getAddtutorDateOfBirth = state => state.addTutor.date_of_birth
export const getAddtutorGender = state => state.addTutor.gender
export const getAddtutorMaritalStatus = state => state.addTutor.marital_status
export const getAddtutorCnic = state => state.addTutor.cnic
export const getAddtutorAddress = state => state.addTutor.address
export const getAddtutorJoiningDate = state => state.addTutor.joining_date
export const getAddtutorAvatar = state => state.addTutor.avatar
export const getAddtutorTitleId = state => state.addTutor.titleId
export const getAddtutorDesignationId = state => state.addTutor.designationId
export const getAddtutorGroupId = state => state.addTutor.groupId
export const getAddtutorGroupList = state => state.addTutor.groupList
export const getAddtutorDesignationList = state => state.addTutor.designatinList
export const getAddtutorTitleList = state => state.addTutor.titleList
export const getAddtutorFeedback = state => state.addTutor.feedback
export const getAddtutorOfferDate = state => state.addTutor.offer_date
export const getAddtutorResignationDate = state => state.addTutor.resignation_date
export const getAddtutorExitDate = state => state.addTutor.exit_date
export const getAddtutorContactNumber = state => state.addTutor.contact_number

export default addTutorSlice.reducer