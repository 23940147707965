import { Box, Typography } from '@mui/material'

const NoticeCard = () => {
    return (
        <Box sx={{
            px: 3,
            py: 1,
            display:"flex",
            flexDirection:"column",
        }}>
            <Typography variant='body1' sx={{mb: 0.25}}>Today - 15 mins ago</Typography>
            <Typography variant='p' sx={{mb: 0.75}} component={"p"}>Asad Mehmood</Typography>
            <Typography variant='caption'>Hy this is dummy notice</Typography>
        </Box>
    )
}

export default NoticeCard