import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

export default function Calendar() {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView='dayGridMonth'
      weekends={true}
      headerToolbar={false}
      // contentHeight={450}
      // height={450}
      aspectRatio={1}
      events={false}
    // eventContent={renderEventContent}
    />
  );
}