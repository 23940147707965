import { useSelector } from "react-redux";
import { APP_NAVIGATION } from "./constants"
import { Routes, Route, Navigate } from "react-router-dom";
import { getIsLoggedIn, getPermissions } from "../../screen/Auth/authSlice";
import ProtectedRoute from "./protected";
import Authorization from "../../screen/Auth";
import NotFound from "../../screen/NotFound";

const Navigation = () => {

    const isLoggedIn = useSelector(getIsLoggedIn)
    const userPermissions = useSelector(getPermissions)
    return (
        <>

            <Routes>
                <Route index element={isLoggedIn ? <Navigate to={`/dashboard`} replace /> : <Authorization />} />
                {APP_NAVIGATION.map((route) => (
                    route.childrens && route.childrens.length > 0 ?
                        <Route
                            key={route.label}
                            path={route.path}
                        >
                            {route.childrens.map(r => (
                                <Route
                                    key={r.label}
                                    path={route.path + r.path}
                                    element={
                                        route.isProtected ?
                                            (<ProtectedRoute
                                                label={r.label}
                                                redirectPath={route.redirectPath}
                                                roles={r.role}
                                                permission={r.permission}
                                            >
                                                <r.screen />
                                            </ProtectedRoute>)
                                            : (<r.screen />)
                                    }
                                />
                            ))}
                        </Route>
                        :
                        <Route
                            key={route.label}
                            path={route.path}
                            element={
                                route.isProtected ?
                                    (<ProtectedRoute
                                        label={route.label}
                                        redirectPath={route.redirectPath}
                                        roles={route.role}
                                        permission={route.permission}
                                    >
                                        <route.screen />
                                    </ProtectedRoute>)
                                    : (<route.screen />)
                            }
                        />
                ))}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

export default Navigation