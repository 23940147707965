import hexy from "../utilities/hexy"



export const darkTheme = {
    palette: {
        mode: 'dark',
        action: {
            active: "#fff"
        },
        primary: {
            // main: '#e21949'
            main: '#FA5C7C'
        },
        secondary: {
            // main: 'rgb(0, 128, 0)'
            main: "#0acf97"
        },
        background: {
            paper: "#383838"
        },
        customFontColor: {
            main: "rgb(170,184,197)",
            light: "#bccee4",
        },
        companyGreen: {
            contrastText: "#fff",
            main: "#3d8029"
        },
        companyPink: {
            main: "#ff3d71",
            light: "#ff3d7114"
        },
        background: {
            paper: "#3a444e",
            default: "#343a40"
        },
        success: {
            main: "#0acf97"
        },
        warning: {
            main: "#ffc35a"
        }, info: {
            main: "#39afd1"
        },
    },
    typography: {
        body1: { fontFamily: "'Open Sans', sans-serif", fontSize: 14 },
        body2: { fontFamily: "'Open Sans', sans-serif" },
        button: { fontFamily: "'Open Sans', sans-serif" },
        caption: { fontFamily: "'Open Sans', sans-serif" },
        h1: { fontFamily: "'Open Sans', sans-serif" },
        h2: { fontFamily: "'Open Sans', sans-serif" },
        h3: { fontFamily: "'Open Sans', sans-serif" },
        h4: { fontFamily: "'Open Sans', sans-serif" },
        h5: { fontFamily: "'Open Sans', sans-serif" },
        h6: { fontFamily: "'Open Sans', sans-serif" },
        overline: { fontFamily: "'Open Sans', sans-serif" },
        subtitle1: { fontFamily: "'Open Sans', sans-serif" },
        subtitle2: { fontFamily: "'Open Sans', sans-serif" },
        fontFamily: "'Open Sans', sans-serif",
        headingColor: "#aab8c5",
        activeBreadCrumbColor: "#8391a2"
    }
}

export const LigthTheme = {
    palette: {
        mode: "light",
        action: {
            active: "#e21949"
        },
        primary: {
            // main: '#3e53a0'
            main: hexy()
        },
        secondary: {
            // main: 'rgb(0, 128, 0)'
            main: hexy()
        },
        companyGreen: {
            contrastText: "#fff",
            main: "#3d8029"
            // main: hexy()
        },
        customFontColor: {
            main: "#222b45",
            light: "#859bb3",
            // light: hexy(),
            // main: hexy()
        },
        companyPink: {
            main: "#ff3d71",
            light: "#ff3d7114"
            // light: hexy(),
            // main: hexy()
        },
        mainBackground: {
            sidebar: hexy(),
            contentarea: "#fff"
            // contentarea: "#323954"
        }
    },
    typography: {
        body1: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontSize: 12, fontWeight: 500 },
        body2: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, },
        button: { fontFamily: "'Open Sans', sans-serif" , fontFamily:`"Roboto", sans-serif`,},
        caption: { fontFamily: "'Open Sans', sans-serif" , fontFamily:`"Roboto", sans-serif`,},
        p: { fontFamily: "'Open Sans', sans-serif" , fontFamily:`"Roboto", sans-serif`, fontSize: 14},
        h1: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 900, fontSize: 32 },
        h2: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 800, fontSize: 28 },
        h3: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 700, fontSize: 26 },
        h4: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 600, fontSize: 24 },
        h5: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 500, fontSize: 20 },
        h6: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, fontWeight: 400, fontSize: 16 },
        overline: { fontFamily: "'Open Sans', sans-serif" , fontFamily:`"Roboto", sans-serif`,},
        subtitle1: { fontFamily: "'Open Sans', sans-serif", fontFamily:`"Roboto", sans-serif`, },
        subtitle2: { fontFamily: "'Open Sans', sans-serif" , fontFamily:`"Roboto", sans-serif`,},
        fontFamily: "'Open Sans', sans-serif",
        headingColor: "#e21949",
        activeBreadCrumbColor: "#8391a2",
        color1: "#eceef0",
        color2: "#5a77df",
        color: "#ccd4de"
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    borderRadius: 50,
                    paddingRight: 0
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 16
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    borderRadius: 50,
                },
                input:{
                    padding: "10px 15px"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: "25px !important",
                    height: "25px !important"
                },
                
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    borderRadius: 50,
                    textTransform: "capitalize",
                    padding: "10px 15px"
                },
                contained : {
                    padding: "10px 15px"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: "0px"
                }
            }
        },
    }

}

export const darkOneTheme = {
    palette: {
        action: {
            activatedOpacity: 0.12,
            active: "rgba(255,255,255,.54)",
            disabled: "rgba(255,255,255,.26)",
            disabledBackground: "rgba(255,255,255,.12)",
            disabledOpacity: 0.38,
            focus: "rgba(255,255,255,.12)",
            focusOpacity: 0.12,
            hover: "rgba(255,255,255,.04)",
            hoverOpacity: 0.04,
            selected: "rgba(255,255,255,.08)",
            selectedOpacity: 0.08
        },
        background: {
            main: "#0b1437",
            paper: "#111c44"
        },
        text: {
            primary: "#fff",
            secondary: "(255, 255, 255, 0.6)"
        }
    }
}

export const darkTheme2 = {
    palette: {
        action: {
            activatedOpacity: 0.12,
            active: "rgba(143, 155, 179,.54)",
            disabled: "rgba(143, 155, 179,.26)",
            disabledBackground: "rgba(143, 155, 179,.12)",
            disabledOpacity: 0.38,
            focus: "rgba(143, 155, 179,.12)",
            focusOpacity: 0.12,
            hover: "rgba(143, 155, 179,.04)",
            hoverOpacity: 0.04,
            selected: "rgba(143, 155, 179,.08)",
            selectedOpacity: 0.08
        },
        background: {
            main: "#151a30",
            paper: "#222b45"
        },
        success: {
            main: "rgb(0, 214, 143)"
        },
        error: {
            main: "rgb(255 61 113)"
        },
        primary: {
            main: "rgb(87 137 255)"
        },
        text: {
            primary: "#fff",
            secondary: "(255, 255, 255, 0.6)"
        }
    }
}


export const cosmicTheme = {
    palette: {
        primary: {
            main: "rgb(161 110 255)"
        },
        success:{
            main: "rgb(0 214 143)"
        },
        error: {
            main: "rgb(255 61 113)"
        },
        warning: {
            main: "rgb(255 186 40)"
        },
        background: {
            main: "#1b1b38",
            paper: "#323258"
        }
    }
}