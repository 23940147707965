import { Container, Fab, Grid, IconButton, TextField, Tooltip, Typography, Box, Stack, Autocomplete, useTheme } from '@mui/material'
import React from 'react'
import ExplicitTable, { StyledTableCell, StyledTableRow } from '../../components/Table/ExplicitTable'
import { MEALS_TABLE_HEADS } from './constants'
import icons from '../../common/icons'
import Icons from '../../common/icons'
import Dialog from '../../components/Modal'
import DeleteModel from '../../common/deleteModel'
import { useDispatch, useSelector } from 'react-redux'
import { getMeaalFor, getMealDeleteModelOpen, getMealId, getMealModelOpen, getMealName, getMealPrice, getMealsList, handleChangeMealDeleteModelOpen, handleChangeMealFor, handleChangeMealId, handleChangeMealModelOpen, handleChangeMealName, handleChangeMealPrice, handleResetModel, mealsRequested } from './mealSlice'
import { deleteMealApi, postMealApi, updateMealApi } from '../../api'
import useCan from '../../hooks/useCan'
import { handleRemoveLoader, handleShowLoader } from '../../common/commonSlice'
import { openErrorToast, openSuccessToast } from '../../common/toast'

const Meals = () => {

  const [search, setSearch] = React.useState("")
  const mealModelOpen = useSelector(getMealModelOpen)
  const mealId = useSelector(getMealId)
  const meal_for = useSelector(getMeaalFor)
  const name = useSelector(getMealName)
  const price = useSelector(getMealPrice)
  const mealsList = useSelector(getMealsList)
  const deleteModelOpen = useSelector(getMealDeleteModelOpen)
  const dispatch = useDispatch()

  
  const add = useCan("can_add_meals")
  const edit = useCan("can_edit_meals")
  const del = useCan("can_delete_meals")

  const ACTION_BUTTON_ARRAY = [
    {
      permission: edit,
      label: "Edit",
      icon: icons.Create,
      color: "primary",
      action: (meal) => {
        dispatch(handleChangeMealId(meal.id))
        dispatch(handleChangeMealName(meal.name))
        dispatch(handleChangeMealFor(meal.meal_for))
        dispatch(handleChangeMealPrice(meal.price))
        dispatch(handleChangeMealModelOpen(true))
      }
    },
    {
      permission: del,
      label: "Delete",
      icon: icons.DeleteForever,
      color: "error",
      action: (meal) => {
        dispatch(handleChangeMealId(meal.id))
        dispatch(handleChangeMealDeleteModelOpen(true))
      }
    },
  ]

  const loadAllMeals = async() => {
    try {
      dispatch(handleShowLoader())
      dispatch(mealsRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)
    }
  }

  React.useState(() => {
    loadAllMeals()
   
  }, [])


  const theme =useTheme()


  return (
    <Container maxWidth={'xl'}>

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container gap={2}>
          <Grid item xs={12} sx={{
              display: "flex",
             alignItems: "start",
             flexDirection: "column",
             [theme.breakpoints.up("md")]: {
               flexDirection: "row",
               alignItems: "center",
               justifyContent: "space-between",
             },
          }}>
            <Typography  sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4'>Meals Offered</Typography>
            <TextField sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%"
              },
            }} size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
              startAdornment: <Icons.Search />
            }} />
          </Grid>
          <Grid xs={12}>
            <ExplicitTable size={"small"} columns={MEALS_TABLE_HEADS}>
              {mealsList.length > 0 ?
                mealsList.map(meal => (
                  <StyledTableRow key={meal.id}>
                    <StyledTableCell><b>{meal.name}</b></StyledTableCell>
                    <StyledTableCell><i>{meal.name}</i></StyledTableCell>
                    <StyledTableCell>{meal.price}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>{
                      ACTION_BUTTON_ARRAY.filter(r => r.permission).map(btn => (
                        <IconButton size='small' key={btn.label} onClick={() => btn.action(meal)} >
                          <btn.icon color={btn.color} />
                        </IconButton>
                      ))
                    }</StyledTableCell>
                  </StyledTableRow>
                ))
                : ""}
            </ExplicitTable>
          </Grid>
        </Grid>
      </Box>

      {add && <Tooltip placement='left' title="Add New Meal">
        <Fab color='primary' onClick={e => dispatch(handleChangeMealModelOpen(true))} sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}


      <Dialog
        dailogOpen={mealModelOpen}
        title={"Add Meal"}
        hasCloseIcon={true}
        size="xs"
        clickAwayListener={true}
        handleClose={() => { dispatch(handleResetModel()) }}
        actionsButtonArray={[
          {
            label: "Cancel",
            color: "error",
            variant: "contained",
            action: () => { dispatch(handleResetModel()) },
            size: "small"
          },
          {
            label: "Save",
            color: "primary",
            variant: "contained",
            action: async (e) => {
              try {
                dispatch(handleShowLoader())
                mealId === null ? await postMealApi({ name,meal_for, price }) : await updateMealApi({ id: mealId, name,meal_for, price })
                await dispatch(mealsRequested()).unwrap()
                dispatch(handleResetModel())
                dispatch(handleRemoveLoader())
                openSuccessToast("Success")
              } catch (err) {
                dispatch(handleRemoveLoader())
                openErrorToast(err.message ? err.message : err)
              }
            },
            size: "small"
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Name" value={name} onChange={e => dispatch(handleChangeMealName(e.target.value))} />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <Autocomplete 
            size='small'
            options={["Regular", "Saturday"]}
            disableClearable
            value={meal_for}
            onChange={(e,v) => dispatch(handleChangeMealFor(v))}
            renderInput={(props)=> <TextField {...props} label="Meal For" />}
            />
          </Grid>
          <Grid item xs={!2} sm={12}>
            <TextField fullWidth size="small" label="Price" type='number' value={price} onChange={e => dispatch(handleChangeMealPrice(e.target.value))} />
          </Grid>
        </Grid>
      </Dialog>

      <DeleteModel open={deleteModelOpen} handleDelete={async () => {
        try {
          dispatch(handleShowLoader())
          await deleteMealApi({ id: mealId })
          dispatch(mealsRequested()).unwrap()
          dispatch(handleChangeMealDeleteModelOpen(false))
          dispatch(handleChangeMealId(null))
          dispatch(handleRemoveLoader())
          openSuccessToast("Success")
        } catch (err) {
          dispatch(handleRemoveLoader())
          openErrorToast(err.message ? err.message : err)
        }
      }} handleClose={() => {
        dispatch(handleChangeMealDeleteModelOpen(false))
        dispatch(handleChangeMealId(null))
      }} />
    </Container>
  )
}

export default Meals