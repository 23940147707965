import { Alert, AlertTitle, Box, Button, CircularProgress, IconButton, TextField, Typography, useTheme } from '@mui/material'
import React from 'react'
import Icons from '../../../common/icons'
import { useDispatch } from 'react-redux'
import { userLoginRequested } from '../authSlice'
import { Typewriter } from 'react-simple-typewriter'

const RenderVideo = () => {
    return (
        <Box sx={{
            overflow: "hidden",
            transform: "scale(1.5)",
            "&:after": {
                content: "''",
                background: "rgba(0,0,0,0.7)",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            }
        }}
            dangerouslySetInnerHTML={{
                __html: `
            <video style={{width: "100%}} muted loop autoPlay controls>
  <source src="/krpl.mp4" type="video/mp4">
  Your browser does not support the video tag.
</video>
            `
            }}
        ></Box>
    )
}

// 22
const LoginComponent = () => {

    const [signin, setSignin] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")

    const [showPassword, setShowPassword] = React.useState(false)
    const [alert, setAlert] = React.useState({
        show: false,
        message: ""
    })
    const dispatch = useDispatch()
    const theme = useTheme()
    return (
        <>
            <Box sx={{
                width: "100%",
                minHeight: "100vh",
                display: "flex",
                flexDirection: signin ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden"
            }}>
                <Box sx={{
                    width: signin ? "80%" : "100%",
                    overflow: "hidden",
                    [theme.breakpoints.down("md")]: {
                        display: signin ? "none" : "block"
                    },
                    [theme.breakpoints.up("md")]: {
                        width: "100%",
                    },

                }}>
                    <RenderVideo />
                    <Box sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: signin ? "start" : "center",
                        justifyContent: signin ? "end" : "center",
                        padding: 5,
                        gap: 2
                    }}>


                        {!signin && <>
                            <Box sx={{
                                width: 400,
                                zIndex: 9999,
                                [theme.breakpoints.down("sm")]: {
                                    width: 200,
                                },
                            }}>
                                <img style={{
                                    filter: "brightness(0) invert(1)"
                                }} src={"https://thesecondnest.com.pk/wp-content/uploads/2024/01/Logo-min-2048x1502.png"} width={"100%"} />
                            </Box>
                            <Typography variant='h4' component={"h4"} sx={{
                                color: "#fff",
                                fontWeight: 500,
                                maxWidth: 800,
                                fontSize: 22,
                                textAlign: "center",
                                lineHeight: 1.5,
                                [theme.breakpoints.down("sm")]: {
                                    display: "none"
                                },
                            }} >
                                <Typewriter
                                    words={['Welcome to The Second Nest Preschool!', 'Nurturing young minds with love, creativity, and fun.', 'Safe & vibrant learning environment', 'Play-based']}
                                    loop={0}
                                    cursor
                                    cursorStyle='|'
                                    typeSpeed={70}
                                    deleteSpeed={50}
                                    delaySpeed={3000}
                                />
                            </Typography>

                            <Box display={"flex"} flexDirection={"row"} gap={2}>
                                <Button onClick={() => setSignin(true)} size='large' variant='outlined' sx={{
                                    borderRadius: 50,
                                    width: 150,
                                    border: "2px solid #fff",
                                    color: "#fff",
                                    '&:hover': {
                                        background: "#fff",
                                        color: "green",
                                        border: "2px solid #fff",
                                    }
                                }}>Sign In</Button>
                            </Box>

                        </>}

                        {signin && <Typography variant='h4' component={"h4"} sx={{
                            color: "#fff",
                            maxWidth: 600,
                            fontSize: 22,
                            lineHeight: 1.5
                        }} > You are a step away from enjoying amazing features. <br />Hurry Up! Login and enjoy.</Typography>
                        }

                    </Box>
                </Box>


                {signin &&
                    <Box sx={{
                        width: "20%",
                        [theme.breakpoints.down("md")]: {
                            width: "50%",
                        },
                        [theme.breakpoints.down("sm")]: {
                            width: "100%",
                        },
                        [theme.breakpoints.up("md")]: {
                            width: "40%",
                        },
                        [theme.breakpoints.up("lg")]: {
                            width: "30%",
                        }
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100% !important",
                            gap: 2,
                            padding: 5,
                            [theme.breakpoints.down("sm")]: {
                                background: "rgba(255,255,255,0.7)",
                                width: "90%",
                                borderRadius: 5
                            },
                            [theme.breakpoints.up("sm")]: {
                                // background: "rgba(255,255,255,0.7)",
                                width: "50%",
                                borderRadius: 5
                            },
                            [theme.breakpoints.up("md")]: {
                                // background: "rgba(255,255,255,0.7)",
                                width: "35%",
                                borderRadius: 5
                            },
                        }}>
                            {alert.show ? <Box sx={{width: "100%", boxShadow: "0 0 5px 1px #d74141"}}>
                                <Alert severity="error" sx={{
                                }}>
                                    <AlertTitle>Error</AlertTitle>
                                    <Typography>{alert.message ? alert.message : "Username/Password is incorrect."}</Typography>
                                </Alert>
                            </Box> : null}
                            <Box sx={{
                                width: 200,
                                zIndex: 9999
                            }}>
                                <img style={{
                                }} src={"https://thesecondnest.com.pk/wp-content/uploads/2024/01/Logo-min-2048x1502.png"} width={"100%"} />
                            </Box>

                            <TextField fullWidth size="small" label="Email Address" value={email} onChange={e => setEmail(e.target.value)} />
                            <TextField fullWidth size="small" label="Passowrd" type={showPassword ? "text" : 'password'} value={password} onChange={e => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {!showPassword ? <Icons.Visibility sx={{
                                            width: 18,
                                            height: 18
                                        }} /> : <Icons.VisibilityOff sx={{
                                            width: 18,
                                            height: 18
                                        }} />}
                                    </IconButton>
                                }}
                            />
                            {!showLoader ? <Button size="small" variant='contained' fullWidth
                                onClick={async () => {
                                    try {
                                        setShowLoader(true)
                                        await dispatch(userLoginRequested({ email: email, password: password })).unwrap()
                                        setShowLoader(false)
                                    } catch (err) {
                                        setShowLoader(false)
                                        setAlert({
                                            show: true,
                                            message: err.message ? err.message : err
                                        })
                                    }
                                }}
                            >Login</Button> : <CircularProgress />}
                        </Box>
                    </Box>

                }
            </Box>


        </>
    )
}

export default LoginComponent