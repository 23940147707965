export const BREADCRUMBS = [
    {
        name: "Employees",
        icon: "",
        path: `/maange-inventory`
    },
    {
        name: "Titles",
        icon: "",
        path: `/Departments`
    },
]

export const TABLE_HEADS = [
    { name: "Role Id" },
    { name: "Role Name" },
    // { name: "role Skills" },
    { name: "Action", align: "right" },
]

export const ROLES_SLICE_NAME = 'roleSlice'
export const ROLES_REQUESTED = `${ROLES_SLICE_NAME}/roleRequested`
export const ROLE_PERIMISSION_REQUESTED = `${ROLES_SLICE_NAME}/rolePermissionRequested`
export const ROLE_PERIMISSION_REQUESTED_BY_ROLEID = `${ROLES_SLICE_NAME}/rolePermissionByRoleIdRequested`