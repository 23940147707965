import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STUDENTS_CONFIG_PRE_REQUISITES_REQUESTED, STUDENTS_REQUESTED, STUDENTS_SLICE_NAME } from "./constants";
import { getAllClassesApi, getAllMealsApi, getAllPackagesApi, getAlltudentsApi } from "../../api";

export const studentsRequested = createAsyncThunk(STUDENTS_REQUESTED, async () => {
    try {
        const response = await getAlltudentsApi()
        return response
    } catch (err) {
        throw err
    }
})

export const studentConfigPreRequisitesRequested = createAsyncThunk(STUDENTS_CONFIG_PRE_REQUISITES_REQUESTED, async () => {
    try {
        const classes = await getAllClassesApi()
        const packages = await getAllPackagesApi()
        const meals = await getAllMealsApi()
        return { classes, packages, meals }
    } catch (err) {
        throw err
    }
})

const studentSlice = createSlice({
    name: STUDENTS_SLICE_NAME,
    initialState: {
        configModelOpen: false,
        studentsList: [],
        studentId: null,
        classId: [],
        packageId: [],
        mealId: [],

        mealsList: [],
        classesList: [],
        packagesList: [],

        currentPage: 0,
        rowsPerPage: 10
    },
    reducers: {
        handleChangeConfigModelOpen: (state, action) => { state.configModelOpen = action.payload },
        handleChangeStudentId: (state, action) => { state.studentId = action.payload },
        handleChangeStudentCurrentPage: (state, action) => { state.currentPage = action.payload },
        handleChangeStudentRowsPerPage: (state, action) => { state.rowsPerPage = action.payload },
        handleChangeStudentPackageId: (state, action) => {
            state.packageId = action.payload
        },
        handleChangeStudentClassId: (state, action) => {
            state.classId = action.payload
        },
        handleChangeStudentMealId: (state, action) => {
            state.mealId = action.payload
        },
        handleResetModel: state => {
            state.packageId = []
            state.mealId = []
            state.classId = []

            state.packagesList = []
            state.mealsList = []
            state.classesList = []
        }
    },
    extraReducers: builder => {
        builder.addCase(studentsRequested.fulfilled, (state, action) => {
            state.studentsList = action.payload.data.students.map(std => ({ ...std, regId: `KRPL-${std.id}` }))
        })
        builder.addCase(studentConfigPreRequisitesRequested.fulfilled, (state, action) => {
            state.classesList = action.payload.classes.data.classes
            state.mealsList = action.payload.meals.data.meals
            state.packagesList = action.payload.packages.data.packages
        })
    }
})

export const getStudentsList = state => state.students.studentsList
export const getStudentId = state => state.students.studentId
export const getStudentRowsPerPage = state => state.students.rowsPerPage
export const getStudentCurrentPage = state => state.students.currentPage
export const getStudentConfigModelOpen = state => state.students.configModelOpen

export const getStudentClassesList = state => state.students.classesList
export const getStudentPackagesList = state => state.students.packagesList
export const getStudentMealsList = state => state.students.mealsList

export const getStudentClassId = state => state.students.classId
export const getStudentPackageId = state => state.students.packageId
export const getStudentMealsID = state => state.students.mealId

export const {
    handleChangeStudentId,
    handleChangeStudentCurrentPage,
    handleChangeStudentRowsPerPage,
    handleChangeConfigModelOpen,
    handleChangeStudentPackageId,
    handleChangeStudentClassId,
    handleChangeStudentMealId,
    handleResetModel
} = studentSlice.actions
export default studentSlice.reducer