import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllVoucherDetailsApi } from "../../../api";

export const voucherConfigRequested = createAsyncThunk("voucherConfigSlice/voucherConfigRequested", async ({ voucherId }) => {
    try {
        const response = await getAllVoucherDetailsApi({ voucherId })
        return response
    } catch (err) {
        throw err
    }
})

const voucherConfigSlice = createSlice({
    name: "voucherConfigSlice",
    initialState: {
        voucherConfigList: [],
        voucherConfigId: null,
        voucherConfigFeeType: "",
        voucherConfigFeeAmount: "",
        voucherConfigFeeDescription: "",
    },
    reducers: {
        handleChangeVoucherConfigList: (state, action) => { state.voucherConfigList = action.payload },
        handleChangeVoucherConfigId: (state, action) => { state.voucherConfigId = action.payload },
        handleChangeVoucherConfigFeeType: (state, action) => { state.voucherConfigFeeType = action.payload },
        handleChangeVoucherConfigFeeAmount: (state, action) => { state.voucherConfigFeeAmount = action.payload },
        handleChangeVoucherConfigFeeDescription: (state, action) => { state.voucherConfigFeeDescription = action.payload },
        handleResetForm: (state) => {
            state.voucherConfigId = null
            state.voucherConfigFeeType = ""
            state.voucherConfigFeeAmount = ""
            state.voucherConfigFeeDescription = ""
        },
        handleResetVoucherConfigSlice: (state) => {
            state.voucherConfigId = null
            state.voucherConfigFeeType = ""
            state.voucherConfigFeeAmount = ""
            state.voucherConfigFeeDescription = ""
            state.voucherConfigList = []
        }
    },
    extraReducers: builder => {
        builder.addCase(voucherConfigRequested.fulfilled, (state, action) => {
            state.voucherConfigList = action.payload.data.voucher
        })
    }
})

export const getVoucherConfigList = (state) => state.voucherConfig.voucherConfigList
export const getVoucherConfigId = (state) => state.voucherConfig.voucherConfigId
export const getVoucherConfigFeeType = (state) => state.voucherConfig.voucherConfigFeeType
export const getVoucherConfigFeeAmount = (state) => state.voucherConfig.voucherConfigFeeAmount
export const getVoucherConfigFeeDescription = (state) => state.voucherConfig.voucherConfigFeeDescription

export const {
    handleChangeVoucherConfigList,
    handleChangeVoucherConfigId,
    handleChangeVoucherConfigFeeType,
    handleChangeVoucherConfigFeeAmount,
    handleChangeVoucherConfigFeeDescription,
    handleResetForm,
    handleResetVoucherConfigSlice
} = voucherConfigSlice.actions

export default voucherConfigSlice.reducer