import { Container, Fab, Grid, TextField, Tooltip, Typography, Box, useTheme, Stack, Select, MenuItem, FormControl, InputLabel, Button, IconButton, Chip, Autocomplete } from "@mui/material"
import ExplicitTable, { StyledTableCell, StyledTableRow } from "../../components/Table/ExplicitTable"
import { ADD_STUDENTS_TABLE_COLUMN_HEADS } from "./constants"
import Icons from "../../common/icons"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getStudentClassesList, getStudentClassId, getStudentConfigModelOpen, getStudentCurrentPage, getStudentId, getStudentMealsID, getStudentMealsList, getStudentPackageId, getStudentPackagesList, getStudentRowsPerPage, getStudentsList, handleChangeConfigModelOpen, handleChangeStudentClassId, handleChangeStudentCurrentPage, handleChangeStudentId, handleChangeStudentMealId, handleChangeStudentPackageId, handleChangeStudentRowsPerPage, handleResetModel, studentConfigPreRequisitesRequested, studentsRequested } from "./studentsSlice"
import React from "react"
import TablePaginationActions from "../../components/TablePaginationActions"
import useCan from "../../hooks/useCan"
import dayjs from "dayjs"
import { MdDelete, MdFace2, MdFastfood, MdLoyalty, MdRemoveCircleOutline, MdSchool, MdSettings } from 'react-icons/md'
import Dialog from "../../components/Modal"
import { postStudentAssignApi } from "../../api"
import { handleRemoveLoader, handleShowLoader } from "../../common/commonSlice"
import { openErrorToast, openSuccessToast } from "../../common/toast"


const StudentaLableRow = React.memo(({ item, actionButton }) => {
  return (
    <StyledTableRow sx={{

    }}>
      <StyledTableCell><b>{item.id}</b></StyledTableCell>
      <StyledTableCell>{item.full_name}</StyledTableCell>
      <StyledTableCell>{item.nick_name}</StyledTableCell>
      <StyledTableCell>{dayjs(item.date_of_birth).format("DD-MMM-YYYY")}</StyledTableCell>
      <StyledTableCell>{item.gender}</StyledTableCell>
      <StyledTableCell>{item.status}</StyledTableCell>
      <StyledTableCell>{dayjs(item.created_at).format("DD-MMM-YYYY hh:mm:ss A")}</StyledTableCell>
      <StyledTableCell sx={{ textAlign: "right" }}>
        {actionButton.map((btn, idx) => (
          <Tooltip placement="top" title={btn.label}>
            <IconButton onClick={() => btn.action(item)} color={btn.color} size="small">
              <btn.icon />
            </IconButton>
          </Tooltip>
        ))}
      </StyledTableCell>
    </StyledTableRow>
  )
})

const Students = () => {
  const [search, setSearch] = React.useState("")
  const navigate = useNavigate()
  const studentsList = useSelector(getStudentsList)
  const dispatch = useDispatch()
  const theme = useTheme()

  const rowsPerPage = useSelector(getStudentRowsPerPage)
  const currentPage = useSelector(getStudentCurrentPage)
  const configModelOpen = useSelector(getStudentConfigModelOpen)
  const studentId = useSelector(getStudentId)

  const mealsList = useSelector(getStudentMealsList)
  const packagesList = useSelector(getStudentPackagesList)
  const classesList = useSelector(getStudentClassesList)

  const classId = useSelector(getStudentClassId)
  const packageId = useSelector(getStudentPackageId)
  const mealId = useSelector(getStudentMealsID)

  const [filteredRows, setFilteredRows] = React.useState([])
  const [filteredRecords, setFilteredRecords] = React.useState(0)
  const [columnHeads, setColumnHeads] = React.useState("regId")


  const add = useCan("can_add_students")
  const edit = useCan("can_edit_students")
  const del = useCan("can_delete_students")




  React.useEffect(() => {
    if (studentsList.length > 0) {
      setFilteredRows(studentsList)
      setFilteredRecords(studentsList.length)
    }
  }, [studentsList.length])


  const load = async () => {
    try {
      dispatch(handleShowLoader())
      await dispatch(studentsRequested()).unwrap()
      dispatch(handleRemoveLoader())
      openSuccessToast("Success")
    } catch (err) {
      dispatch(handleRemoveLoader())
      openErrorToast(err.message ? err.message : err)
    }
  }
  React.useEffect(() => {
    load()
  }, [])

  React.useEffect(() => {
    if (studentId) {
      dispatch(studentConfigPreRequisitesRequested()).unwrap()
      dispatch(handleChangeConfigModelOpen(true))
    }
  }, [studentId])


  const STUDENT_ACTION_BUTTON_ARRAY = [
    {
      label: "Configuration",
      icon: MdSettings,
      color: "warning",
      action: async (item) => {
        dispatch(handleChangeStudentId(item.id))
      }
    },
    {
      label: "Edit Profile",
      icon: MdFace2,
      color: "success",
      action: async (item) => {
        navigate("/student-profile", {
          state: {
            id: item.id
          }
        })
      }
    },
    {
      label: "Delete Profile",
      icon: MdDelete,
      color: "danger",
      action: async () => { }
    },
  ]


  return (
    <Container maxWidth="xl" >

      <Box sx={{
        background: theme => theme.palette.common.white,
        p: 2,
        borderRadius: 4
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}>
            <Typography sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: 1.5
              },
            }} variant='h4' color={"primary.dark"}>Students</Typography>
            <Stack flexDirection={"row"}>
              <FormControl size='small' sx={{
                minWidth: "fit-content"
              }}>
                <InputLabel>Records</InputLabel>
                <Select value={columnHeads} onChange={e => setColumnHeads(e.target.value)} size="small" label="Records"  >
                  <MenuItem value={"regId"}>Reg Id</MenuItem>
                  <MenuItem value={"full_name"}>Full Name</MenuItem>
                </Select>
              </FormControl>
              <TextField size='small' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} InputProps={{
                startAdornment: <Icons.Search sx={{ mr: 1 }} />
              }} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {filteredRows.length > 0 ?
              <ExplicitTable columns={ADD_STUDENTS_TABLE_COLUMN_HEADS} size={"small"}>
                {(
                  filteredRows.length > 0 ? filteredRows.slice(currentPage * rowsPerPage, (currentPage * rowsPerPage) + rowsPerPage) : filteredRows
                ).filter(std => {
                  if (columnHeads) {
                    if (search) {
                      return std[columnHeads].toLowerCase().includes(search.toLowerCase())
                    } else {
                      return std
                    }
                  }
                }).map(std => (
                  <StudentaLableRow actionButton={STUDENT_ACTION_BUTTON_ARRAY} key={std.id} item={std} />
                ))}
              </ExplicitTable>
              : ""}
          </Grid>
          <Grid item xs={!2} md={12} >
            <TablePaginationActions
              count={filteredRecords}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onPageChange={(e, val) => {
                dispatch(handleChangeStudentCurrentPage(val))
              }}
              onRowsPerPageChange={(e) => { dispatch(handleChangeStudentRowsPerPage(e.target.value)) }}
            />
          </Grid>
        </Grid>
      </Box>

      {add && <Tooltip placement='left' title="Add New Student">
        <Fab color='primary' onClick={() => { navigate("/create-student") }} sx={{
          position: "absolute",
          bottom: 50,
          right: 50
        }}>
          <Icons.Add />
        </Fab>
      </Tooltip>}


      <Dialog actionsButtonArray={[
        {
          label: "Save",
          variant: "contained",
          size: "small",
          action: async () => {
            try {
              await postStudentAssignApi({
                student_id: studentId,
                class_id: packageId.length > 0 ? packageId.map(c => c.class_id) : [],
                meal_id: mealId.length > 0 ? mealId.map(c => c.id) : [],
                package_id: packageId.length > 0 ? packageId.map(c => c.id) : []
              })

              dispatch(handleChangeStudentId(null))
              dispatch(handleChangeConfigModelOpen(false))
              dispatch(handleResetModel())
            } catch (err) {
              console.log(err.message ? err.message : err)
            }
          }
        }
      ]} size={"sm"} dailogOpen={configModelOpen} title={"Configure Office Info for Student"} hasCloseIcon={true} handleClose={() => {
        dispatch(handleChangeConfigModelOpen(false))
        dispatch(handleResetModel())
        dispatch(handleChangeStudentId(null))
      }} clickAwayListener={true} >
        <Stack flexDirection={"column"} gap={2}>
          <Autocomplete
            options={mealsList ? mealsList : []}
            disableClearable
            size='small'
            multiple
            getOptionLabel={option => {
              return `${option.name} - (${option.meal_for})`
            }}
            onChange={(e, val) => {
              dispatch(handleChangeStudentMealId(val))
            }}
            renderInput={(props) => (<TextField {...props} label="Select Meals" />)}
          />

          <Autocomplete
            options={classesList ? classesList : []}
            disableClearable
            size='small'
            multiple
            getOptionLabel={option => {
              return `${option.name}`
            }}
            onChange={(e, val) => {
              dispatch(handleChangeStudentClassId(val))
            }}
            renderInput={(props) => (<TextField {...props} label="Select Class" />)}
          />

          <Autocomplete
            options={packagesList ? packagesList : []}
            disableClearable
            size='small'
            multiple
            getOptionLabel={option => {
              return `${option.name}`
            }}
            onChange={(e, val) => {
              dispatch(handleChangeStudentPackageId(val))
            }}
            renderInput={(props) => (<TextField {...props} label="Select Package" />)}
          />
        </Stack>
      </Dialog>
    </Container>
  )
}

export default Students